import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import CreateAbsenceType from '../modals/CreateAbsenceType'
import EditAbsenceType from '../modals/EditAbsenceType'
import { useTranslation } from 'react-i18next'
import { getAbsenceTypes } from '../../actions/absenceTypeActions'
import useAuth from '../../hooks/UseAuth'
import { CustomDataTable } from '../../components/datatables/CustomDataTable'

export default function ListAbsenceTypes() {
	const { t } = useTranslation()
	const {absenceTypes} = useSelector(state => state.absenceTypes)
	const dispatch = useDispatch()
	const {auth} = useAuth()

	useEffect(() => {
		if(auth){
			dispatch(getAbsenceTypes())
		}
	}, [auth])

	const columns = [
		{
			name: t('Tipo de ausencia'),
			cell: absenceType =>
				<div className="d-flex align-items-center">
					<div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
						<Link to={'#'}>
							<div className="symbol-label fs-3 bg-light-danger text-danger">{absenceType.name?.charAt(0)}</div>
						</Link>
					</div>
					<div className="d-flex flex-column text-gray-800">
						{absenceType.name}
					</div>
				</div>,
			selector: (absenceType) => absenceType.name,
			sortable: true,
		},{
			name: t('Descripción'),
			cell: absenceType =>
				<div>
					{absenceType.description}
				</div>,
			selector: (absenceType) => absenceType.description,
			sortable: true,
		},{
			name: '',
			cell: absenceType =>
				<div>
					<Link to={'#'} className="btn btn-light btn-active-light-primary btn-sm" data-bs-toggle="dropdown" data-kt-menu-placement="bottom-end">{t('Acciones')}
						<span className="svg-icon svg-icon-5 m-0">
							<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
								<path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
							</svg>
						</span>
					</Link>
					<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4 dropdown-menu">
						<div className="menu-item px-3">
							<EditAbsenceType absenceType={absenceType} lightLink />
						</div>
					</div>
				</div>,
			selector: (absenceType) => absenceType.route,
			sortable: true,
		}
	]

	return (
		<>
			<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
				<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
					<div className="page-title d-flex flex-column me-3">
						<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Listar Tipos de Ausencia')}</h1>
						<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white">{t('Listar Tipos de Ausencia')}</li>
						</ul>
					</div>
				</div>
			</div>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					<div className="card">
						<div className="card-header border-0 pt-6">
							<div className="card-title">
								<div className="d-flex align-items-center position-relative my-1">
									<div>{t('Tipos de Ausencia')}</div>
								</div>
							</div>
							<div className="card-toolbar">
								<div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
									<CreateAbsenceType />
								</div>
								<div className="d-flex justify-content-end align-items-center d-none" data-kt-user-table-toolbar="selected">
									<div className="fw-bolder me-5">
										<span className="me-2" data-kt-user-table-select="selected_count" />Selected
									</div>
									<button type="button" className="btn btn-danger" data-kt-user-table-select="delete_selected">Delete Selected</button>
								</div>
							</div>
						</div>
						<div className="card-body py-4">
							<div className='table-responsive'>
								{absenceTypes?.length > 0
									?
										<CustomDataTable columns={columns} data={absenceTypes} />
									:
										<></>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}