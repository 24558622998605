
export function sortNotifications(userNotifications) {
  const array = []
  // Paso el objeto con las notificaciones a array
  for(var i in userNotifications){
    array.push(userNotifications[i])
  }

  // Ordeno el array de menor tiempo a mayor
  array.sort(function(a,b){
    return new Date(b.dateTime) - new Date(a.dateTime)
  })

  // Mostrar solo las ultimas 10 alertas
  const aUserNotifications = array.slice(0, 10)
  
  return aUserNotifications
}
