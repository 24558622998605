export const reportReducer = (state = {reports: null, report: null, months: null, years: null, monthsDepartment: null, yearsDepartment: null}, action)=>{
    switch (action.type) {
        case 'REPORT_GET_SUCCESS':
            return {
                ...state,
                reports: action.payload
            }
        case 'REPORT_GET_DETAIL_SUCCESS':
            return {
                ...state,
                report: action.payload
            }
        case 'REPORT_SEND_SUCCESS':
            return {
                ...state,
                reports: [
                    ...state.reports,
                    action.payload
                ]
            }
        case 'REPORT_EDITED_SUCCESS':
            return {
                ...state.map(
                    report => report.id === action.payload.id
                    ?
                        action.payload
                    :
                        report
                )
            }
        case 'REPORT_GET_MONTHS':
            return {
                ...state,
                months: action.payload
            }
        case 'REPORT_GET_YEARS':
            return {
                ...state,
                years: action.payload
            }
        case 'REPORT_GET_MONTHSDEPARTMENT':
            return {
                ...state,
                monthsDepartment: action.payload
            }
        case 'REPORT_GET_YEARSDEPARTMENT':
            return {
                ...state,
                yearsDepartment: action.payload
            }
        default:
            return state;
    }
}