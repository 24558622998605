import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const popup = withReactContent(Swal)

export function SuccessPopup(message) {
  return (
    popup.fire({
      position: 'top-end',
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 2000
    })
  )
}

export function ConfirmPupup(message) {
  return (
    Swal.fire({
      title: message,
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5cb85c',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    })
  )
}
