import AxiosControl from '../hooks/AxiosControl'
const Axios = AxiosControl()

export const addOrEditBusiness = (data, name) => async (dispatch) => {
	try {
		const fileData = new FormData()
		fileData.append('file', data)
		fileData.append('name', name)
		const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/business', fileData, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'BUSINESS_UPDATE_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}

export const getBusiness = () => async (dispatch) => {
	try {
		const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/business/get')
		if(response.status < 400){
			dispatch({
				type: 'BUSINESS_GET_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}