import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmPupup } from '../../components/popups/Popups';
import useAuth from '../../hooks/UseAuth';
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2';
import { getTasksByUser } from '../../actions/taskActions';
import { addCheckRequest, getCheckInOutStatus, getCheckRequestByDeviceAndUser } from '../../actions/checkrequestActions';
import { checkIn } from '../../actions/sheetActions';

export default function CheckInOut() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const {auth} = useAuth()
	const [deviceName, setDeviceName] = useState('')
	const [latitude, setLatitude] = useState(null)
	const [longitude, setLongitude] = useState(null)

	const {tasks} = useSelector(state => state.tasks)
	const {status, buttonState} = useSelector(state => state.checkRequests)

	const {register, handleSubmit, watch, clearErrors, formState:{errors}} = useForm()
	const [errMsg, setErrMsg] = useState('')

	const [show, setShow] = useState(false)
	const handleShow = () => setShow(true)
	const handleClose = (e) => {
		e.preventDefault()
		clearErrors()
		setErrMsg('')
		setShow(false)
	}

	useEffect(()=>{
		if (auth?.username) {
			dispatch(getTasksByUser(auth?.user))
			// Geolocalization
			navigator.geolocation.getCurrentPosition(function(position){
				setLatitude(position.coords.latitude)
				setLongitude(position.coords.longitude)
			}, () => {}, {
				enableHighAccuracy: true, // Alta precisión
				maximumAge: 0, // No cache
			})
			CheckInOutStatus()
		}
	}, [auth])

	const data = {
		username: auth?.username || '',
		task: watch('task'),
	}

	const Sheet = async() => {
		dispatch(checkIn({...data, latitude: latitude, longitude: longitude}, {username: auth.username, device: localStorage.getItem("idDeviceRH") ? localStorage.getItem("idDeviceRH") : null}))
		setShow(false)
		dispatch(getCheckInOutStatus({username: auth.username, device: localStorage.getItem("idDeviceRH") ? localStorage.getItem("idDeviceRH") : null}))
	}

	const CheckInOutStatus = async() => {
		dispatch(getCheckRequestByDeviceAndUser({username: auth.username, user: auth.user, device: localStorage.getItem("idDeviceRH") ? localStorage.getItem("idDeviceRH") : null}))
	}

	useEffect(()=>{
		if(buttonState){
			if(buttonState !== 'nothing'){
				if(buttonState !== 'denied'){
					if(auth?.username){
						dispatch(getCheckInOutStatus({username: auth.username, device: localStorage.getItem("idDeviceRH") ? localStorage.getItem("idDeviceRH") : null}))
					}
				}
			}
		}
	}, [buttonState, auth?.username, dispatch])

	const ConfirmCheckOut = async() => {
		if(status === 'checkout' ){
			ConfirmPupup('¿Fichar Salida?')
			.then((result) => {
				if (result.isConfirmed) {
					Sheet()
				}
			})
		}
	}

	const createCheckRequest = async() => {
		let description = navigator.userAgent.split('(')
		description = description[1].split(')')
		description = description[0].replaceAll(';', ' -')

		if(deviceName !== ''){
			dispatch(addCheckRequest({
				username: auth.username,
				name: deviceName,
				description: description
			}))
			dispatch(getCheckRequestByDeviceAndUser({username: auth.username, user: auth.user, device: localStorage.getItem("idDeviceRH") ? localStorage.getItem("idDeviceRH") : null}))
		}else{
			Swal.fire({
				title: t("Introduzca un nombre al dispositivo para que el administrador pueda identificar a que dispositivo pertenece"),
				text: "",
				icon: 'error',
				showCancelButton: false,
				confirmButtonColor: 'red',
				confirmButtonText: t("Vale"),
			})
		}
	}

	const giveName = async(name) => {
		setDeviceName(name)
	}

	return (
		<>
		{buttonState === 'nothing'
			?
			<div className="d-flex flex-column flex-center h-100 p-6 bg-hover-secondary rounded-2 bg-gray-100 bg-opacity-70  px-6 py-5">
				<h4>{t('Nombre del dispositivo')}</h4>
				<Form.Control
					type="text"
					placeholder="Ej: Ordenador de casa, movil de trabajo..."
					aria-label={t('Introduzca un nombre de dispositivo para que el administrador pueda identificarlo')}
					aria-describedby="btnGroupAddon"
					onChange={(e) => {giveName(e.target.value)}}
					/>
				<br/>
				<Button onClick={createCheckRequest} className="btn btn-warning">
				{t('Pedir acceso a fichaje desde este dispositivo')}
				</Button>
			</div>
			:
			buttonState === 'denied'
				?
				<Button className="btn btn-secondary" disabled>
					{t('Aún no se ha activado su fichaje')}
				</Button>
				:
				status === 'checkin' 
					? <Button onClick={handleSubmit(Sheet)} className="btn btn-success">
						{t('Fichar entrada')}
					</Button>
					: 
					status === 'checkout' 
						?
							<Button onClick={ConfirmCheckOut} className="btn btn-danger">
							{t('Fichar salida')}
							</Button>
						:
							<Button  className="btn btn-primary">
							{t('Cargando...')}
							</Button>
		}
		{/* <Modal show={show} size='lg' backdrop='static' >
			<Modal.Header closeButton onClick={handleClose} >
			<Modal.Title className='fw-bolder fs-2'>{buttonState === 'Checkin' ? 'Fichar Entrada' : 'Fichar Salida'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			<div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
				<form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(Sheet)}>
				<div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
					<div className="fv-row mb-7">
					<label className="required fw-bold fs-6 mb-2">{t('Tarea')}</label>
					<div className="fs-6 fw-bold text-muted mb-3">{t('Tareas pendientes')}.</div>
					<select name="department" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Tarea" {...register(
						'task', {
						required: 'Este campo es obligatorio'
						}
					)} >
						<option value={null}>{t('Seleccionar tarea')}</option>
						{tasks?.length
						? (
							tasks.map((task, i) =>
							<option key={i} value={task.name}>{task.name}</option>
							)
						) : ''
						}
					</select>
					<div className="fv-plugins-message-container invalid-feedback">
						<div data-field="task" data-validator="notEmpty">{errors.task?.message}</div>
					</div>
					</div>
					<div className="fv-plugins-message-container invalid-feedback">
					<div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
					</div>
				</div>
				<div className="text-center pt-10">
					<button className="btn btn-light me-3" onClick={handleClose}>{t('Cancelar')}</button>
					<button className="btn btn-primary">
					<span className="indicator-label">{t('Aceptar')}</span>
					<span className="indicator-progress">{t('Porfavor espere...')}
						<span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
					</button>
				</div>
				</form>
			</div>
			</Modal.Body>
		</Modal> */}
		</>
	);
}