export const notificationReducer = (state = {notifications: null, notification: null}, action)=>{
    switch (action.type) {
        case 'NOTIFICATION_GET_SUCCESS':
            return {
                ...state,
                notifications: action.payload
            }
        case 'NOTIFICATION_GET_DETAIL_SUCCESS':
            return {
                ...state,
                notification: action.payload
            }
        case 'NOTIFICATION_SEND_SUCCESS':
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    action.payload
                ]
            }
        case 'NOTIFICATION_EDITED_SUCCESS':
            return {...state, notifications: state.notifications?.map(
                    notification => notification.id === action.payload.id
                    ?
                        action.payload
                    :
                        notification
                )
            }
        default:
            return state;
    }
}