import React from 'react'
import { Link } from 'react-router-dom'

export default function Error404() {
  return (
    <>
      {/*begin::Authentication - 404 Page*/}
      <div className="d-flex flex-column flex-center flex-column-fluid p-10">
        {/*begin::Illustration*/}
        <img src="/assets/media/illustrations/sigma-1/18.png" alt="notFoundRoute" className="mw-100 mb-10 h-lg-450px" />
        {/*end::Illustration*/}
        {/*begin::Message*/}
        <h1 className="fw-bold mb-10" style={{color: '#EA3546'}}>Error 404</h1>
        <h1 className="fw-bold mb-10" style={{color: '#A3A3C7'}}>Ruta desconocida</h1>
        {/*end::Message*/}
        {/*begin::Link*/}
        <Link to={'/'} className="btn btn-primary">Volver al inicio</Link>
        {/*end::Link*/}
      </div>
      {/*end::Authentication - 404 Page*/}
    </>
  )
}
