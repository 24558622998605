import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import useAuth from '../hooks/UseAuth'
import { useTranslation } from 'react-i18next'
import CreateAbsence from './modals/CreateAbsence'
import { getAbsencesUser } from '../actions/absenceActions'
import { CustomDataTable } from '../components/datatables/CustomDataTable'

export default function Absences() {
    const { t } = useTranslation()
    const {auth} = useAuth()
    const dispatch = useDispatch()

    const {absences} = useSelector(state => state.absences)

    useEffect(()=>{
        if(auth?.username){
            dispatch(getAbsencesUser(auth?.username))
        }
    },[auth, dispatch])

	const columns = [
		{
			name: t('Ausencia'),
			cell: absence =>
                <>
                    <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                        <div>
                            <div className="symbol-label fs-3 bg-light-danger text-danger">{absence.reason?.charAt(0)}</div>
                        </div>
                    </div>
                    <div className="d-flex flex-column text-gray-800">
                        {absence.reason}
                    </div>
                </>,
			selector: (absence) => absence.name,
			sortable: true,
		},{
			name: t('Tipo'),
			cell: absence =>
				<div>
					{absence.absenceType}
				</div>,
			selector: (absence) => absence.absenceType,
			sortable: true,
		},{
			name: t('Fecha de Inicio'),
			cell: absence =>
				<div>
					{absence.startDate}
				</div>,
			selector: (absence) => absence.startDate,
			sortable: true,
		},{
			name: t('Fecha de Fin'),
			cell: absence =>
				<div>
					{absence.endDate}
				</div>,
			selector: (absence) => absence.endDate,
			sortable: true,
		},{
			name: 'Estado',
			cell: absence =>
                <div>
                    {
                    absence.status === 'Aceptada' 
                        ?
                            <span className="badge badge-light-success fs-8 fw-bolder">{absence.status}</span>
                        : 
                            absence.status === 'Denegada' 
                                ? 
                                    <span className="badge badge-light-danger fs-8 fw-bolder">{absence.status}</span>
                                : 
                                    absence.status === 'Pendiente de revisión' 
                                        ?
                                            <span className="badge badge-light-info fs-8 fw-bolder">{absence.status}</span>
                                        :
                                            <></>
                    }
                </div>
		}
	]

    return (
        <>
            <div className="toolbar py-5 py-lg-15" id="kt_toolbar">
                <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
                    <div className="page-title d-flex flex-column me-3">
                        <h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Mis Ausencias')}</h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                            <li className="breadcrumb-item text-white opacity-75">
                                <Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
                            </li>
                            <li className="breadcrumb-item text-white">{t('Mis Ausencias')}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    <div className="card">
                        <div className="card-header border-0 pt-6">
                            <div className="card-toolbar">
                                <CreateAbsence button />
                            </div>
                        </div>
                        <div className="card-body py-4">
                            <div className='table-responsive'>
                                {absences?.length > 0
                                    ?
                                        <CustomDataTable columns={columns} data={absences} />
                                    :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}