import AxiosControl from '../hooks/AxiosControl'
const Axios = AxiosControl()

export const getCheckRequests = () => async (dispatch) => {
	try {
		const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/checkrequests', {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'CHECKREQUEST_GET_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}

export const getCheckRequest = (checkrequest) => async (dispatch) => {
	try {
		const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/checkrequest/' + checkrequest, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'CHECKREQUEST_GET_DETAIL_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}

export const getCheckRequestByDeviceAndUser = (data) => async (dispatch) => {
	try {
		const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/checkrequestbydeviceanduser', {
			device: data.device,
			username: data.username,
			user: data.user
		}, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'CHECKREQUEST_GET_BYDEVICEANDUSER_SUCCESS',
				payload: {
					buttonState: response.data.buttonState
				}
			}) 
		}
	} catch(error){
		console.log(error)
	}
}

export const getCheckInOutStatus = (data) => async (dispatch) => {
	try {
		const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/sheet/checkInOut/status', {
			username: data.username,
			device: data.device,
		}, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'CHECKREQUEST_GET_INOUT_SUCCESS',
				payload: {
					status: response.data.status
				}
			})

		}
	} catch(error){
		console.log(error)
	}
}

export const addCheckRequest = (data) => async (dispatch) => {
	try {
		const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/checkrequest/add', {
			username: data.username,
			name: data.name,
			description: data.description
		}, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			localStorage.setItem('idDeviceRH', response.data)
			dispatch({
				type: 'CHECKREQUEST_SEND_SUCCESS',
				payload: response.data
			}) 
			const responseTwo = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/checkrequestbydeviceanduser', {
				device: response.data,
				username: data.username,
				user: data.user
			}, {
				headers: {
					authorization: 'Bearer ' + localStorage.getItem('RHjwt')
				}
			})
			if(responseTwo.status===202){
				dispatch({
					type: 'CHECKREQUEST_GET_BYDEVICEANDUSER_SUCCESS',
					payload: {
						status: responseTwo.data.status,
						buttonState: responseTwo.data.buttonState
					}
				}) 
			}
		}
	} catch(error){
		console.log(error)
	}
}

export const enableCheckRequest = (checkrequest) => async (dispatch) => {
	try {
		const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/checkrequest/' + checkrequest + '/enable', {}, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'CHECKREQUEST_EDITED_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}

export const disableCheckRequest = (checkrequest) => async (dispatch) => {
	try {
		const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/checkrequest/' + checkrequest + '/remove', {}, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'CHECKREQUEST_DELETE_SUCCESS',
				payload: checkrequest
			}) 
		}
	} catch(error){
		console.log(error)
	}
}

export const enableAllCheckRequest = (checkrequest) => async (dispatch) => {
	try {
		const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/checkrequest/' + checkrequest + '/enableall', {}, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'CHECKREQUEST_EDITED_SUCCESS',
				payload: response.data
			}) 
			dispatch({
				type: 'CHECKREQUEST_SEND_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}