import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { getLogs } from '../actions/logActions';
import useAuth from '../hooks/UseAuth';
import { CustomDataTable } from '../components/datatables/CustomDataTable';

export default function Logs() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {auth} = useAuth()

	const {logs} = useSelector(state => state.logs)

	useEffect(() => {
		if(auth){
			dispatch(getLogs())
		}
	}, [auth, dispatch])

	const columns = [
		{
			name: t('Usuario'),
			cell: log =>
				<div>
					<Link to={`/user/${log.user.id}`} className="d-flex flex-column text-gray-800 text-hover-primary fs-3 ms-2">
						{log.user.username}
					</Link>
				</div>,
			selector: (log) => log.user.username,
			sortable: true,
		},{
			name: t('Fecha'),
			cell: log =>
				<div>
					{log.day}
				</div>,
			selector: (log) => log.day,
			sortable: true,
		},{
			name: t('Hora'),
			cell: log =>
                    <div>
                        {log.hour}
                    </div>,
			selector: (log) => log.hour,
			sortable: true,
		},{
			name: t(''),
			cell: log =>
				<div>
					{log.action}
				</div>,
			selector: (log) => log.action,
			sortable: true,
		}
	]

	return (
		<>
			<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
				<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
					<div className="page-title d-flex flex-column me-3">
						<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Registros')}</h1>
						<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white">{t('Registros')}</li>
						</ul>
					</div>
				</div>
			</div>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					<div className="card">
						<div className="card-body py-4">
							<div className='table-responsive'>
							{logs?.length > 0
								?
									<CustomDataTable columns={columns} data={logs} />
								:
									<></>
							}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
