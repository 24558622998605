import React from 'react'

export default function Toolbar({title='', children}) {
  return (
    <>
      {/*begin::Toolbar*/}
      <div className="toolbar py-5 py-lg-15" id="kt_toolbar">
        {/*begin::Container*/}
        <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
          {/*begin::Page title*/}
          <div className="page-title d-flex flex-column me-3">
            {/*begin::Title*/}
            <h1 className="d-flex text-white fw-bolder my-1 fs-3">
              { title }
            </h1>
            {/*end::Title*/}
          </div>
          {/*end::Page title*/}
          {/*begin::Actions*/}
          <div className="d-flex align-items-center py-3 py-md-1">
            {/*begin::Wrapper*/}
            <div>
              { children }
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Actions*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Toolbar*/}
    </>
  )
}

