import AxiosControl from '../hooks/AxiosControl'
const Axios = AxiosControl()

export const getMonths = (user) => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/sheet/getmonthschecked/' + user, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status===200){
            dispatch({
                type: 'REPORT_GET_MONTHS',
                payload: response.data.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const getMonthsDepartment = (department) => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/sheet/getmonthsdepartmentchecked/' + department, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status===200){
            dispatch({
                type: 'REPORT_GET_MONTHSDEPARTMENT',
                payload: response.data.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const getYears = (user) => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/sheet/getyearschecked/' + user, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status===200){
            dispatch({
                type: 'REPORT_GET_YEARS',
                payload: response.data.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const getYearsDepartment = (department) => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/sheet/getyearsdepartmentchecked/' + department, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status===200){
            dispatch({
                type: 'REPORT_GET_YEARSDEPARTMENT',
                payload: response.data.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}