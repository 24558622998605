import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/UseAuth';
import { acceptSheetCorrected, denySheetCorrected, getSheetCorrected } from '../../actions/sheetActions';
import { getTasksByUser } from '../../actions/taskActions';

export default function ModifiedSheet() {
	const {auth} = useAuth()
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [correctedSheets, setCorrectedSheets] = useState([])
	const [comments, setComments] = useState([])

	const {sheets} = useSelector(state => state.sheets)
	const {tasks} = useSelector(state => state.tasks)

	useEffect(() => {
		if (sheets?.length > 0 && tasks) {
			let usedDates = []
			let usedSheets = []
			let foundSheet = sheets[0]
			while (foundSheet) {
				usedDates.push(foundSheet.date)
				let sheetsWithDate = sheets.filter(s => s.date === foundSheet.date)
				usedSheets.push({date: foundSheet.date, sheets: sheetsWithDate})
				foundSheet = sheets?.find(s => usedDates.indexOf(s.date) === -1)
			}
			setCorrectedSheets(usedSheets)
			let sheetsComments = []
			for (let i = 0; i < usedSheets.length; i++) {
				sheetsComments.push('')
			}
			setComments(sheetsComments)
		} else {
			setCorrectedSheets([])
		}
	}, [sheets, tasks])

	const changeComment = (index, value) => {
		setComments(comments.map((c, i) => {
		if (i === index) {
			return value
		} else {
			return c
		}
		}))
	}

	const acceptCorrection = async (index) => {
		dispatch(acceptSheetCorrected({sheets: correctedSheets[index].sheets}))
		let newComments = []
		comments.forEach((c, i) => {
			if (i === index) {
				newComments.push(c)
			}
		})
		setComments(newComments)
		dispatch(getSheetCorrected(auth.username))
	}

	const denyCorrection = async (index) => {
		dispatch(denySheetCorrected({sheets: correctedSheets[index].sheets, comment: comments[index]}))
		let newComments = []
		comments.forEach((c, i) => {
			if (i === index) {
				newComments.push(c)
			}
		})
		setComments(newComments)
		dispatch(getSheetCorrected(auth.username))
	}

	// When users comes first time
	useEffect(() => {
		if(auth?.username){
			dispatch(getSheetCorrected(auth.username))
			dispatch(getTasksByUser(auth.user))
		}
	}, [auth, dispatch])


	return (
		<div>
		{/*begin::Toolbar*/}
		<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
		{/*begin::Container*/}
		<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
			{/*begin::Page title*/}
			<div className="page-title d-flex flex-column me-3">
			{/*begin::Title*/}
			<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Fichajes modificados')}</h1>
			{/*end::Title*/}
			{/*begin::Breadcrumb*/}
			<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
				{/*begin::Item*/}
				<li className="breadcrumb-item text-white opacity-75">
				<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
				</li>
				{/*end::Item*/}
				{/*begin::Item*/}
				<li className="breadcrumb-item">
				<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
				</li>
				{/*end::Item*/}
				{/*begin::Item*/}
				<li className="breadcrumb-item text-white">{t('Fichajes modificados')}</li>
				{/*end::Item*/}
			</ul>
			{/*end::Breadcrumb*/}
			</div>
			{/*end::Page title*/}
		</div>
		{/*end::Container*/}
		</div>
		{/*end::Toolbar*/}
		{/*begin::Container*/}
		<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
		{/*begin::Sheets*/}
		<div className="content flex-row-fluid" id="kt_content">
			<div className="row g-6 g-xl-9">
			{/*begin::Sheet detail*/}
			{
				correctedSheets.length > 0
				?
				correctedSheets.map((s, index) => (
					<div className="col-12" key={index}>
					<div className="card h-100">
						<div className='card-header pt-5'>
						<h3>{s.date}</h3>
						</div>
						<div className="card-body p-9">
						<div className='row align-items-center'>
							<div className='col-7'>
							<div className='row'>
								{
								s.sheets.map(sheet => (
									<Fragment key={sheet.id}>
									<div className='col-4'>
										<div>
										<label>{t('Hora de entrada')}</label>
										</div>
										{
										sheet.checkInTime && !sheet.deleted
										?
											<div><strike>{sheet.checkInTime}</strike></div>
										:
											<></>
										}
										<div style={sheet.deleted ? {textDecoration: 'line-through'} : {}}>{sheet.editedCheckInTime}</div>
									</div>
									<div className='col-4'>
										<div>
										<label>{t('Hora de salida')}</label>
										</div>
										{
											sheet.checkOutTime && !sheet.deleted
											?
											<div><strike>{sheet.checkOutTime}</strike></div>
											:
											<></>
										}
										<div style={sheet.deleted ? {textDecoration: 'line-through'} : {}}>{sheet.editedCheckOutTime}</div>
									</div>
									<div className='col-4'>
										<div>
										<label>{t('Tarea')}</label>
										</div>
										{
											sheet.task && !sheet.deleted
											?
											<div><strike>{tasks?.find(task => task.id === sheet.task)?.name}</strike></div>
											:
											<></>
										}
										<div style={sheet.deleted ? {textDecoration: 'line-through'} : {}}>{tasks?.find(t => t.id === sheet.editedTask)?.name}</div>
									</div>
									</Fragment>
								))
								}
							</div>
							</div>
							<div className='col-3'>
							<textarea onChange={(e) => {changeComment(index, e.target.value)}} value={comments[index]} className='form-control' />
							</div>
							<div className='col-1'>
							<button type="button" className='btn btn-danger' onClick={() => {denyCorrection(index)}}>
								<i className='fas fa-times' />
							</button>
							</div>
							<div className='col-1'>
							<button type="button" className='btn btn-success' onClick={() => {acceptCorrection(index)}}>
								<i className='fas fa-check' />
							</button>
							</div>
						</div>
						</div>
					</div>
					</div>
				))
				:
				<div className='col col-12'>
					<div className='card h-100'>
					<div className='card-header py-5'>
						<h3 className='m-0'>{t('No hay correcciones que mostrar')}</h3>
					</div>
					</div>
				</div>
			}
			{/*end::Sheet detail*/}
			</div>
		</div>
		{/*end::Sheets*/}
		</div>
		{/*end::Container*/}
	</div>
	)
}