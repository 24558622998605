import React, { useEffect, useState } from 'react'
import { Accordion, Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { editNotification, getNotifications } from '../../actions/notificationActions'
import useAuth from '../../hooks/UseAuth'
import { sortNotifications } from '../../services/sortNotifications'
import CreateNotification from '../modals/CreateNotification'
import { useTranslation } from 'react-i18next'

export default function Notifications() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {auth} = useAuth()

    const {notifications} = useSelector(state => state.notifications)
    
    const [aSentNotifications, setASentNotifications] = useState([])
    const [aUserNotifications, setAUserNotifications] = useState([])

    const [showReceived, setShowReceived] = useState(true)
    const handleSent = () => setShowReceived(false)
    const handleReceived = () => setShowReceived(true)

    useEffect(() => {
        if(auth){
            dispatch(getNotifications(auth?.user))
        }
    }, [auth, dispatch])

    useEffect(() => {
        if(notifications){
            setASentNotifications(sortNotifications(notifications?.filter(notification => notification.sendUser.username === auth.username && notification.receiverUser.username !== auth.username)))
            setAUserNotifications(sortNotifications(notifications?.filter(notification => notification.receiverUser.username === auth.username && notification.sendUser.username !== auth.username)))
        }
    }, [notifications, auth])

    const handleView = async(notification)=> {
        if(!notification.view){
            dispatch(editNotification(notification.id))
        }
    }

    return (
        <>
        {/*begin::Toolbar*/}
        <div className="toolbar py-5 py-lg-15" id="kt_toolbar">
        {/*begin::Container*/}
        <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
            {/*begin::Page title*/}
            <div className="page-title d-flex flex-column me-3">
            {/*begin::Title*/}
            <h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Notificaciones')}</h1>
            {/*end::Title*/}
            {/*begin::Breadcrumb*/}
            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                {/*begin::Item*/}
                <li className="breadcrumb-item text-white opacity-75">
                <Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item">
                <span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item text-white">{t('Notificaciones')}</li>
                {/*end::Item*/}
            </ul>
            {/*end::Breadcrumb*/}
            </div>
            {/*end::Page title*/}
        </div>
        {/*end::Container*/}
        </div>
        {/*end::Toolbar*/}
        {/*begin::Container*/}
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        {/*begin::Post*/}
        <div className="content flex-row-fluid" id="kt_content">
            {/*begin::Inbox App - Messages */}
            <div className="d-flex flex-column flex-lg-row">
            {/*begin::Sidebar*/}
            <div className="flex-column flex-lg-row-auto w-100 w-lg-275px mb-10 mb-lg-0">
                {/*begin::Sticky aside*/}
                <div className="card card-flush mb-0" data-kt-sticky="false" data-kt-sticky-name="inbox-aside-sticky" data-kt-sticky-offset="{default: false, xl: '0px'}" data-kt-sticky-width="{lg: '275px'}" data-kt-sticky-left="auto" data-kt-sticky-top="150px" data-kt-sticky-animation="false" data-kt-sticky-zindex={95}>
                {/*begin::Aside content*/}
                <div className="card-body">
                    {/*begin::Button*/}
                    <CreateNotification normalButton />
                    {/*end::Button*/}
                    {/*begin::Menu*/}
                    <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10">
                    {/*begin::Menu item*/}
                    <div className="menu-item mb-3">
                        {/*begin::Inbox*/}
                        <span className={showReceived ? 'menu-link active' : 'menu-link'} onClick={handleReceived}>
                        <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/communication/com010.svg*/}
                            <span className="svg-icon svg-icon-2 me-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                <path d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z" fill="black" />
                                <path opacity="0.3" d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z" fill="black" />
                            </svg>
                            </span>
                            {/*end::Svg Icon*/}
                        </span>
                        <span className="menu-title fw-bolder">{t('Entrantes')}</span>
                        <span className="badge badge-light-success">{aUserNotifications?.length}</span>
                        </span>
                        {/*end::Inbox*/}
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item mb-3">
                        {/*begin::Marked*/}
                        <span className={showReceived ? 'menu-link' : 'menu-link active'} onClick={handleSent}>
                        <span className="menu-icon">
                            {/*begin::Svg Icon | path: icons/duotune/abstract/abs024.svg*/}
                            <span className="svg-icon svg-icon-2 me-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M7.16973 20.95C6.26973 21.55 5.16972 20.75 5.46972 19.75L7.36973 14.05L2.46972 10.55C1.56972 9.95005 2.06973 8.55005 3.06973 8.55005H20.8697C21.9697 8.55005 22.3697 9.95005 21.4697 10.55L7.16973 20.95Z" fill="black" />
                                <path d="M11.0697 2.75L7.46973 13.95L16.9697 20.85C17.8697 21.45 18.9697 20.65 18.6697 19.65L13.1697 2.75C12.7697 1.75 11.3697 1.75 11.0697 2.75Z" fill="black" />
                            </svg>
                            </span>
                            {/*end::Svg Icon*/}
                        </span>
                        <span className="menu-title fw-bolder">{t('Enviadas')}</span>
                        </span>
                        {/*end::Marked*/}
                    </div>
                    {/*end::Menu item*/}
                    </div>
                    {/*end::Menu*/}
                </div>
                {/*end::Aside content*/}
                </div>
                {/*end::Sticky aside*/}
            </div>
            {/*end::Sidebar*/}
            {/*begin::Content*/}
            <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                {/*begin::Card*/}
                <div className="card">
                <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                    {/*begin::Pagination*/}
                    <div className="d-flex align-items-center flex-wrap mt-1">
                    <h2>Notificaciones</h2>
                    </div>
                    {/*end::Pagination*/}
                </div>
                <div className="card-body p-0">
                    <Accordion defaultActiveKey="0" flush>
                    {(
                        showReceived ?
                        aUserNotifications?.map((notification, i) =>
                        <Accordion.Item eventKey={i} key={i}>
                            <div className="separator"></div>
                            <Accordion.Header as={'div'}>
                            <div className="d-flex flex-wrap gap-2 flex-stack cursor-pointer w-100" data-kt-inbox-message="header">
                                {/*begin::Author*/}
                                <div className="d-flex align-items-center">
                                {/*begin::Avatar*/}
                                <div className="symbol symbol-50 me-4">
                                    <span className="symbol-label" style={{backgroundImage: `url(${notification.sendUser.imageProfile})`}} />
                                </div>
                                {/*end::Avatar*/}
                                <div className="pe-5">
                                    {/*begin::Author details*/}
                                    <div className="d-flex align-items-center flex-wrap gap-1">
                                    <span className="text-gray-700 fw-bolder">{t('De')}:</span>
                                    <Link to={'#'} className="fw-bolder text-dark text-hover-primary me-6">{notification.sendUser.username}</Link>
                                    {/*begin::Preview message*/}
                                    {/*end::Preview message*/}
                                    </div>
                                    {/*end::Author details*/}
                                    
                                </div>
                                </div>
                                <div className="text-muted fw-bold mw-450px flex-fill" data-kt-inbox-message="preview">{notification.title.substring(0,50)}</div>

                                {/*end::Author*/}
                                {/*begin::Actions*/}
                                <div className="d-flex align-items-center flex-wrap gap-2">
                                {/*begin::Date*/}
                                <span className="fw-bold text-muted text-end me-3">{notification.dateTimeFormated}</span>
                                {/*end::Date*/}
                                <div className="d-flex">
                                    {/*begin::Star*/}
                                    <Link to={'#'} className="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Star">
                                    {
                                        notification.view 
                                        ? <span className="svg-icon svg-icon-muted svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z" fill="currentColor"/>
                                            </svg></span>
                                        : <span className="svg-icon svg-icon-muted svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"/>
                                            <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"/>
                                        </svg></span>
                                    }
                                    </Link>
                                    {/*end::Star*/}
                                </div>
                                </div>
                                {/*end::Actions*/}
                            </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="py-5">
                                <h3>{notification.title}</h3>
                                <p>{notification.description}</p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        )
                        :
                        aSentNotifications?.map((notification, i) =>
                        <Accordion.Item eventKey={i} key={i}>
                            <div className="separator"></div>
                            <Accordion.Header>
                            <div className="d-flex flex-wrap gap-2 flex-stack cursor-pointer w-100" data-kt-inbox-message="header">
                                {/*begin::Author*/}
                                <div className="d-flex align-items-center">
                                {/*begin::Avatar*/}
                                <div className="symbol symbol-50 me-4">
                                    <span className="symbol-label" style={{backgroundImage: `url(${notification.receiverUser.imageProfile})`}} />
                                </div>
                                {/*end::Avatar*/}
                                <div className="pe-5">
                                    {/*begin::Author details*/}
                                    <div className="d-flex align-items-center flex-wrap gap-1">
                                    <span className="text-gray-700 fw-bolder">{t('Para')}:</span>
                                    <Link to={'#'} className="fw-bolder text-dark text-hover-primary me-6">{notification.receiverUser.username}</Link>
                                    {/*begin::Preview message*/}
                                    {/*end::Preview message*/}
                                    </div>
                                    {/*end::Author details*/}
                                    
                                </div>
                                </div>
                                <div className="text-muted fw-bold mw-450px flex-fill" data-kt-inbox-message="preview">{notification.title.substring(0,50)}</div>

                                {/*end::Author*/}
                                {/*begin::Actions*/}
                                <div className="d-flex align-items-center flex-wrap gap-2">
                                {/*begin::Date*/}
                                <span className="fw-bold text-muted text-end me-3">{notification.dateTimeFormated}</span>
                                {/*end::Date*/}
                                <div className="d-flex">
                                </div>
                                </div>
                                {/*end::Actions*/}
                            </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="py-5">
                                <h3>{notification.title}</h3>
                                <p>{notification.description}</p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        )
                    )}
                    </Accordion>
                </div>
                </div>
                {/*end::Card*/}
            </div>
            {/*end::Content*/}
            </div>
            {/*end::Inbox App - Messages */}
        </div>
        {/*end::Post*/}
        </div>
        {/*end::Container*/}
        {/*start::Notification modal*/}
        {
            aUserNotifications?.find(n => n.view === false)
            ?
            <Modal show={true}>
                <Modal.Header className='modal-header py-7 d-flex justify-content-between'>
                <h2>{aUserNotifications?.find(n => n.view === false)?.title}</h2>
                </Modal.Header>
                <Modal.Body>
                <div>{aUserNotifications?.find(n => n.view === false)?.description}</div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant='primary' type='button' onClick={() => {handleView(aUserNotifications?.find(n => n.view === false))}}>{t('Aceptar')}</Button>
                </Modal.Footer>
            </Modal>
            :
            <></>
        }
        {/*end::Notification modal*/}
    </>
    )
}