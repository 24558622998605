import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { disableDepartment, enableDepartment, getDepartments } from '../../actions/departmentActions'
import { ConfirmPupup } from '../../components/popups/Popups'
import CreateDepartment from '../modals/CreateDepartment'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/UseAuth'
import EditDepartment from '../modals/EditDepartment'
import { CustomDataTable } from '../../components/datatables/CustomDataTable'

export default function ListDepartment() {
	
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {auth} = useAuth()

	const {departments} = useSelector(state => state.departments)

	useEffect(() => {
		if(auth){
			dispatch(getDepartments())
		}
	}, [auth])

	const ConfirmEnableDepartment=(departmentId, departmentName)=>{
		ConfirmPupup(`Activar departmento ${departmentName}?`)
		.then((result) => {
			if (result.isConfirmed) {
				EnableDepartment(departmentId)
			}
		})
	}

	const ConfirmDisableDepartment=(departmentId, departmentName)=>{
		ConfirmPupup(`Desactivar departmento ${departmentName}?`)
		.then((result) => {
			if (result.isConfirmed) {
				DisableDepartment(departmentId, departmentName)
			}
		})
	}

	const EnableDepartment = async(departmentId)=>{
		dispatch(enableDepartment(departmentId))
		dispatch(getDepartments())
	}

	const DisableDepartment = async(departmentId, departmentName)=>{
		dispatch(disableDepartment(departmentId))
		dispatch(getDepartments())
	}

	const columns = [
		{
			name: t('Departamento'),
			cell: department =>
				<div className="d-flex align-items-center border-0">
					<div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
						<Link to={`/department/${department.id}`}>
						<div className="symbol-label fs-3 bg-light-danger text-danger">{department.name?.charAt(0)}</div>
						</Link>
					</div>
					<div className="d-flex flex-column">
						<Link to={`/department/${department.id}`} className="text-gray-800 text-hover-primary mb-1">
							{department.name}
						</Link>
						<span></span>
					</div>
				</div>,
			selector: (department) => department.name,
			sortable: true,
		},{
			name: t('Descripción'),
			cell: department =>
				<div>
					{department.description}
				</div>,
			selector: (department) => department.description,
			sortable: true,
		},{
			name: t('Dirección'),
			cell: department =>
				<div>
					{department.address}
				</div>,
			selector: (department) => department.address,
			sortable: true,
		},{
			name: t('Activo'),
			cell: department =>
				<div>
					{department.isActive === true
						?
							<span className='text-success'>{t('Activo')}</span>
						:
							<span className='text-danger'>{t('Desactivado')}</span>
					}
				</div>,
			selector: (department) => department.address,
			sortable: true,
		},{
			name: '',
			cell: department =>
				<div className='text-end'>
					<Link to={'#'} className="btn btn-light btn-active-light-primary btn-sm" data-bs-toggle="dropdown" data-kt-menu-placement="bottom-end">{t('Acciones')}
						<span className="svg-icon svg-icon-5 m-0">
							<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
								<path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
							</svg>
						</span>
					</Link>
					<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4 dropdown-menu">
						<div className="menu-item px-3">
						<EditDepartment department={department} lightLink />
						</div>
						<div className="menu-item px-3">
						{
							department.isActive ? 
							<Link to={'#'} className="menu-link px-3 text-danger"
								onClick={() => {
								ConfirmDisableDepartment(department?.id, department?.name)
								}}
							>
								{t('Desactivar')}
							</Link>
							: 
							<Link to={'#'} className="menu-link px-3 text-success"
								onClick={() => {
								ConfirmEnableDepartment(department?.id, department?.name)
								}}
							>
								{t('Activar')}
							</Link>
						}
						</div>
					</div>
				</div>
		}
	]

	return (
		<>
			<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
				<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
					<div className="page-title d-flex flex-column me-3">
						<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Listar Departamentos')}</h1>
						<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white">{t('Listar Departamentos')}</li>
						</ul>
					</div>
				</div>
			</div>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					<div className="card">
						<div className="card-header border-0 pt-6">
							<div className="card-toolbar">
								<div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
									<CreateDepartment />
								</div>
								<div className="d-flex justify-content-end align-items-center d-none" data-kt-user-table-toolbar="selected">
									<div className="fw-bolder me-5">
										<span className="me-2" data-kt-user-table-select="selected_count" />Selected
									</div>
									<button type="button" className="btn btn-danger" data-kt-user-table-select="delete_selected">Delete Selected</button>
								</div>
							</div>
						</div>
						<div className="card-body py-4">
							<div className='table-responsive'>
                                {departments?.length > 0
                                    ?
                                        <CustomDataTable columns={columns} data={departments} />
                                    :
                                        <></>
                                }
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}