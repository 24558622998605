import AxiosControl from '../hooks/AxiosControl'
const Axios = AxiosControl()

export const getVacations = () => async (dispatch) => {
	
	try {
		const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/vacation', {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'VACATION_GET_SUCCESS',
				payload: response.data
			})
		}
		
	} catch(error){
		console.log(error)
		
	}
}

export const getVacationsSupervised = (user) => async (dispatch) => {
	
	try {
		const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/vacation/supervisedVacations/' + user,{
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'VACATION_GET_SUCCESS',
				payload: response.data
			}) 
		}
		
	} catch (error) {
		console.log(error);
		
	}
}

export const getVacationsManaged = (user) => async (dispatch) => {
	
	try {
		const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/vacation/managedVacations/' + user,{
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'VACATION_GET_SUCCESS',
				payload: response.data
			}) 
		}
		
	} catch (error) {
		console.log(error);
		
	}
}

export const getVacationsUser = (user) => async (dispatch) => {
	
	try {
		const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/vacation/userVacations', {
			username: user
		},{
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'VACATION_GET_SUCCESS',
				payload: response.data
			})
		}
		
	} catch(error){
		console.log(error)
		
	}
}

export const getVacationsByRange = (data, user) => async (dispatch) => {
	
	try {
		const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/vacation/userVacationsByRange/' + user, data,{
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'VACATION_GET_SUCCESS',
				payload: response.data
			})
		}
		
	} catch(error){
		console.log(error)
		
	}
}

export const addVacation = (data) => async (dispatch) => {
	
	try {
		const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/vacation', data, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'VACATION_SEND_SUCCESS',
				payload: response.data
			}) 
		}
		
	} catch(error){
		console.log(error)
		
	}
}

export const editVacation = (id, accepted) => async (dispatch) => {
	
	try {
		const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/vacation/' + id, {
			accepted: accepted
		}, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'VACATION_UPDATE_SUCCESS',
				payload: response.data
			})
			if (accepted) {
				dispatch({
					type: 'VACATION_UPDATE_SUCCESS',
					payload: response.data
				})
			} else {
				dispatch({
					type: 'VACATION_DELETED_SUCCESS',
					payload: id
				})
			}
		}
		
	} catch (error) {
		console.log(error);
		
	}
}

export const editMultipleVacations = (vacations, accepted) => async (dispatch) => {
	
	let errors = 0
	try {
		for await (const vacation of vacations) {
			const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/vacation/' + vacation.id, {
				accepted: accepted
			}, {
				headers: {
					authorization: 'Bearer ' + localStorage.getItem('RHjwt')
				}
			})
			if (response.status > 399) {
				errors++
			}
		}

		if (errors === 0) {
			dispatch({
				type: 'VACATION_MASSIVE_UPDATE_SUCCESS'
			})
		}
	} catch (error) {
		console.log(error);
		
	}
}

export const removeVacation = (id) => async (dispatch) => {
	
	try {
		const response = await Axios.delete(process.env.REACT_APP_DB_ADDRESS + '/api/vacation/' + id, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'VACATION_DELETED_SUCCESS',
				payload: id
			})
		}
		
	} catch (error) {
		console.log(error);
		
	}
}

export const requestVacations = (newVacations, deletedVacations, user) => async (dispatch) => {
	
	let errors = 0
	try {
		for await (const vacation of newVacations) {
			const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/vacation', {
				date: vacation,
				user: user,
			}, {
				headers: {
					authorization: 'Bearer ' + localStorage.getItem('RHjwt')
				}
			})
			if (response.status > 399) {
				errors++
			}
		}

		for await (const vacation of deletedVacations) {
			const response = await Axios.delete(process.env.REACT_APP_DB_ADDRESS + '/api/vacation/' + vacation.id, {
				headers: {
					authorization: 'Bearer ' + localStorage.getItem('RHjwt')
				}
			})
			if (response.status > 399) {
				errors++
			}
		}

		if (errors === 0) {
			dispatch({
				type: 'VACATION_MASSIVE_UPDATE_SUCCESS'
			})
		}
	} catch (error) {
		console.log(error);
		
	}
}