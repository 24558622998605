import React, { Fragment, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { editDepartment } from '../../actions/departmentActions'
import { SuccessPopup } from '../../components/popups/Popups'
import { useTranslation } from 'react-i18next'

export default function EditDepartment(props) {
  
  const { t } = useTranslation()
  const dispatch = useDispatch()
  
	const {users} = useSelector(state => state.users)
  
  // Si la ruta contiene la id la recoge de ahi, sino la recoge por parametros
  
  const {register, handleSubmit, watch, clearErrors, setValue} = useForm()
  const [errMsg, setErrMsg] = useState('')
  const [festives, setFestives] = useState([])

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = (e) => {
    e.preventDefault()
    clearErrors()
    setErrMsg('')
    setShow(false)
  }

  const department = props.department

  const data = {
    description: watch('form.description'),
    address: watch('form.address'),
		manager: watch('manager')
  }

  useEffect(()=>{
    setValue('form', department)
    if (department?.festives) {
      setFestives(department.festives)
    }

    if (department?.manager) {
      setValue('manager', department.manager.id)
    }
  }, [setValue, department])

  const UpdateDepartment = async() => {
    dispatch(editDepartment({...data, festives: festives}, department?.id))
    setErrMsg('')
    setShow(false)
    SuccessPopup(`Departamento modificado correctamente!`)
  }

  const addFestive = () => {
    setFestives([...festives, ''])
  }

  const changeFestive = (index, value) => {
    setFestives(festives?.map((f, i) => {
      if (i === index) {
        return value
      } else {
        return f
      }
    }))
  }

  const removeFestive = (index) => {
    let newFestives = []
    festives.forEach((f, i) => {
      if (i !== index) {
        newFestives.push(f)
      }
    })
    setFestives(newFestives)
  }

  return (
    <>
      {
        props.lightLink 
        ? <Link to={'#'} onClick={handleShow} className="menu-link px-3">
            {t('Editar')}
          </Link>
        : <Button variant="primary" onClick={handleShow} className="btn btn-sm btn-light-primary">
            {t('Editar')}
          </Button>
      }

      <Modal show={show} size='lg' backdrop='static' >
        <Modal.Header closeButton onClick={handleClose} >
          <Modal.Title className='fw-bolder fs-2'>{t('Editar Departamento')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*begin::Modal body*/}
          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
            {/*begin::Form*/}
            <form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(UpdateDepartment)}>
              {/*begin::Scroll*/}
              <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
                {/* <!--begin::Input group--> */}
                <div className="fv-row mb-7 fv-plugins-icon-container">
                  <label className="form-label fw-bolder text-dark fs-6">{t('Descripción')}</label>
                  <input className="form-control form-control-lg form-control-solid" type="text" name="description" autoComplete="off" {...register('form.description')} />
                </div>
                {/* <!--end::Input group--> */}
                {/* <!--begin::Input group--> */}
                <div className="fv-row mb-7 fv-plugins-icon-container">
                  <label className="form-label fw-bolder text-dark fs-6">{t('Dirección')}</label>
                  <input className="form-control form-control-lg form-control-solid" type="text" name="address" autoComplete="off" {...register('form.address')} />
                </div>
                <div className='fv-row mb-7'>
                  <label className='fw-bold fs-6 mb-2'>{t('Responsable')}</label>
                  <select className="form-select form-select-solid" placeholder='Selecciona el Usuario' {...register("manager", { required: false })} >
                    {users?.length
                      ?
                        <>
                          <option></option>
                          {users.map((user, i) => 
                            user.isActive === true ?
                                <option value={user.id} key={i}>{user.username}</option>
                              :
                                <Fragment key={i}></Fragment>
                          )}
                        </>
                      :
                        <option disabled>{t('No existen usuarios')}</option>
                    }
                  </select>
                </div>
                {/* <!--end::Input group--> */}
                {/* <!--begin::Api error message--> */}
                <div className="fv-plugins-message-container invalid-feedback">
                  <div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
                </div>
                {/* <!--end::Api error message--> */}

                {/* start::festives inputs */}
                <div className='row my-4'>
                  <div className='col col-12 d-grid gap-2'>
                    <button type='button' className='btn btn-info' onClick={() => {addFestive()}}>{t('Añadir festivo')}</button>
                  </div>
                  <div className='col col-12'>
                    <div className='row mt-3'>
                      {
                        festives?.map((festive, index) => (
                          <div key={index} className='col col-md-6 my-3 col-sm-12'>
                            <div className='row'>
                              <div className='col-10'>
                                <input required type='date' className='form-control' value={festive} onChange={(e) => {changeFestive(index, e.target.value)}} />
                              </div>
                              <div className='col-2'>
                                <button type='button' className='btn btn-danger w-100 h-100 d-flex justify-content-center align-items-center' onClick={() => {removeFestive(index)}}><i className='fas fa-trash p-0' /></button>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
                {/* end::festives inputs */}
              </div>
              {/*end::Scroll*/}
              {/*begin::Actions*/}
              <div className="text-center pt-15">
                <button className="btn btn-light me-3" onClick={handleClose}>{t('Cancelar')}</button>
                <button className="btn btn-primary">
                  <span className="indicator-label">{t('Actualizar')}</span>
                  <span className="indicator-progress">{t('Porfavor espere...')}
                    <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                </button>
              </div>
              {/*end::Actions*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Modal body*/}
        </Modal.Body>
      </Modal>
    </>
  );
}
