import AxiosControl from '../hooks/AxiosControl'
const Axios = AxiosControl()

export const getTasks = () => async (dispatch) => {
	try {
		const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/task', {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'TASK_GET_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}

export const getTasksByUser = (user) => async (dispatch) => {
	try {
		const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/task/userTasks', {
			user: user
		}, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'TASK_GET_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}

export const getTasksNotEnd = () => async (dispatch) => {
	try {
		const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/tasknotend', {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'TASK_GET_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}

export const getTask = (task) => async (dispatch) => {
	try {
		const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/task/' + task, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'TASK_GET_DETAIL_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}

export const addTask = (data) => async (dispatch) => {
	try {
		const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/task', data, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'TASK_SEND_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}

export const editTask = (data, task) => async (dispatch) => {
	try {
		const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/task/' + task, data, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'TASK_EDITED_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}

export const enableTask = (task) => async (dispatch) => {
	try {
		const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/task/enable/' + task, {}, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'TASK_EDITED_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}

export const disableTask = (task) => async (dispatch) => {
	try {
		const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/task/disable/' + task, {}, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'TASK_EDITED_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}

export const updateTask = (data, task) => async (dispatch) => {
	try {
		const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/task/' + task + '/status', {
			status: data.status
		}, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'TASK_EDITED_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}