export const absenceReducer = (state = {absences: null, absence: null}, action)=>{
    switch (action.type) {
        case 'ABSENCE_GET_SUCCESS':
            return {
                ...state,
                absences: action.payload
            }
        case 'ABSENCE_GET_DETAIL_SUCCESS':
            return {
                ...state,
                absence: action.payload
            }
        case 'ABSENCE_SEND_SUCCESS':
            return {
                ...state,
                absences: [
                    ...state?.absences,
                    action.payload
                ]
            }
        case 'ABSENCE_EDITED_SUCCESS':
            return {
                ...state, absence: action.payload, absences: state.absences?.map(
                    absence => absence.id === action.payload.id
                    ?
                        action.payload
                    :
                        absence
                )
            }
        default:
            return state;
    }
}