import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { addTask } from '../../actions/taskActions'
import { SuccessPopup } from '../../components/popups/Popups'
import { useTranslation } from 'react-i18next'
import { getDepartments } from '../../actions/departmentActions'
import Select from 'react-select'

export default function CreateTask() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {register, handleSubmit, watch, clearErrors, setValue, formState:{errors}} = useForm()
  const [errMsg, setErrMsg] = useState('')
  const [departmentOptions, setDepartmentOptions] = useState({options: null, selected: null})

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = (e) => {
    e.preventDefault()
    clearErrors()
    setErrMsg('')
    setShow(false)
  }

  const {departments} = useSelector(state => state.departments)
  const today = new Date().toISOString().split('T')[0]

  useEffect(()=>{
    setValue('startDate', today)
    dispatch(getDepartments())
  }, [setValue, today])

  useEffect(() => {
    if (departments) {
      let departmentsAvailable = []

        departments.forEach(deparment => {
            const deparmentOption = {label: `${deparment.name}`, value: deparment.id}
            departmentsAvailable.push(deparmentOption)
        });
        setDepartmentOptions({...departmentOptions, options: departmentsAvailable})
    }
  }, [departments])

  const selectDepartment = (target) => {
    let selectedDepartmentOption = []
    target.forEach(option => {
      departments.forEach(deparment => {
        const deparmentOption = {label: `${deparment.name}`, value: deparment.id}
  
          if(deparment.id === option.value){
            selectedDepartmentOption.push(deparmentOption)
          }
      })
    })
    setDepartmentOptions({...departmentOptions, selected: selectedDepartmentOption})
  }

  const data = {
    name: watch('name'),
    description: watch('description'),
    startDate: watch('startDate'),
    endDate: watch('endDate'),
    departments: []
  }

  const CreateTask = async() => {
    departmentOptions?.selected?.forEach(deparment => {
      data.departments.push(deparment.value)
    })
    dispatch(addTask(data))
    setErrMsg('')
    setShow(false)
    SuccessPopup(`Tarea '${data.name}' creada correctamente!`)
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
        <span className="svg-icon svg-icon-2 me-3">
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
            <rect opacity="0.5" x="11.364" y="20.364" width={16} height={2} rx={1} transform="rotate(-90 11.364 20.364)" fill="black" />
            <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
          </svg>
        </span>
        {/*end::Svg Icon*/}
        {t('Crear Tarea')}
      </Button>

      <Modal show={show} size='lg' backdrop='static' >
        <Modal.Header closeButton onClick={handleClose} >
          <Modal.Title className='fw-bolder fs-2'>{t('Crear Tarea')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*begin::Modal body*/}
          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
            {/*begin::Form*/}
            <form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(CreateTask)}>
              {/*begin::Scroll*/}
              <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Nombre')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="text" name="name" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Nombre de la tarea" {...register(
                    'name', {
                      required: 'Este campo es obligatorio'
                      }
                  )}  />
                  {/*end::Input*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="name" data-validator="notEmpty">{errors.name?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="fw-bold fs-6 mb-2">{t('Descripción')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="text" name="description" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Descripcion de la tarea" {...register('description')} />
                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Fecha de Inicio')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="date" name="startDate" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Fecha de inicio" min={today} {...register(
                    'startDate', {
                      required: 'Este campo es obligatorio'
                      }
                  )}  />
                  {/*end::Input*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="startDate" data-validator="notEmpty">{errors.startDate?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="fw-bold fs-6 mb-2">{t('Fecha de Fin')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="date" name="endDate" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Fecha de fin" min={today} {...register('endDate')}  />
                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Departamento')}</label>
                  {/*end::Label*/}
                  {/*begin::Select*/}
                  <Select isMulti onChange={selectDepartment}
                      id='department'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      options={departmentOptions.options}
                      value={departmentOptions.selected}
                  />
                  {/*end::Select*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="department" data-validator="notEmpty">{errors.department?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/* <!--begin::Api error message--> */}
                <div className="fv-plugins-message-container invalid-feedback">
                  <div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
                </div>
                {/* <!--end::Api error message--> */}
              </div>
              {/*end::Scroll*/}
              {/*begin::Actions*/}
              <div className="text-center pt-15">
                <button className="btn btn-light me-3" onClick={handleClose}>{t('Cancelar')}</button>
                <button className="btn btn-primary">
                  <span className="indicator-label">{t('Crear')}</span>
                  <span className="indicator-progress">{t('Please wait...')}
                    <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                </button>
              </div>
              {/*end::Actions*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Modal body*/}
        </Modal.Body>
      </Modal>
    </>
  );
}
