import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { addNotification, addNotificationDepartment } from '../../actions/notificationActions'
import { SuccessPopup } from '../../components/popups/Popups'
import useAuth from '../../hooks/UseAuth'
import { useTranslation } from 'react-i18next'
import { getUser, getUsers } from '../../actions/userActions'
import { getDepartments } from '../../actions/departmentActions'

export default function CreateNotification(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {auth} = useAuth()

  const {register, handleSubmit, watch, clearErrors, formState:{errors}} = useForm()
  const [errMsg, setErrMsg] = useState('')

  const [sendToDepartment, setSendToDepartment] = useState(false)
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = (e) => {
    e.preventDefault()
    clearErrors()
    setErrMsg('')
    setShow(false)
  }

  const {users, user} = useSelector(state => state.users)
  const {departments} = useSelector(state => state.departments)

  useEffect(() => {
    if(auth){
      dispatch(getUsers())
      dispatch(getUser(auth?.user, true))
      dispatch(getDepartments())
    }
  }, [auth])

  const CreateNotification = async() => {
    const data = {
      sendUser: user?.id,
      title: watch('title'),
      description: watch('description'),
      receiverUser: watch('receiverUser'),
      receiverDepartment: watch('receiverDepartment'),
    }

    if(sendToDepartment){
      data?.map((notification) =>
        dispatch(addNotificationDepartment(notification))
      )
      setErrMsg('')
      setShow(false)
      SuccessPopup(`Notificacion enviada al departamento correctamente!`)
    } else{
      setErrMsg('')
      setShow(false)
      dispatch(addNotification(data))
      SuccessPopup(`Notificacion enviada correctamente!`)
    }
  }

  function handleSendToDepartment() {
    setSendToDepartment(!sendToDepartment)
  }

  return (
    <>
      {
        props.normalButton ?
        <Button variant="primary" onClick={handleShow} className={'btn btn-primary text-uppercase w-100 mb-10'}>
          {t('Nueva notificación')}
        </Button>
        :
        <Button variant="primary" onClick={handleShow} className={'btn btn-primary py-2 px-3 ms-20 fs-6'}>
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
          <span className="svg-icon svg-icon-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z" fill="currentColor"/>
              <path opacity="0.3" d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z" fill="currentColor"/>
            </svg>
          </span>
          {/*end::Svg Icon*/}
          {t('Nueva')}
        </Button>
      }
      

      <Modal show={show} size='lg' backdrop='static' >
        <Modal.Header closeButton onClick={handleClose} >
          <Modal.Title className='fw-bolder fs-2'>{t('Nueva Notificación')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*begin::Modal body*/}
          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
            {/*begin::Form*/}
            <form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(CreateNotification)}>
              {/*begin::Scroll*/}
              <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Título')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="text" name="name" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Titulo de la notificacion" {...register(
                    'title', {
                      required: 'Este campo es obligatorio'
                      }
                  )}  />
                  {/*end::Input*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="name" data-validator="notEmpty">{errors.title?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-8">
                  {/*begin::Label*/}
                  <label className="fw-bold fs-6 mb-2">{t('Cuerpo del mensaje')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <textarea type="text" name="description" rows={5} className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Descripcion de la tarea" {...register('description')} />
                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-8">
                  {/*begin::Input*/}
                  <div className="d-flex">
                    <label className="fw-bold fs-6 me-5">{t('Enviar a un departamento')}</label>
                    {/*begin::Switch*/}
                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                      {/*begin::Input*/}
                      <input className="form-check-input" name="activeAccount" type="checkbox" defaultChecked={sendToDepartment} onClick={handleSendToDepartment} />
                      {/*end::Input*/}
                      {/*begin::Label*/}
                      <span className="form-check-label fw-bold text-muted" htmlFor="kt_modal_connected_accounts_google" />
                      {/*end::Label*/}
                    </label>
                    {/*end::Switch*/}
                  </div>
                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}
                {
                  sendToDepartment
                  ?
                  <div className="fv-row mb-7">
                    {/*begin::Label*/}
                    <label className="required fw-bold fs-6 mb-2">{t('Departamento destinatario')}</label>
                    {/*end::Label*/}
                    {/*begin::Select*/}
                    <select name="department" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Departamento destinatario" {...register(
                      'receiverDepartment', {
                        required: 'Este campo es obligatorio'
                        }
                    )} >
                      {
                        departments?.length
                          ? (
                            departments.map((department, i) =>
                              <option key={i} value={department.id}>{department.name}</option>
                            )
                          ) : ''
                      }
                    </select>
                    {/*end::Select*/}
                    {/* <!--begin::Input error message--> */}
                    <div className="fv-plugins-message-container invalid-feedback">
                      <div data-field="department" data-validator="notEmpty">{errors.receiverDepartment?.message}</div>
                    </div>
                    {/* <!--end::Input error message--> */}
                  </div>
                  :
                  <div className="fv-row mb-7">
                    {/*begin::Label*/}
                    <label className="required fw-bold fs-6 mb-2">{t('Usuario destinatario')}</label>
                    {/*end::Label*/}
                    {/*begin::Select*/}
                    <select name="receiverUser" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Usuario destinatario" {...register(
                      'receiverUser', {
                        required: 'Este campo es obligatorio'
                        }
                    )} >
                      {
                        users?.length
                          ? (
                            users.filter(u => u.id !== auth?.user).map((user, i) =>
                              <option key={i} value={user.id}>{user.username}</option>
                            )
                          ) : ''
                      }
                    </select>
                    {/*end::Select*/}
                    {/* <!--begin::Input error message--> */}
                    <div className="fv-plugins-message-container invalid-feedback">
                      <div data-field="receiver" data-validator="notEmpty">{errors.receiverUser?.message}</div>
                    </div>
                    {/* <!--end::Input error message--> */}
                  </div>
                }
                {/* <!--begin::Api error message--> */}
                <div className="fv-plugins-message-container invalid-feedback">
                  <div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
                </div>
                {/* <!--end::Api error message--> */}
              </div>
              {/*end::Scroll*/}
              {/*begin::Actions*/}
              <div className="text-center pt-15">
                <button className="btn btn-light me-3" onClick={handleClose}>{t('Cancelar')}</button>
                <button className="btn btn-primary">
                  <span className="indicator-label">{t('Enviar')}</span>
                  <span className="indicator-progress">{t('Please wait...')}
                    <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                </button>
              </div>
              {/*end::Actions*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Modal body*/}
        </Modal.Body>
      </Modal>
    </>
  );
}
