import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { disableUser, enableUser, getUser } from '../../actions/userActions'
import DepartmentRemoveUser from '../modals/DepartmentRemoveUser'
import EditUser from '../modals/EditUser'
import EditUserPassword from '../modals/EditUserPassword'
import EditUserRole from '../modals/EditUserRole'
import UserAssignSchedule from '../modals/UserAssignSchedule'
import { useTranslation } from 'react-i18next'
import UploadPayroll from '../modals/UploadPayroll'
import DeletePayroll from '../modals/DeletePayroll'
import { getLogs } from '../../actions/logActions'
import useAuth from '../../hooks/UseAuth'
import { getPayrolls } from '../../actions/payrollActions'
import { CustomDataTable } from '../../components/datatables/CustomDataTable'
import { Button, Modal } from 'react-bootstrap'

export default function User() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {auth} = useAuth()
	const {id} = useParams()
	const {user} = useSelector(state => state.users)
	const {payrolls} = useSelector(state => state.payrolls)
	const {logs} = useSelector(state => state.logs)

	const [week, setWeek] = useState([])
	const [show, setShow] = useState(false)

	const handleShow = () => {
		setShow(true)
	}

	const handleClose = (e) => {
		e?.preventDefault()
		setShow(false)
	}

  useEffect(()=>{
	  if(auth?.user){
		dispatch(getUser(id))
		dispatch(getPayrolls(id))
	  }
  }, [auth, id, dispatch])

  useEffect(()=>{
	let array = []
	const weekdays = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"]
	dispatch(getLogs())

		for (var i = 0; i <= 7; i++) {
			var currentDate = new Date();
			currentDate.setDate(currentDate.getDate() + i)
			array.push({
				day: weekdays[currentDate.getDay()],
				date: currentDate.getDate(),
				month: currentDate.getMonth()
			})
		}

		setWeek(array)
	}, [dispatch])

	function handleActiveAccount(e) {
		if(user.isActive === true){
			dispatch(disableUser(user.id))
		} else if(user.isActive === false){
			dispatch(enableUser(user.id))
		}
	}

	const columnsOne = [
		{
			name: t('Departamento'),
			cell: department =>
				<div className="d-flex align-items-center border-0">
					<div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
						<Link to={`/user/${user?.id}`}>
						<div className="symbol-label fs-3 bg-light-danger text-danger">{department.name?.charAt(0)}</div>
						</Link>
					</div>
					<div className="d-flex flex-column">
						<Link to={`/department/${department.id}`} className="text-gray-800 text-hover-primary mb-1">
						{department.name}
						</Link>
					</div>
				</div>,
			selector: (department) => department.name,
			sortable: true,
		},{
			name: t('Description'),
			cell: department =>
				<div className="d-flex align-items-center border-0">
					{department.description }
				</div>,
			selector: (department) => department.description,
			sortable: true,
		},{
			name: t('Dirección'),
			cell: department =>
				<div className="d-flex align-items-center border-0">
					{department.address }
				</div>,
			selector: (department) => department.address,
			sortable: true,
		},{
			name: t('Estado'),
			cell: department =>
				<div className={department.isActive ? '' : 'bg-light'}>
					{department.isActive ? t('Activo') : t('Desactivado')}
				</div>,
			selector: (department) => department.isActive,
			sortable: true,
		},{
			name: 'Estado',
			cell: department =>
				<div>
					<DepartmentRemoveUser userId={user.id} departmentId={department.id} />
				</div>,
			selector: (department) => department.isActive,
			sortable: true,
		}
	]

	const columnsTwo = [
		{
			name: t('Nómina'),
			cell: payroll =>
				<div className="d-flex align-items-center border-0">
					<div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
						<span className="svg-icon svg-icon-2x svg-icon-primary me-4">
						<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
							<path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z" fill="black" />
							<path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
						</svg>
						</span>
					</div>
					<div className="d-flex flex-column">
						<Link to={'#'} className="text-gray-800 text-hover-primary mb-1">
							{payroll.title}
						</Link>
					</div>
				</div>,
			selector: (department) => department.name,
			sortable: true,
		},{
			name: t('Mes/Año'),
			cell: payroll =>
				<div>
					{payroll.monthyear}
				</div>,
			selector: (payroll) => payroll.monthyear,
			sortable: true,
		},{
			name: '',
			cell: payroll =>
				<div className='row'>
					<div className='col-6'>
						<a href={payroll.route} download className="btn btn-light-primary menu-link px-3 text-success">
							<span className="svg-icon svg-icon-muted svg-icon-2x m-0">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor"/>
									<path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor"/>
								</svg>
							</span>
						</a>
					</div>
					<div className='col-6'>
						<DeletePayroll payroll={payroll} />
					</div>
				</div>,
			selector: (payroll) => payroll.date,
			sortable: true,
		}
	]

	const columnsThree = [
		{
			name: t('Acción'),
			cell: log =>
				<div>
					{log.action}
				</div>,
			selector: (log) => log.action,
			sortable: true,
		},{
			name: t('Fecha'),
			cell: log =>
				<div>
					{log.day}
				</div>,
			selector: (log) => log.day,
			sortable: true,
		},{
			name: t('Hora'),
			cell: log =>
				<div>
					{log.hour}
				</div>,
			selector: (log) => log.hour,
			sortable: true,
		}
	]

	return (
		<>
			<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
				<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
					<div className="page-title d-flex flex-column me-3">
						<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Usuario')}</h1>
						<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/user/list' className="text-white text-hover-primary">{t('Listar Usuarios')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white">{t('Usuario')}</li>
						</ul>
					</div>
				</div>
			</div>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					<div className="d-flex flex-column flex-lg-row">
						<div className="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
							<div className="card mb-5 mb-xl-8">
								<div className="card-body">
									<div className="d-flex flex-center flex-column py-5">
										<div className="symbol symbol-100px symbol-circle mb-7">
											<img src={user?.imageProfile} alt='avatar' />
										</div>
										<Link to={'#'} className="fs-3 text-gray-800 text-hover-primary fw-bolder mb-3">{user?.username}</Link>
										<div className="mb-9">
											{user?.roles
												?
													user?.roles.map((role, y) => 
														role==='ROLE_ADMIN' 
															?
																<div className="badge badge-lg badge-light-danger d-inline m-1" key={y}>{role}</div>
															:
																<div className="badge badge-lg badge-light-success d-inline m-1" key={y}>{role}</div>
													)
												:
													<></>
											}
										</div>
										{user?.isActive 
											?
												<></>
											:
												<div className="notice d-flex bg-light-danger rounded border-danger border border-dashed mb-9 p-6">
													<span className="svg-icon svg-icon-2tx svg-icon-danger me-4">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
															<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
															<rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"/>
															<rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
														</svg>
													</span>
													<div className="d-flex flex-stack flex-grow-1">
														<div className="fw-bold">
															<div className="fs-4 text-gray-900">{t('Usuario desactivado')}.</div>
															<div className="fs-6 text-gray-700">{t('Este usuario no tiene acceso a la aplicación')}.</div>
														</div>
													</div>
												</div>  
											}
										</div>
										<div className="d-flex flex-stack fs-4 py-3">
										<div className="fw-bolder rotate collapsible" data-bs-toggle="collapse" href="#kt_user_view_details" role="button" aria-expanded="false" aria-controls="kt_user_view_details">{t('Detalles')}
											<span className="ms-2 rotate-180">
											<span className="svg-icon svg-icon-3">
												<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
												<path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
												</svg>
											</span>
											</span></div>
										<span data-bs-toggle="tooltip" data-bs-trigger="hover" title="Edit customer details">
											<Button variant="primary" onClick={handleShow} className="btn btn-sm btn-light-primary">
												{t('Editar')}
											</Button>
											<Modal show={show} size='lg' backdrop='static' >
												<Modal.Header closeButton onClick={handleClose} >
												<Modal.Title className='fw-bolder fs-2'>{t('Editar Usuario')}</Modal.Title>
												</Modal.Header>
												<Modal.Body>
													<EditUser admin={true} user={user} handleClose={handleClose} />
												</Modal.Body>
											</Modal>
										</span>
										</div>
										<div className="separator" />
										<div id="kt_user_view_details" className="collapse show">
											<div className="pb-5 fs-6">
												<div className="fw-bolder mt-5">{t('ID del usuario')}</div>
												<div className="text-gray-600">{user?.id}</div>
												{user?.firstName !== null
													?
														<>
															<div className="fw-bolder mt-5">{t('Nombre y apellidos')}</div>
															<div className="text-gray-600">{user?.firstName} {user?.lastName}</div>
														</>
													:
														<></>
												}
												{user?.dni !== null
													?
														<>
															<div className="fw-bolder mt-5">{t('DNI')}</div>
															<div className="text-gray-600">{user?.dni}</div>
														</>
													:
														<></>
												}
												{user?.phone !== null
													?
														<>
															<div className="fw-bolder mt-5">{t('Telefono')}</div>
															<div className="text-gray-600">{user?.phone}</div>
														</>
													:
														<></>
												}
												{user?.email !== null
													?
														<>
															<div className="fw-bolder mt-5">{t('Email')}</div>
															<div className="text-gray-600">{user?.email}</div>
														</>
													:
														<></>
												}
												{user?.employ !== null
													?
														<>
															<div className="fw-bolder mt-5">{t('Empleo')}</div>
															<div className="text-gray-600">{user?.employ}</div>
														</>
													:
														<></>
												}
												{user?.address !== null
													?
														<>
															<div className="fw-bolder mt-5">{t('Dirección')}</div>
															<div className="text-gray-600">{user?.address}</div>
														</>
													:
														<></>
												}
												{user?.gender !== null
													?
														<>
															<div className="fw-bolder mt-5">{t('Genero')}</div>
															<div className="text-gray-600">{user?.gender}</div>
														</>
													:
														<></>
												}
												{user?.birthDateFormatted !== null
													?
														<>
															<div className="fw-bolder mt-5">{t('Fecha de nacimiento')}</div>
															<div className="text-gray-600">{user?.birthDateFormatted}</div>
														</>
													:
														<></>
												}
												{user?.creationDate !== null
													?
														<>
															<div className="fw-bolder mt-5">{t('Fecha de alta')}</div>
															<div className="text-gray-600">{user?.creationDate}</div>
														</>
													:
														<></>
												}
											</div>
										</div>
								</div>
							</div>
							<div className="card mb-5 mb-xl-8">
								<div className="card-header border-0">
									<div className="card-title">
										<h3 className="fw-bolder m-0">{t('Características')}</h3>
									</div>
								</div>
								<div className="card-body pt-2">
									<div className="py-2">
									<div className="d-flex flex-stack">
										<div className="d-flex">
										<span className="svg-icon svg-icon-muted svg-icon-2hx me-6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
											<path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
											</svg></span>
										<div className="d-flex flex-column">
											<div className="fs-5 text-dark fw-bolder">{t('Cuenta activa')}</div>
											<div className="fs-6 fw-bold text-muted">{t('Activa o desactiva el acceso')}</div>
										</div>
										</div>
										<div className="d-flex justify-content-end">
										<label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
											<input className="form-check-input" name="activeAccount" type="checkbox" defaultChecked={user?.isActive ? true : false} onClick={handleActiveAccount} />
											<span className="form-check-label fw-bold text-muted" htmlFor="kt_modal_connected_accounts_google" />
										</label>
										</div>
									</div>
									</div>
								</div>
							</div>
						</div>
						<div className="flex-lg-row-fluid ms-lg-15">
							<ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
								<li className="nav-item">
									<a className="nav-link text-active-primary pb-4 active text-dark" data-bs-toggle="tab" href="#kt_user_view_overview_tab">{t('Horario')}</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-active-primary pb-4 text-dark" data-kt-countup-tabs="true" data-bs-toggle="tab" href="#kt_user_view_department">{t('Departamento')}</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-active-primary pb-4 text-dark" data-kt-countup-tabs="true" data-bs-toggle="tab" href="#kt_user_view_overview_payrolls">{t('Nominas')}</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-active-primary pb-4 text-dark" data-kt-countup-tabs="true" data-bs-toggle="tab" href="#kt_user_view_overview_security">{t('Seguridad')}</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-active-primary pb-4 text-dark" data-bs-toggle="tab" href="#kt_user_view_overview_events_and_logs_tab">{t('Registros')}</a>
								</li>
							</ul>
							<div className="tab-content" id="myTabContent">
							<div className="tab-pane fade show active" id="kt_user_view_overview_tab" role="tabpanel">
								<div className="card card-flush mb-6 mb-xl-9">
									<div className="card-header mt-6">
										<div className="card-title flex-column">
											<h2 className="mb-1">{t('Horario')}</h2>
										</div>
										<div className="card-toolbar">
											<UserAssignSchedule />
										</div>
									</div>
									<div className="card-body p-9 pt-4">
										<ul className="nav nav-pills d-flex flex-nowrap hover-scroll-x py-2">
											{week?.length
												?
													week.map((day, i) => 
														<li key={i} className="nav-item me-1">
															<a  className={`nav-link btn d-flex flex-column flex-center rounded-pill min-w-40px me-2 py-4 btn-active-primary ${i === 0 ? 'active' : ''}`} data-bs-toggle="tab" href={`#kt_schedule_day_${i}`}>
																<span className="opacity-50 fs-7 fw-bold">{t(day.day)}</span>
																<span className="fs-6 fw-boldest">{day.date}</span>
															</a>
														</li>
													)
												:
													<></>
											}
										</ul>
										<div className="tab-content">
										{
											week?.length
											? (
											week.map((week, i) => 
												<div key={i} id={`kt_schedule_day_${i}`} className={`tab-pane fade show ${i === 0 ? 'active' : ''}`}>
												{
													user?.schedules?.length
													? (
													user.schedules.map((schedule, j) => 
														schedule.day === week.day 
														? <div key={j} className="d-flex flex-stack position-relative mt-6">
															<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
															<div className="fw-bold ms-5">
															<div className="fs-7 mb-1">{schedule.checkInTime} - {schedule.checkOutTime}
																<span className="fs-7 text-muted text-uppercase"> pm</span></div>
															<Link to={`/schedule/${schedule.id}`} className="fs-5 fw-bolder text-dark text-hover-primary mb-2">{schedule.name}</Link>
															<div className="fs-7 text-muted">{t('Asignado por')}
																<Link to={'#'} > Administrador</Link></div>
															</div>
															<Link to={`/schedule/${schedule.id}`} className="btn btn-light bnt-active-light-primary btn-sm">Ver</Link>
														</div>
														: ''
													)
													) : ''
												}
												</div>
											)
											)
											: ''
										}
										</div>
									</div>
								</div>
								<div className="card card-flush mb-6 mb-xl-9">
								<div className="card-header mt-6">
									<div className="card-title flex-column">
									<h2 className="mb-1">{t('Tareas')}</h2>
									</div>
								</div>
								<div className="card-body d-flex flex-column">
									{
									user?.tasks?.map((task, i)=>
										<div key={i} className="d-flex align-items-center position-relative mb-7">
										<div className="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px" />
										<div className="fw-bold ms-5">
											<div className="fs-7 mb-1">{task.startDate.formatedDate} - {task.endDate.formatedDate}</div>
											<Link to={`/task/${task.id}`} className="fs-5 fw-bolder text-dark text-hover-primary">{task.name}</Link>
											<div className="fs-7 text-muted">
												{t('Departamentos')}:
												{task.departments.map((department, j) =>
													<Link key={j} className='mx-3' to={`/department/${department.id}`}>{department.name}</Link>
												)}
											</div>
										</div>
										<button type="button" className="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
											<span className="svg-icon svg-icon-3">
											<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
												<path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="black" />
												<path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="black" />
											</svg>
											</span>
										</button>
										</div>
									)
									}
								</div>
								</div>
							</div>
							<div className="tab-pane fade" id="kt_user_view_department" role="tabpanel">
								<div className="card pt-4 mb-6 mb-xl-9">
								<div className="card-header border-0">
									<div className="card-title flex-column">
									<h2 className="mb-1">{t('Departamento')}</h2>
									<div className="fs-6 fw-bold text-muted">{t('Departamentos a los que pertenece el usuario')}</div>
									</div>
								</div>
								<div className="card-body pt-0 pb-5">
									<div className="table-responsive">
										{user?.departments?.length > 0
											?
												<CustomDataTable columns={columnsOne} data={user?.departments} />
											:
												<></>
										}
									</div>
								</div>
								</div>
							</div>
							<div className="tab-pane fade" id="kt_user_view_overview_security" role="tabpanel">
								<div className="card pt-4 mb-6 mb-xl-9">
								<div className="card-header border-0">
									<div className="card-title flex-column">
									<h2 className="mb-1">{t('Campos protegidos')}</h2>
									<div className="fs-6 fw-bold text-muted">{t('Estos campos solo pueden ser modificados por un administrador')}.</div>
									</div>
								</div>
								<div className="card-body pt-0 pb-5">
									<div className="table-responsive">
									<table className="table align-middle table-row-dashed gy-5" id="kt_table_users_login_session">
										<tbody className="fs-6 fw-bold text-gray-600">
										<tr>
											<td>{t('Contraseña')}</td>
											<td>******</td>
											<td className="text-end">
											<EditUserPassword id={id} />
											</td>
										</tr>
										<tr>
											<td>{t('Rol')}</td>
											<td>{t(user?.roles?.includes('ROLE_ADMIN') ? 'Administrador' : 'Usuario')}</td>
											<td className="text-end">
											<EditUserRole id={id} />
											</td>
										</tr>
										</tbody>
									</table>
									</div>
								</div>
								</div>
							</div>
							<div className="tab-pane fade" id="kt_user_view_overview_payrolls" role="tabpanel">
								<div className="card pt-4 mb-6 mb-xl-9">
								<div className="card-header mt-6">
									<div className="card-title flex-column">
									<h2 className="mb-1">{t('Nóminas')}</h2>
									<div className="fs-6 fw-bold text-muted">{t('Nóminas del usuario')}</div>
									</div>
									<div className="card-toolbar">
										<UploadPayroll button />
									</div>
								</div>
								<div className="card-body pt-0 pb-5">
									<div className="table-responsive">
										{payrolls?.length > 0
											?
												<CustomDataTable columns={columnsTwo} data={payrolls} />
											:
												<></>
										}
									</div>
								</div>
								</div>
							</div>
							<div className="tab-pane fade" id="kt_user_view_overview_events_and_logs_tab" role="tabpanel">
								<div className="card pt-4 mb-6 mb-xl-9">
								<div className="card-header border-0">
									<div className="card-title">
									<h2>{t('Acciones en la aplicación')}</h2>
									</div>
									<div className="card-toolbar">
									</div>
								</div>
								<div className="card-body pt-0 pb-5">
									<div className="table-responsive">
										{Array.isArray(logs) && logs.filter(l => l.user?.id === parseInt(id))?.length > 0
											? <CustomDataTable columns={columnsThree} data={logs.filter(l => l.user?.id === parseInt(id))} />
											: <></>
										}
									</div>
								</div>
								</div>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
