import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/UseAuth'
import { getVacationsManaged } from '../../actions/vacationActions';
import FullCalendar from '@fullcalendar/react' 
import dayGridPlugin from '@fullcalendar/daygrid' 
import interactionPlugin from "@fullcalendar/interaction";

export default function InfoVacations() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {auth} = useAuth()

	const {vacations} = useSelector(state => state.vacations)

	const [event, setEvent] = useState([])

	useEffect(() => {
		if(auth){
			dispatch(getVacationsManaged(auth?.user))
		}
	}, [auth, dispatch])

	useEffect(() => {
		let aVacations = []
		// Recorrer todas las vacaciones
		vacations?.map((vacation)=>{
			const vDay = new Date(vacation.date)
			const vDayStamp = `${vDay.getFullYear()}-${(parseInt(vDay.getMonth())+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}-${vDay.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}`
			
			// Añadirlas al array
			let eventJSON = {
				id: vacation.id,
				title: vacation.user.username, 
				start: vDayStamp,
				end: vDayStamp
			}
			if (vacation.accepted) {
				eventJSON.color = 'green'
			}
			aVacations.push(eventJSON)
		})
		setEvent(aVacations)
	}, [vacations])

	return (
		<>
			<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
				<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
					<div className="page-title d-flex flex-column me-3">
						<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Información de Vacaciones')}</h1>
						<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white">{t('Información de Vacaciones')}</li>
						</ul>
					</div>
				</div>
			</div>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					<div className="card">
						<div className="card-header">
							<h2 className="card-title fw-bolder">{t('Vacaciones')}</h2>
						</div>
						<div className="card-body">
							<FullCalendar
								plugins={[ dayGridPlugin, interactionPlugin ]}
								initialView="dayGridMonth"
								events={event}
								displayEventTime={false}
								height="auto"
							/>
							<div className='row'>
								<div className='col-12 my-7'>
									<div className='row'>
										<div className='col-6 d-flex justify-content-center'>
											<span style={{float: 'left', width: '15px', height: '15px', border: '1px solid black', backgroundColor: 'green', marginTop: '2px', marginRight: '8px'}}/>
											<span>{t('Aceptados')}</span>
										</div>
										<div className='col-6 d-flex justify-content-center'>
											<span style={{float: 'left', width: '15px', height: '15px', border: '1px solid black', backgroundColor: '#3788d8', marginTop: '2px', marginRight: '8px'}}/>
											<span>{t('solicitados')}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
