import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import useAuth from '../../hooks/UseAuth';
import { getUsers, getUserSupervisor } from '../../actions/userActions';
import { getVacationsByRange } from '../../actions/vacationActions';
import VacationDays from './VacationDays';

export default function CheckVacations() {

    const {auth} = useAuth()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const calendarRef = useRef()

    const {users} = useSelector(state => state.users)
    const {vacations} = useSelector(state => state.vacations)

    const [selectedUser, setSelectedUser] = useState({options: [], selected: null})
    const [selectedStartDate, setSelectedStartDate] = useState(null)
    const [selectedEndDate, setSelectedEndDate] = useState(null)
    const [confirmDate, setConfirmDate] = useState(false)
    const [event, setEvent] = useState([])


    const handleUserChange = async (selectedValue) => {
        // Set the selected user state selected value
        setSelectedUser({...selectedUser, selected: selectedValue})
        setConfirmDate(false)
    }

    // When a date has been confirmed
    const handleConfirmDate = async () => {
        setEvent([])
        dispatch(getVacationsByRange({start: selectedStartDate, end: selectedEndDate},selectedUser?.selected?.value))
        setConfirmDate(true)
        if (calendarRef.current) {
            calendarRef.current.getApi().gotoDate(new Date(selectedStartDate))
        }
    }

    
    useEffect(() => {
        if (vacations) {
            let aVacations = []
            // Recorrer todas las vacaciones
            if(vacations?.length > 0){
                vacations?.map((vacation)=>{
                    const vDay = new Date(vacation.date)
                    const vDayStamp = `${vDay.getFullYear()}-${(parseInt(vDay.getMonth())+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}-${vDay.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}`

                    // Añadirlas al array
                    let eventJSON = {
                        id: vacation.id,
                        title: vacation.user.username,
                        start: vDayStamp,
                        end: vDayStamp
                    }
                    if (vacation.accepted) {
                        eventJSON.color = 'green'
                    }
                    aVacations.push(eventJSON)
                })
            }
            setEvent(aVacations)

        }
    }, [vacations])

    useEffect(() => {
        if (auth) {
            if (auth.roles.indexOf('ROLE_ADMIN') !== -1) {
                dispatch(getUsers())
            } else {
                dispatch(getUserSupervisor(auth.user))
            }
        }
    }, [auth, dispatch])

    useEffect(() => {
        if (users) {
            let usersAvailable = []
            let usersFound = users

            if (!auth?.roles?.includes('ROLE_ADMIN')) {
                const currentUser = users?.find(u => u.username === auth?.username)
                usersFound = users.filter(u => u.supervisor === currentUser?.id)
            }
            usersFound.forEach(user => {
                const userOption = {label: `${user.lastName}, ${user.firstName}`, value: user.id}
                usersAvailable.push(userOption)
            });
            setSelectedUser({options: usersAvailable, selected: null})
        }
    }, [users, auth])

    return (
        <div>
        {/*begin::Toolbar*/}
        <div className="toolbar py-5 py-lg-15" id="kt_toolbar">
        {/*begin::Container*/}
        <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
            {/*begin::Page title*/}
            <div className="page-title d-flex flex-column me-3">
            {/*begin::Title*/}
            <h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Revisión de vacaciones')}</h1>
            {/*end::Title*/}
            {/*begin::Breadcrumb*/}
            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                {/*begin::Item*/}
                <li className="breadcrumb-item text-white opacity-75">
                <Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item">
                <span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item text-white opacity-75">
                <Link to='/admin' className="text-white text-hover-primary">{t('Admin')}</Link>
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item">
                <span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item text-white">{t('Revisar vacaciones')}</li>
                {/*end::Item*/}
            </ul>
            {/*end::Breadcrumb*/}
            </div>
            {/*end::Page title*/}
        </div>
        {/*end::Container*/}
        </div>
        {/*end::Toolbar*/}
        {/*begin::Container*/}
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        {/*begin::Steps*/}
        <div className="content flex-row-fluid" id="kt_content">
            <div className="row g-6 g-xl-9">
            {/*begin::User selection*/}
            <div className="col-12">
                {/*begin::User Selector*/}
                <div className="card h-100">
                <div className="card-body p-9">
                    <div className="fs-2x fw-bolder">{t('Selección de usuario')}</div>
                    <div className="fs-4 fw-bold text-gray-400 mb-7">{t('Seleccione usuario para continuar')}</div>
                    <Select name="user" placeholder={t('Seleccionar Usuario')} options={selectedUser.options} value={selectedUser.selected} onChange={handleUserChange}/>
                </div>
                </div>
                {/*end::Budget*/}
            </div>
            {/*end::User selector*/}
            {/*begin::Date Selection*/}
            {
                selectedUser.selected &&
                <div className="col-12">
                    <div className="card h-100">
                        <div className="card-body p-9">
                        <div className="fs-2x fw-bolder">{t('Selección de fechas')}</div>
                        <div className="fs-4 fw-bold text-gray-400 mb-7">{t('Seleccione un rango de fechas para continuar')}</div>
                            <div className='row'>
                                <div className='col-5'>
                                    <label htmlFor='start' className='form-label'>{t('Fecha de inicio')}</label>
                                    <input type="date" className="form-control form-control-solid mb-5" name="start" value={selectedStartDate || ''} onChange={(e) => {setSelectedStartDate(e.target.value); setConfirmDate(false)}}/>
                                </div>
                                <div className='col-2 d-flex align-items-center justify-content-center'>
                                    <i className='fa fa-arrow-right fa-lg'/>
                                </div>
                                <div className='col-5'>
                                    <label htmlFor='start' className='form-label'>{t('Fecha de fin')}</label>
                                    <input type="date" className="form-control form-control-solid mb-5" name="end" value={selectedEndDate || ''} onChange={(e) => {setSelectedEndDate(e.target.value); setConfirmDate(false)}}/>
                                </div>
                            </div>
                            <div className='d-grid gap-2'>
                            <button type='button' className='btn btn-info' onClick={handleConfirmDate}>{t('Seleccionar fecha')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {/*end::Date selection*/}
            {
                selectedUser?.selected && confirmDate &&
                    <div className='col-12'>
                        <div className="card">
                            <div className="card-header">
                                <h2 className="card-title fw-bolder">{t('Vacaciones')}</h2>
                            </div>
                            <div className="card-body">
                                <div className='text-center'>
                                    <VacationDays events={event} />
                                </div>
                                {/* <div className='row'>
                                    <div className='col-12 my-7'>
                                        <div className='row'>
                                            <div className='col-6 d-flex justify-content-center'>
                                                <span style={{float: 'left', width: '15px', height: '15px', border: '1px solid black', backgroundColor: 'green', marginTop: '2px', marginRight: '8px'}}/>
                                                <span>{t('Aceptados')}</span>
                                            </div>
                                            <div className='col-6 d-flex justify-content-center'>
                                                <span style={{float: 'left', width: '15px', height: '15px', border: '1px solid black', backgroundColor: '#3788d8', marginTop: '2px', marginRight: '8px'}}/>
                                                <span>{t('solicitados')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
            }
            </div>
        </div>
        {/*end::Steps*/}
        </div>
        {/*end::Container*/}
    </div>
    )
}