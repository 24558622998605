import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import EditSchedule from '../modals/EditSchedule'
import ScheduleAssignUser from '../modals/ScheduleAssignUser'
import ScheduleRemoveUser from '../modals/ScheduleRemoveUser'
import { useTranslation } from 'react-i18next'
import { CustomDataTable } from '../../components/datatables/CustomDataTable'
import { getSchedule } from '../../actions/scheduleActions'

export default function Schedule() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { id } = useParams()
	const {schedule} = useSelector(state => state.schedules)

	useEffect(() => {
		dispatch(getSchedule(id))
	}, [])

	const columns = [
		{
			name: t('Usuario'),
			cell: user =>
				<div className="d-flex align-items-center">
					{user.username}
				</div>,
			selector: (user) => user.username,
			sortable: true,
		},{
			name: t('Nombre y apellidos'),
			cell: user =>
				<div>
					{user.firstName 
						? 
							user.firstName + ' '
						:
							<></>
					}
					{user.lastName 
						?
							user.lastName
						:
							<></>
					}
				</div>,
			selector: (user) => user.firstName+' '+user.lastName,
			sortable: true,
		},{
			name: t('Empleo'),
			cell: user =>
				<div>
					{user.employ}
				</div>,
			selector: (user) => user.employ,
			sortable: true,
		},{
			name: t('Roles'),
			cell: user =>
				<div>
					{user?.roles
						?
							user.roles.map((role, y) => 
								role==='ROLE_ADMIN' 
									?
										<div className="badge badge-light-danger fw-bolder mb-1 me-1" key={y}>{role}</div>
									:
										<div className="badge badge-light-success fw-bolder mb-1 me-1" key={y}>{role}</div>
								)
						:
							<></>
					}
				</div>,
			selector: (user) => user.roles,
			sortable: true,
		},{
			name: t('Fecha de alta'),
			cell: user =>
				<div>
					{user.creationDate}
				</div>,
			selector: (user) => user.creationDate,
			sortable: true,
		},{
			name: t('Estado'),
			cell: user =>
				<div className={user.isActive ? '' : 'bg-light'}>
					{user.isActive ? t('Activo') : t('Desactivado')}
				</div>,
			selector: (user) => user.isActive,
			sortable: true,
		},{
			name: '',
			cell: user =>
				<div>
					<ScheduleRemoveUser schedule={schedule} userId={user.id} />
				</div>,
			sortable: true,
		}
	]

	return (
		<>
			<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
				<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
					<div className="page-title d-flex flex-column me-3">
						<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Horario')}</h1>
						<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/schedule/list' className="text-white text-hover-primary">{t('Listar Horarios')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white">{t('Horario')}</li>
						</ul>
					</div>
				</div>
			</div>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					<form id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row" data-kt-redirect="../../demo2/dist/apps/ecommerce/catalog/categories.html">
						<div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
							<div className="card card-flush py-4">
								<div className="card-header">
									<div className="card-title">
										<h2>{schedule?.name}</h2>
									</div>
								</div>
								<div className="card-body pt-0">
									<div className="d-flex flex-stack fs-4 py-3">
										<div className="fw-bolder rotate collapsible" data-bs-toggle="collapse" href="#kt_user_view_details" role="button" aria-expanded="false" aria-controls="kt_user_view_details">{t('Detalles')}
											<span className="ms-2 rotate-180">
												<span className="svg-icon svg-icon-3">
													<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
														<path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
													</svg>
												</span>
											</span>
										</div>
										<span data-bs-toggle="tooltip" data-bs-trigger="hover" title="Edit customer details">
											<EditSchedule schedule={schedule} />
										</span>
									</div>
									<div className="separator" />
									<div id="kt_user_view_details" className="collapse show">
										<div className="pb-5 fs-6">
											{schedule
												? 
													<>
														<div className="fw-bolder mt-5">{t('Día de la semana')}</div>
														<div className="text-gray-600">{t(schedule.day)}</div>
													</>
												: 
													<></>
											}
											{schedule?.description
												? 
													<>
														<div className="fw-bolder mt-5">{t('Descripción')}</div>
														<div className="text-gray-600">{schedule.description}</div>
													</>
												: 
													<></>
											}
											{schedule?.checkInTime
												?
													<>
														<div className="fw-bolder mt-5">{t('Hora de entrada')}</div>
														<div className="text-gray-600">{schedule.checkInTime}</div>
													</>
												:
													<></>
											}
											{schedule?.checkOutTime
												? 
													<>
														<div className="fw-bolder mt-5">{t('Hora de salida')}</div>
														<div className="text-gray-600">{schedule.checkOutTime}</div>
													</>
												: 
													<></>
											}
											{schedule?.startDate
												?
													<>
														<div className="fw-bolder mt-5">{t('Fecha de inicio')}</div>
														<div className="text-gray-600">{schedule.startDateFormated}</div>
													</>
												: 
													<></>
											}
											{schedule?.endDate
												?
													<>
														<div className="fw-bolder mt-5">{t('Fecha de fin')}</div>
														<div className="text-gray-600">{schedule.endDateFormated}</div>
													</>
												: 
													<></>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
							<div className="card card-flush py-4">
								<div className="card-header border-0 pt-6">
									<div className="card-title">
										<h2>{t('Usuarios')}</h2>
									</div>
									<div className="card-toolbar">
										<div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
											<ScheduleAssignUser schedule={schedule} />
										</div>
									</div>
								</div>
								<div className="card-body pt-0">
									<div className="mb-10 fv-row">
										<label className="form-label">{t('Usuarios que tienen asignado este horario')}<div className="separator my-5" /></label>
										<div className='table-responsive'>
											{schedule?.users?.length > 0
												?
													<CustomDataTable columns={columns} data={schedule?.users} />
												:
													<></>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}