export const businessReducer = (state = {name: '', logo: ''}, action)=>{
	switch (action.type) {
		case 'BUSINESS_GET_SUCCESS':
			return {
				...state,
				name: action.payload?.name,
				logo: action.payload?.logo
			}
		case 'BUSINESS_UPDATE_SUCCESS':
			return {
				...state,
				name: action.payload?.name,
				logo: action.payload?.logo
			}
		default:
			return state;
	}
}