export const scheduleReducer = (state = {schedules: null, schedule: null}, action)=>{
    switch (action.type) {
        case 'SCHEDULE_GET_SUCCESS':
            return {
                ...state,
                schedules: action.payload
            }
        case 'SCHEDULE_GET_DETAIL_SUCCESS':
            return {
                ...state,
                schedule: action.payload
            }
        case 'SCHEDULE_SEND_SUCCESS':
            return {
                ...state,
                schedules: [
                    ...state.schedules,
                    action.payload
                ]
            }
        case 'SCHEDULE_EDITED_SUCCESS':
            return {
                ...state,
                schedule: action.payload,
                schedules:
                    state.schedules?.map((schedule) =>{
                        if(schedule.id === action.payload.id){
                            return action.payload
                        }else{
                            return schedule
                        }
                    })
            }
        default:
            return state;
    }
}