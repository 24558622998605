import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { disableUser, editUser, enableUser, getUsers } from '../../actions/userActions'
import { ConfirmPupup } from '../../components/popups/Popups'
import CreateUser from '../modals/CreateUser'
import EditUser from '../modals/EditUser'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/UseAuth'
import { CustomDataTable } from '../../components/datatables/CustomDataTable'
import { Modal } from 'react-bootstrap'

export default function ListUsers() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {auth} = useAuth()

	const {users} = useSelector(state => state.users)

	const [selectedUser, setSelectedUser] = useState(null)
	const [showModal, setShowModal] = useState(false)

	useEffect(() => {
		if(auth){
			dispatch(getUsers())
		}
	}, [auth])

	const ConfirmEnableUser=(userId, userUsername)=>{
		ConfirmPupup(`Activar usuario ${userUsername}?`)
		.then((result) => {
			if (result.isConfirmed) {
				dispatch(enableUser(userId))
			}
		})
	}

	const ConfirmDisableUser=(userId, userUsername)=>{
		ConfirmPupup(`Desactivar usuario ${userUsername}?`)
		.then((result) => {
			if (result.isConfirmed) {
				dispatch(disableUser(userId))
			}
		})
	}

	const handleShow = (user) => {
		setSelectedUser(user)
		setShowModal(true)
	}

	const handleClose = (e) => {
		e.preventDefault()
		setShowModal(false)
	  }

	const columns = [
		{
			name: t('Usuario'),
			cell: user =>
				<div className="d-flex align-items-center border-0">
					<div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
						<Link to={`/user/${user?.id}`}>
							<div className="symbol-label fs-3 bg-light-danger text-danger">{user.username?.charAt(0)}</div>
						</Link>
					</div>
					<div className="d-flex flex-column">
						<Link to={`/user/${user?.id}`} className="text-gray-800 text-hover-primary mb-1">
							{user.username}
						</Link>
					</div>
				</div>,
			selector: (user) => user.name,
			sortable: true,
		},{
			name: t('Nombre y apellidos'),
			cell: user =>
				<div>
					{
						user.firstName 
						?
							user.firstName + ' '
						:
							<></>
					}
					{
						user.lastName 
						?
							user.lastName
						:
							<></>
					}
				</div>,
			selector: (user) => user.firstName+' '+user.lastName,
			sortable: true,
		},{
			name: t('Empleo'),
			cell: user =>
				<div>
					{user.employ}
				</div>,
			selector: (user) => user.name,
			sortable: true,
		},{
			name: t('Roles'),
			cell: user =>
				<div>
					{user?.roles
						?
							user.roles.map((role, y) => 
								role==='ROLE_ADMIN' 
									?
										<div className="badge badge-light-danger fw-bolder mb-1 me-1" key={y}>{role}</div>
									:
										<div className="badge badge-light-success fw-bolder mb-1 me-1" key={y}>{role}</div>
							)
						:
							<></>
					}
				</div>,
			selector: (user) => user.name,
			sortable: true,
		},{
			name: t('Estado'),
			cell: user =>
				<div className={user.isActive ? '' : 'bg-light'}>
					{user.isActive ? t('Activo') : t('Desactivado')}
				</div>,
			selector: (user) => user.isActive,
			sortable: true,
		},{
			name: '',
			cell: user =>
				<div>
					<Link to={'#'} href="#" className="btn btn-light btn-active-light-primary btn-sm" data-bs-toggle="dropdown" data-kt-menu-placement="bottom-end">{t('Acciones')}
						<span className="svg-icon svg-icon-5 m-0">
							<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
								<path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
							</svg>
						</span>
					</Link>
					<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4 dropdown-menu">
						<div className="menu-item px-3">
							<Link to={'#'} onClick={() => handleShow(user)} className="menu-link px-3">
								{t('Editar')}
							</Link>
						</div>
						<div className="menu-item px-3">
							{user.isActive
								? 
									<Link to={'#'} className="menu-link px-3 text-danger" data-kt-users-table-filter="delete_row"
										onClick={() => {
										ConfirmDisableUser(user?.id, user?.username)
										}}
									>
										{t('Desactivar')}
									</Link>
								: 
									<Link to={'#'} className="menu-link px-3 text-success" data-kt-users-table-filter="delete_row"
										onClick={() => {
										ConfirmEnableUser(user?.id, user?.username)
										}}
									>
										{t('Activar')}
									</Link>
							}
						</div>
					</div>
				</div>,
			sortable: true,
		}
	]

	return (
		<>
			<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
				<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
					<div className="page-title d-flex flex-column me-3">
						<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Listar Usuarios')}</h1>
						<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white">{t('Listar Usuarios')}</li>
						</ul>
					</div>
				</div>
			</div>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					<div className="card">
						<div className="card-header border-0 pt-6">
							<div className="card-toolbar">
								<div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
									<CreateUser />
								</div>
								<div className="d-flex justify-content-end align-items-center d-none" data-kt-user-table-toolbar="selected">
									<div className="fw-bolder me-5">
										<span className="me-2" data-kt-user-table-select="selected_count" />Selected
									</div>
									<button type="button" className="btn btn-danger" data-kt-user-table-select="delete_selected">Delete Selected</button>
								</div>
							</div>
						</div>
						<div className="card-body py-4">
							<div className='table-responsive'>
								{users?.length > 0
									?
										<CustomDataTable columns={columns} data={users} />
									:
										<></>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal show={showModal} size='lg' backdrop='static' >
				<Modal.Header closeButton onClick={handleClose} >
				<Modal.Title className='fw-bolder fs-2'>{t('Editar Usuario')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<EditUser admin={true} user={selectedUser} handleClose={handleClose} lightLink/>
				</Modal.Body>
			</Modal>
		</>
	)
}
