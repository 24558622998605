import React, { useEffect } from 'react'
import { Accordion } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import useAuth from '../../hooks/UseAuth'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, getUserSupervisor } from '../../actions/userActions';

export default function OffCanvasMenu({toggleShow}) {
  const { t } = useTranslation();
  const {auth} = useAuth()
  const dispatch = useDispatch()

  const {user, supervisor} = useSelector(state => state.users)

  const adminRole = ['ROLE_ADMIN']
  const userRole = ['ROLE_USER']

  useEffect(()=>{
	if(auth.user){
		dispatch(getUser(auth.user, true))
		dispatch(getUserSupervisor(auth.user))
	}
}, [auth, dispatch])

  return (
	<>
	<div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch" id="#kt_header_menu" data-kt-menu="true">
	  <NavLink to="/" className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
		<span className="menu-link py-3" onClick={toggleShow}>
		  <div className="menu-title" >{t('Inicio')}</div>
		</span>
	  </NavLink>
	  <NavLink to="/correctedsheets" className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
		<span className="menu-link py-3" onClick={toggleShow}>
			<div className="menu-title" >{t('Fichajes modificados')}</div>
		</span>
	  </NavLink>
	  <NavLink to="/vacations" className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
		<span className="menu-link py-3" onClick={toggleShow}>
			<div className="menu-title" >{t('Vacaciones')}</div>
		</span>
	  </NavLink>
	  {
		user?.departmentsManaged && user?.departmentsManaged.length > 0 &&
			<Accordion flush>
				<Accordion.Item eventKey="0">
					<Accordion.Header>
						<span className="menu-title menu-link text-gray-700 fw-bolder">{t('Departamento')}</span>
					</Accordion.Header>
					<Accordion.Body className="p-0 ps-5">
						<NavLink to="/vacation/managed" className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
							<span className="menu-link py-3" onClick={toggleShow}>
								<div className="menu-title" >{t('Información de vacaciones')}</div>
							</span>
						</NavLink>
						<NavLink to="/schedule/managed" className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
							<span className="menu-link py-3" onClick={toggleShow}>
								<div className="menu-title" >{t('Información de horario')}</div>
							</span>
						</NavLink>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
	  }
		{
			supervisor && supervisor.length > 0 &&
				<>
					<Accordion flush>
						<Accordion.Item eventKey="0">
							<Accordion.Header>
								<span className="menu-title menu-link text-gray-700 fw-bolder">{t('Gestión de fichajes')}</span>
							</Accordion.Header>
							<Accordion.Body className="p-0 ps-5">
								<NavLink to="/checksheet" className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
									<span className="menu-link py-3" onClick={toggleShow}>
										<div className="menu-title" >{t('Revisión de fichajes')}</div>
									</span>
								</NavLink>
								<NavLink to="/sheets" className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
									<span className="menu-link py-3" onClick={toggleShow}>
										<div className="menu-title" >{t('Modificación de fichajes')}</div>
									</span>
								</NavLink>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
					<Accordion flush>
						<Accordion.Item eventKey="0">
							<Accordion.Header>
								<span className="menu-title menu-link text-gray-700 fw-bolder">{t('Gestión de vacaciones')}</span>
							</Accordion.Header>
							<Accordion.Body className="p-0 ps-5">
								<NavLink to="/vacation/check" className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
									<span className="menu-link py-3" onClick={toggleShow}>
										<div className="menu-title" >{t('Revisión de vacaciones')}</div>
									</span>
								</NavLink>
								<NavLink to="/vacation/list" className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
									<span className="menu-link py-3" onClick={toggleShow}>
										<div className="menu-title" >{t('Gestión de vacaciones')}</div>
									</span>
								</NavLink>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
					<NavLink to="/payroll/list" className={({ isActive }) => (isActive ? "menu-item menu-lg-down-accordion me-lg-1 here show" : "menu-item menu-lg-down-accordion me-lg-1")}>
						<span className="menu-link py-3" onClick={toggleShow}>
							<div className="menu-title" >{t('Gestión de nóminas')}</div>
						</span>
					</NavLink>
				</>
		}
	  {/*begin::Admin*/}
	  {
		auth?.roles?.find(role => adminRole?.includes(role))
		  ?
		  <>
			<Accordion flush>
			  <Accordion.Item eventKey="0">
				<Accordion.Header>
				  <span className="menu-title menu-link text-gray-700 fw-bolder">{t('Admin')}</span>
				</Accordion.Header>
				<Accordion.Body className='p-0 ps-5'>
				<NavLink to={'/admin'} className={({ isActive }) => "menu-item menu-lg-down-accordion"}>
					<span className="menu-link p-3" onClick={toggleShow}>
					  <span className="text-gray-600"> - {t('Panel Administración')}</span>
					</span>
				  </NavLink>
				  <NavLink to={'/user/list'} className={({ isActive }) => "menu-item menu-lg-down-accordion"}>
					<span className="menu-link p-3" onClick={toggleShow}>
					  <span className="text-gray-600"> - {t('Usuarios')}</span>
					</span>
				  </NavLink>
				  <NavLink to={'/department/list'} className={({ isActive }) => "menu-item menu-lg-down-accordion"}>
					<span className="menu-link p-3" onClick={toggleShow}>
					  <span className="text-gray-600"> - {t('Departamentos')}</span>
					</span>
				  </NavLink>
				  {/* <NavLink to={'/task/list'} className={({ isActive }) => "menu-item menu-lg-down-accordion"}>
					<span className="menu-link p-3" onClick={toggleShow}>
					  <span className="text-gray-600"> - {t('Tareas')}</span>
					</span>
				  </NavLink> */}
				  <NavLink to={'/absence/list'} className={({ isActive }) => "menu-item menu-lg-down-accordion"}>
					<span className="menu-link p-3" onClick={toggleShow}>
					  <span className="text-gray-600"> - {t('Ausencias')}</span>
					</span>
				  </NavLink>
				  <NavLink to={'/absenceType/list'} className={({ isActive }) => "menu-item menu-lg-down-accordion"}>
					<span className="menu-link p-3" onClick={toggleShow}>
					  <span className="text-gray-600"> - {t('Tipos de ausencias')}</span>
					</span>
				  </NavLink>
				  <NavLink to='/festives' className={({isActive})=> "menu-item menu-lg-fown-accordion"}>
					<span className='menu-link p-3' onClick={toggleShow}>
						<span className='text-gray-600'> - {t('Festivos')}</span>
					</span>
				  </NavLink>
				  <NavLink to={'/logs'} className={({ isActive }) => "menu-item menu-lg-down-accordion"}>
					<span className="menu-link p-3" onClick={toggleShow}>
					  <span className="text-gray-600"> - {t('Registros')}</span>
					</span>
				  </NavLink>
				</Accordion.Body>
			  </Accordion.Item>
			</Accordion>
		  </>
		  : ''
	  }
	  {/*end::Admin*/}
	  
	</div>
	</>
  )
}
