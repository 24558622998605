import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { SuccessPopup } from '../../components/popups/Popups'
import { useDispatch, useSelector } from 'react-redux'
import { editTask } from '../../actions/taskActions'
import { useTranslation } from 'react-i18next'
import { getDepartments } from '../../actions/departmentActions'
import Select from 'react-select'

export default function EditTask(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {register, handleSubmit, watch, clearErrors, setValue, formState:{errors}} = useForm()
  const [errMsg, setErrMsg] = useState('')
  
  const { id = props.task.id } = useParams()
  
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = (e) => {
    e.preventDefault()
    clearErrors()
    setErrMsg('')
    setShow(false)
  }
  
  const {departments} = useSelector(state => state.departments)
  const task = props.task
  
  const [departmentOptions, setDepartmentOptions] = useState({options: null, selected: null})

  const data = {
    name: watch('form.name'),
    description: watch('form.description'),
    startDate: watch('form.startDate'),
    endDate: watch('form.endDate'),
    departments: []
  }

  useEffect(()=>{
    setValue('form', task)
    // Cuando llega del task
    //Aqui hay que poner el json en el formato de {label, value ponlo}
    setDepartmentOptions({...departmentOptions, selected: []})
    dispatch(getDepartments())
  }, [setValue, task])

  // Cuando se actualizan los departamentos porque obtiene la lista de departamentos
  useEffect(() => {
    if (departments) {
      let departmentsAvailable = []
      let selectedDepartmentOption = []

        departments.forEach(deparment => {
            const deparmentOption = {label: `${deparment.name}`, value: deparment.id}
            departmentsAvailable.push(deparmentOption)
            task?.departments?.forEach(deparmenttask => {
              if(deparmenttask.id === deparment.id){
                selectedDepartmentOption.push(deparmentOption)
              }
            })
        });
        setDepartmentOptions({options: departmentsAvailable, selected: selectedDepartmentOption})
    }
  }, [departments])

  const selectDepartment = (target) => {
    let selectedDepartmentOption = []
    target.forEach(option => {
      departments.forEach(deparment => {
        const deparmentOption = {label: `${deparment.name}`, value: deparment.id}
  
          if(deparment.id === option.value){
            selectedDepartmentOption.push(deparmentOption)
          }
      })
    })
    setDepartmentOptions({...departmentOptions, selected: selectedDepartmentOption})
  }

  const UpdateTask = async() => {
    departmentOptions?.selected?.forEach(deparment => {
      data.departments.push(deparment.value)
    })
    dispatch(editTask(data, id))
    setErrMsg('')
    setShow(false)
    SuccessPopup(`Tarea modificado correctamente!`)
  }

  return (
    <>
      {
        props.lightLink 
        ? <Link to={'#'} onClick={handleShow} className="menu-link px-3">
            {t('Editar')}
          </Link>
        : <Button variant="primary" onClick={handleShow} className="btn btn-sm btn-light-primary">
            {t('Editar')}
          </Button>
      }
      
      <Modal show={show} size='lg' backdrop='static' >
        <Modal.Header closeButton onClick={handleClose} >
          <Modal.Title className='fw-bolder fs-2'>{t('Editar Tarea')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*begin::Modal body*/}
          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
            {/*begin::Form*/}
            <form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(UpdateTask)}>
              {/*begin::Scroll*/}
              <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Nombre')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="text" name="name" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Nombre de la tarea" {...register(
                    'form.name', {
                      required: 'Este campo es obligatorio'
                      }
                  )}  />
                  {/*end::Input*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="name" data-validator="notEmpty">{errors.form?.name?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="fw-bold fs-6 mb-2">{t('Descripción')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="text" name="description" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Descripción de la tarea" {...register('form.description')} />
                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Fecha de Inicio')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="date" name="startDate" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Fecha de inicio" {...register(
                    'form.startDate', {
                      required: 'Este campo es obligatorio'
                      }
                  )}  />
                  {/*end::Input*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="startDate" data-validator="notEmpty">{errors.form?.startDate?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="fw-bold fs-6 mb-2">{t('Fecha de Fin')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="date" name="endDate" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Fecha de fin" {...register('form.endDate')}  />
                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Departamento')}</label>
                  {/*end::Label*/}
                  {/*begin::Select*/}
                  <Select isMulti onChange={selectDepartment}
                      id='department'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      options={departmentOptions.options}
                      value={departmentOptions.selected}
                  />
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="department" data-validator="notEmpty">{errors.form?.department?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/* <!--begin::Api error message--> */}
                <div className="fv-plugins-message-container invalid-feedback">
                  <div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
                </div>
                {/* <!--end::Api error message--> */}
              </div>
              {/*end::Scroll*/}
              {/*begin::Actions*/}
              <div className="text-center pt-15">
                <button className="btn btn-light me-3" onClick={handleClose}>{t('Cancelar')}</button>
                <button className="btn btn-primary">
                  <span className="indicator-label" onClick={UpdateTask}>{t('Actualizar')}</span>
                  <span className="indicator-progress">{t('Please wait...')}
                    <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                </button>
              </div>
              {/*end::Actions*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Modal body*/}
        </Modal.Body>
      </Modal>
    </>
  );
}
