import AxiosControl from '../hooks/AxiosControl'
const Axios = AxiosControl()

export const getNotifications = (user) => async (dispatch) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/notification/userNotifications', {
            user: user
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'NOTIFICATION_GET_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const getNotification = (notification) => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/notification/' + notification, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'NOTIFICATION_GET_DETAIL_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const addNotification = (data) => async (dispatch) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/notification', {
            sendUser: data.sendUser,
            receiverUser: data.receiverUser,
            title: data.title,
            description: data.description
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'NOTIFICATION_SEND_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const addNotificationDepartment = (data) => async (dispatch) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/notification/department', {
            sendUser: data.sendUser,
            receiverDepartment: data.receiverDepartment,
            title: data.title,
            description: data.description
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'NOTIFICATION_SEND_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const editNotification = (notification) => async (dispatch) => {
    try {
        const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/notification/' + notification + '/view', {}, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'NOTIFICATION_EDITED_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}