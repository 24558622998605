import i18next from '../../i18n'

export default function handleApiErrors(err) {
  if (!err?.response) {
    return(i18next.t('No hay respuesta del servidor'));
  } else if (err.response?.status === 400) {
    return(err.response?.data?.status ? err.response?.data?.status : i18next.t('Rellene todos los campos'));
  } else if (err.response?.status === 401) {
    return(i18next.t('Credenciales inválidas'));
  } else if (err.response?.status === 409) {
    return(i18next.t('La entidad ya existe en la base de datos'));
  }  else {
    return(err);
  }
}
