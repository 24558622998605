import React, { useEffect, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react' 
import dayGridPlugin from '@fullcalendar/daygrid' 
import interactionPlugin from "@fullcalendar/interaction";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/UseAuth';
import { getVacationsUser, requestVacations } from '../../actions/vacationActions';
import Swal from 'sweetalert2';
import { getUser } from '../../actions/userActions';

export default function Vacation() {
	const { t } = useTranslation()
	const {auth} = useAuth()
	const dispatch = useDispatch()
	const {vacations, massiveUpdated} = useSelector(state => state.vacations)
	const {user} = useSelector(state => state.users)
	const [event, setEvent] = useState([])
	const [newDaysRequested, setNewDaysRequested] = useState([])
	const [deletedDaysRequested, setDeletedDaysRequested] = useState([])
	const [daysCount, setDaysCount] = useState(0)

	const modalBtn = useRef(null)

	useEffect(()=>{
		let aVacations = []
		// Recorrer todas las vacaciones
		vacations?.map((vacation)=>{
			const vDay = new Date(vacation.date)
			const vDayStamp = `${vDay.getFullYear()}-${(parseInt(vDay.getMonth())+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}-${vDay.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}`
			
			// Añadirlas al array
			let eventJSON = {
				id: vacation.id,
				title: vacation.description, 
				start: vDayStamp,
				end: vDayStamp,
			}
			if (vacation.accepted) {
				eventJSON.color = 'green'
			}
			if (deletedDaysRequested?.find(d => d.id === vacation.id)) {
				eventJSON.color = 'orangered'
			}
			aVacations.push(eventJSON)
		})

		newDaysRequested.map(day => {
			aVacations.push({
				title: '',
				start: day,
				end: day,
				color: 'wheat'
			})
		})
		setEvent(aVacations)
	}, [vacations, newDaysRequested, deletedDaysRequested])
	
	useEffect(()=>{
		if(auth?.user){
			dispatch(getVacationsUser(auth.username))
			dispatch(getUser(auth?.user, true))
		}
	}, [auth, dispatch])

	useEffect(() => {
		if (massiveUpdated) {
			modalBtn.current.click()
			dispatch({type: 'VACATION_MASSIVE_UPDATE_RESET'})
			dispatch(getVacationsUser(auth.username))
			dispatch(getUser(auth?.user, true))
			setNewDaysRequested([])
			setDeletedDaysRequested([])
		}
	}, [massiveUpdated, dispatch, auth])

	
	const handleEventClick = (e) => {
		const vacationSelected = vacations?.find(v => String(v.id) === e.event.id)
		if (e.event.id !== '') {
			if (vacationSelected?.accepted !== true && !deletedDaysRequested?.find(v => v.id === vacationSelected?.id)) {
				setDeletedDaysRequested([...deletedDaysRequested, vacationSelected])
			}
		} else {
			setNewDaysRequested(newDaysRequested.filter(d => d !== e.event.startStr))
		}
		let dia = new Date(e.event.startStr).getDay();
		if(dia !== 0 && dia !== 6){
			setDaysCount(daysCount - 1)
		}
	}

	const handleDateClick = (e) => {
		const daysAvailable = parseInt(user?.vacationDays) - (parseInt(user?.vacationAccepted) + parseInt(user?.vacationRequested) + daysCount)
		if (daysAvailable > 0 && event.filter(ev => ev.start === e.dateStr).length === 0 && newDaysRequested.indexOf(e.dateStr) === -1) {
			setNewDaysRequested([...newDaysRequested, e.dateStr])
			let dia = new Date(e.dateStr).getDay();
			if(dia !== 0 && dia !== 6){
				setDaysCount(daysCount + 1)
			}
		}
	}

	const saveChanges = () => {
		Swal.fire({
			title: t('¿Quiere guardar los cambios?'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: t('Aceptar'),
			cancelButtonText: t('Cancelar')
			}).then((result) => {
			if (result.isConfirmed) {
				// dispatch(removeVacation(vacationSelected?.id))
				modalBtn.current.click()
				dispatch(requestVacations(newDaysRequested, deletedDaysRequested, auth?.user))
			}
		})
	}

	return (
		<>
		{/*begin::Toolbar*/}
		<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
			{/*begin::Container*/}
			<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
			{/*begin::Page title*/}
			<div className="page-title d-flex flex-column me-3">
				{/*begin::Title*/}
				<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Vacaciones')}</h1>
				{/*end::Title*/}
				{/*begin::Breadcrumb*/}
				<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
				{/*begin::Item*/}
				<li className="breadcrumb-item text-white opacity-75">
					<Link to={'/'} className="text-white text-hover-primary">{t('Inicio')}</Link>
				</li>
				{/*end::Item*/}
				{/*begin::Item*/}
				<li className="breadcrumb-item">
					<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
				</li>
				{/*end::Item*/}
				{/*begin::Item*/}
				<li className="breadcrumb-item text-white opacity-75">{t('Vacaciones')}</li>
				{/*end::Item*/}
				
				</ul>
				{/*end::Breadcrumb*/}
			</div>
			{/*end::Page title*/}
			</div>
			{/*end::Container*/}
		</div>
		{/*end::Toolbar*/}
		{/*begin::Container*/}
		<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
			{/*begin::Post*/}
			<div className="content flex-row-fluid" id="kt_content">
			{/*begin::Card*/}
				<div className="card">
					{/*begin::Card header*/}
					<div className="card-header">
						<h2 className="card-title fw-bolder">{t('Vacaciones')}: {parseInt(user?.vacationDays) - (parseInt(user?.vacationAccepted) + parseInt(user?.vacationRequested) + daysCount)}</h2>
					</div>
					{/*end::Card header*/}
					{/*begin::Card body*/}
					<div className="card-body">
						<FullCalendar
							plugins={[ dayGridPlugin, interactionPlugin ]}
							initialView="dayGridMonth"
							eventClick={handleEventClick}
							dateClick={handleDateClick}
							events={event}
							displayEventTime={false}
							height="auto"
						/>
						<div className='row'>
							<div className='col-12 my-7'>
								<div className='row'>
									<div className='col-3'>
										<span style={{float: 'left', width: '15px', height: '15px', border: '1px solid black', backgroundColor: 'green', marginTop: '2px', marginRight: '8px'}}/>
										<span>{t('Aceptados')}</span>
									</div>
									<div className='col-3'>
										<span style={{float: 'left', width: '15px', height: '15px', border: '1px solid black', backgroundColor: '#3788d8', marginTop: '2px', marginRight: '8px'}}/>
										<span>{t('Ya solicitados')}</span>
									</div>
									<div className='col-3'>
										<span style={{float: 'left', width: '15px', height: '15px', border: '1px solid black', backgroundColor: 'orangered', marginTop: '2px', marginRight: '8px'}}/>
										<span>{t('Eliminado')}</span>
									</div>
									<div className='col-3'>
										<span style={{float: 'left', width: '15px', height: '15px', border: '1px solid black', backgroundColor: 'wheat', marginTop: '2px', marginRight: '8px'}}/>
										<span>{t('Solicitado')}</span>
									</div>
								</div>
							</div>
							<div className='col-12 my-7 d-grid gap-2'>
								<button type='button' className='btn btn-primary' onClick={() => {saveChanges()}} disabled={!(newDaysRequested.length > 0) && !(deletedDaysRequested.length > 0)}>{t('Guardar')}</button>
							</div>
						</div>
					</div>
					{/*end::Card body*/}
				</div>
			{/*end::Card*/}
			</div>
			{/*end::Post*/}
		</div>
		<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#loadingModal" hidden ref={modalBtn}/>
		<div className="modal fade show" id="loadingModal" tabIndex="-1" data-bs-backdrop="static">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-body text-center">
						<span className='spinner-border align-middle ms-2' />
					</div>
				</div>
			</div>
		</div>
		{/*end::Container*/}
		</>
	)
}