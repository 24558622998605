import React from 'react'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SuccessPopup } from '../../components/popups/Popups';
import { useTranslation } from 'react-i18next'
import { getPayrolls, removePayroll } from '../../actions/payrollActions';

export default function DeletePayroll(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const id = props.payroll.id
  const user = props.user

  const Remove = async() => {
	dispatch(removePayroll(id))
	dispatch(getPayrolls(user))
  }

  return (
	<>
		<Link to={'#'} className="menu-link px-3 text-danger btn btn-light-danger" data-kt-users-table-filter="delete_row" onClick={Remove}>
			<span className="svg-icon svg-icon-muted svg-icon-2x m-0">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
					<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"/>
					<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"/>
				</svg>
			</span>
		</Link>
	</>
  );
}
