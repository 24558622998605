import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { absenceReducer } from './reducers/absenceReducer';
import { absenceTypeReducer } from './reducers/absenceTypeReducer';
import { businessReducer } from './reducers/businessReducer';
import { checkRequestReducer } from './reducers/checkRequestReducer';
import { departmentReducer } from './reducers/departmentReducer';
import { documentReducer } from './reducers/documentReducer';
import { logReducer } from './reducers/logReducer';
import { notificationReducer } from './reducers/notificationReducer';
import { payrollReducer } from './reducers/payrollReducer';
import { redirectReducer } from './reducers/redirectReducer';
import { reportReducer } from './reducers/reportReducer';
import { scheduleReducer } from './reducers/scheduleReducer';
import { sheetReducer } from './reducers/sheetReducer';
import { taskReducer } from './reducers/taskReducer';
import { userReducer } from './reducers/userReducer';
import { vacationReducer } from './reducers/vacationReducer';
import { festiveReducer } from "./reducers/festiveReducer";

const intialState = {}
const appReducer = combineReducers({
	absences: absenceReducer,
	absenceTypes: absenceTypeReducer,
	checkRequests: checkRequestReducer,
	departments: departmentReducer,
	documents: documentReducer,
	notifications: notificationReducer,
	logs: logReducer,
	payrolls: payrollReducer,
	reports: reportReducer,
	schedules: scheduleReducer,
	sheets: sheetReducer,
	tasks: taskReducer,
	users: userReducer,
	vacations: vacationReducer,
	business: businessReducer,
	redirectReducer: redirectReducer,
	festives: festiveReducer,
})

const reducer = (state, action) => {
	if (action.type === 'USER_LOGOUT') {
		return appReducer(undefined, action)
	}
	return appReducer(state, action)
}

const composeEnhancer = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, intialState, composeEnhancer(applyMiddleware(thunk)))

export default store