import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      {/*begin::Container*/}
      <div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
        {/*begin::Copyright*/}
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-bold me-1">2023©</span>
          <a href='https://smalldev.es' target='_blank' className="text-gray-800 text-hover-primary">Smalldev</a>
        </div>
        {/*end::Copyright*/}
        {/*begin::Menu*/}
        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          <li className='menu-item'>
            <Link to="/about" className='menu-link px-2'>Sobre la aplicación</Link>
          </li>
          <li className="menu-item">
            <a href='https://smalldev.es/quienes-somos/' target='_blank' className="menu-link px-2">Sobre nosotros</a>
          </li>
          <li className="menu-item">
            <a href='https://smalldev.es#contacto' target='_blank' className="menu-link px-2">Soporte</a>
          </li>
        </ul>
        {/*end::Menu*/}
      </div>
      {/*end::Container*/}
    </div>
  )
}
