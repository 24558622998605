import React, { Fragment, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { addDepartment } from '../../actions/departmentActions'
import { SuccessPopup } from '../../components/popups/Popups'
import { useTranslation } from 'react-i18next'
import { getUsers } from '../../actions/userActions';
import useAuth from '../../hooks/UseAuth';

export default function CreateDepartment() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
	const {auth} = useAuth()

  
	const {users} = useSelector(state => state.users)

  
  const {register, handleSubmit, watch, clearErrors, formState:{errors}, reset} = useForm()
  const [errMsg, setErrMsg] = useState('')
  const [festives, setFestives] = useState([])

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = (e) => {
    e.preventDefault()
    clearErrors()
    setErrMsg('')
    setShow(false)
  }
  
  const data = {
    name: watch('name'),
    description: watch('description'),
    address: watch('address'),
		manager: watch('manager')
  }

  const CreateDepartment = async() => {
    dispatch(addDepartment({...data, festives: festives}))
    setErrMsg('')
    setShow(false)
    SuccessPopup(`Departamento '${data.name}' creado correctamente!`)
    setFestives([])
    reset()
  }

  const addFestive = () => {
    setFestives([...festives, ''])
  }

  const changeFestive = (index, value) => {
    setFestives(festives?.map((f, i) => {
      if (i === index) {
        return value
      } else {
        return f
      }
    }))
  }

  const removeFestive = (index) => {
    let newFestives = []
    festives.forEach((f, i) => {
      if (i !== index) {
        newFestives.push(f)
      }
    })
    setFestives(newFestives)
  }

  useEffect(() => {
		if(auth){
			dispatch(getUsers())
		}
	}, [auth])

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
        <span className="svg-icon svg-icon-2 me-3">
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
            <rect opacity="0.5" x="11.364" y="20.364" width={16} height={2} rx={1} transform="rotate(-90 11.364 20.364)" fill="black" />
            <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
          </svg>
        </span>
        {/*end::Svg Icon*/}
        {t('Crear Departamento')}
      </Button>

      <Modal show={show} size='lg' backdrop='static' >
        <Modal.Header closeButton onClick={handleClose} >
          <Modal.Title className='fw-bolder fs-2'>{t('Crear Departamento')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*begin::Modal body*/}
          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
            {/*begin::Form*/}
            <form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(CreateDepartment)}>
              {/*begin::Scroll*/}
              <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Nombre')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="text" name="name" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Nombre del departamento" {...register(
                    'name', {
                      required: 'Este campo es obligatorio'
                      }
                  )}  />
                  {/*end::Input*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="name" data-validator="notEmpty">{errors.name?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="fw-bold fs-6 mb-2">{t('Descripción')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="text" name="description" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Descripcion del departamento" {...register('description')}  />
                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="fw-bold fs-6 mb-2">{t('Dirección')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="text" name="direccion" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Localizacion de la organizacion" {...register('address')}  />
                  {/*end::Input*/}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fw-bold fs-6 mb-2'>{t('Responsable')}</label>
                  <select className="form-select form-select-solid" placeholder='Selecciona el Usuario' {...register("manager", { required: false })} >
                    {users?.length
                      ?
                        <>
                          <option></option>
                          {users.map((user, i) => 
                            user.isActive === true ?
                                <option value={user.id} key={i}>{user.username}</option>
                              :
                                <Fragment key={i}></Fragment>
                          )}
                        </>
                      :
                        <option disabled>{t('No existen usuarios')}</option>
                    }
                  </select>
                </div>
                {/*end::Input group*/}
                {/* <!--begin::Api error message--> */}
                <div className="fv-plugins-message-container invalid-feedback">
                  <div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
                </div>
                {/* <!--end::Api error message--> */}

                {/* start::festives inputs */}
                <div className='row my-4'>
                  <div className='col col-12 d-grid gap-2'>
                    <button type='button' className='btn btn-info' onClick={() => {addFestive()}}>{t('Añadir festivo')}</button>
                  </div>
                  <div className='col col-12'>
                    <div className='row mt-3'>
                      {
                        festives?.map((festive, index) => (
                          <div key={index} className='col col-md-6 my-3 col-sm-12'>
                            <div className='row'>
                              <div className='col-10'>
                                <input required type='date' className='form-control' value={festive} onChange={(e) => {changeFestive(index, e.target.value)}} />
                              </div>
                              <div className='col-2'>
                                <button type='button' className='btn btn-danger w-100 h-100 d-flex justify-content-center align-items-center' onClick={() => {removeFestive(index)}}><i className='fas fa-trash p-0' /></button>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
                {/* end::festives inputs */}
              </div>
              {/*end::Scroll*/}
              {/*begin::Actions*/}
              <div className="text-center pt-15">
                <button className="btn btn-light me-3" onClick={handleClose}>{t('Cancelar')}</button>
                <button className="btn btn-primary">
                  <span className="indicator-label">{t('Crear')}</span>
                  <span className="indicator-progress">{t('Please wait...')}
                    <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                </button>
              </div>
              {/*end::Actions*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Modal body*/}
        </Modal.Body>
      </Modal>
    </>
  );
}
