import AxiosControl from '../hooks/AxiosControl'
const Axios = AxiosControl()

export const getFestives = () => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/festives', {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'FESTIVE_GET_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const getUserFestives = (user) => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/festives/user/' + user, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'FESTIVE_GET_SUCCESS',
                payload: response.data
            }) 
        }
    } catch (error) {
        console.log(error);
    }
}

export const getFestive = (festive) => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/festives/' + festive, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'FESTIVE_GET_DETAIL_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const addFestive = (date) => async (dispatch) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/festives', {
            date: date
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'FESTIVE_SEND_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const deleteFestive = (festive) => async (dispatch) => {
    try {
        const response = await Axios.delete(process.env.REACT_APP_DB_ADDRESS + '/api/festives/' + festive, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'FESTIVE_DELETED_SUCCESS',
                payload: festive
            }) 
        }
    } catch(error){
        console.log(error)
    }
}