import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getUser, updatePassword } from '../../actions/userActions';
import { SuccessPopup } from '../../components/popups/Popups';
import useAuth from '../../hooks/UseAuth';
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';

export default function EditUserPassword(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {auth} = useAuth()

  const { id } = useParams()

  const {register, handleSubmit, watch, clearErrors, formState:{errors}, reset} = useForm()
  const [errMsg, setErrMsg] = useState('')
  const [passwordShow, setPasswordShow] = useState(false)
  const [repeatPasswordShow, setRepeatPasswordShow] = useState(false)

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = (e) => {
    e.preventDefault()
    clearErrors()
    setErrMsg('')
    setShow(false)
  }

  useEffect(() => {
    if(auth && id){
      dispatch(getUser(id))
    }
  }, [auth, id, dispatch])

  const data = {
    password: watch('password'),
    repeatPassword: watch('repeatPassword')
  }

  const EditPassword = async() => {
    dispatch(updatePassword({password: data.password}, props.id))
    setErrMsg('')
    setShow(false)
    reset({
      password: '',
      repeatPassword: ''
    })
    SuccessPopup(`Contraseña actualizada correctamente!`)
  }

  const togglePassword = () => {
    setPasswordShow(!passwordShow);
  }

  const toggleRepeatPassword = () => {
    setRepeatPasswordShow(!repeatPasswordShow);
  }
    
  return (
    <>
      <button onClick={handleShow} type="button" className="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto">
        {/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
        <span className="svg-icon svg-icon-3">
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
            <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
            <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
          </svg>
        </span>
        {/*end::Svg Icon*/}
      </button>

      <Modal show={show} size='lg' backdrop='static' >
        <Modal.Header closeButton onClick={handleClose} >
          <Modal.Title className='fw-bolder fs-2'>{t('Actualizar contraseña del usuario')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*begin::Modal body*/}
          <div className="modal-body scroll-y mx-5 mx-xl-15">
            {/*begin::Form*/}
            <form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(EditPassword)}>
              {/*begin::Scroll*/}
              <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/* <!--begin::Input group--> */}
                  <div className="fv-row mb-6">
                    {/* <!--begin::Wrapper--> */}
                    <div className="mb-1">
                      {/* <!--begin::Label--> */}
                      <label className="required fw-bold fs-6 mb-2">{t('Nueva Contraseña')}</label>
                      {/* <!--end::Label--> */}
                      {/* <!--begin::Input wrapper--> */}
                      <div className="position-relative mb-3">
                        <input className="form-control form-control-solid mb-3 mb-lg-0" type={passwordShow ? "text" : "password"} placeholder="Contraseña" name="password" autoComplete="off" 
                          {...register(
                            'password', {
                              required: 'Este campo es obligatorio'
                            }
                          )} 
                        />
                        <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={togglePassword}>
                          {
                            passwordShow 
                            ? <i className="bi bi-eye-slash fs-2"></i>
                            : <i className="bi bi-eye fs-2"></i>
                          }
                        </span>
                      </div>
                      {/* <!--end::Input wrapper--> */}
                    </div>
                    {/* <!--end::Wrapper--> */}
                    {/* <!--begin::Input error message--> */}
                    <div className="fv-plugins-message-container invalid-feedback">
                      <div data-field="username" data-validator="notEmpty">{errors.password?.message}</div>
                    </div>
                    {/* <!--end::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback"></div></div>
                  {/* <!--end::Input group=--> */}
                  {/* <!--begin::Input group--> */}
                  <div className="fv-row mb-6">
                    {/* <!--begin::Wrapper--> */}
                    <div className="mb-1">
                      {/* <!--begin::Label--> */}
                      <label className="required fw-bold fs-6 mb-2">{t('Repetir contraseña')}</label>
                      {/* <!--end::Label--> */}
                      {/* <!--begin::Input wrapper--> */}
                      <div className="position-relative mb-3">
                        <input className="form-control form-control-solid mb-3 mb-lg-0" type={repeatPasswordShow ? "text" : "password"} placeholder="Contraseña" name="password" autoComplete="off" 
                          {...register(
                            'repeatPassword', {
                              validate: value =>
                                value === data.password || "Las contraseñas no coinciden"
                            }
                          )} 
                        />
                        <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={toggleRepeatPassword}>
                          {
                            repeatPasswordShow 
                            ? <i className="bi bi-eye-slash fs-2"></i>
                            : <i className="bi bi-eye fs-2"></i>
                          }
                        </span>
                      </div>
                      {/* <!--end::Input wrapper--> */}
                    </div>
                    {/* <!--end::Wrapper--> */}
                    {/* <!--begin::Input error message--> */}
                    <div className="fv-plugins-message-container invalid-feedback">
                      <div data-field="username" data-validator="notEmpty">{errors.repeatPassword?.message}</div>
                    </div>
                    {/* <!--end::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback"></div></div>
                  {/* <!--end::Input group=--> */}
                </div>
                {/* <!--begin::Api error message--> */}
                <div className="fv-plugins-message-container invalid-feedback">
                  <div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
                </div>
                {/* <!--end::Api error message--> */}
              </div>
              {/*end::Scroll*/}
              {/*begin::Actions*/}
              <div className="text-center pt-10">
                <button className="btn btn-light me-3" onClick={handleClose}>{t('Cancelar')}</button>
                <button className="btn btn-primary">
                  <span className="indicator-label">{t('Subir')}</span>
                  <span className="indicator-progress">{t('Porfavor espere...')}
                    <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                </button>
              </div>
              {/*end::Actions*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Modal body*/}
        </Modal.Body>
      </Modal>
    </>
  );
}
