import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CreateVacation from '../modals/CreateVacation'
import useAuth from '../../hooks/UseAuth'
import { editMultipleVacations, editVacation, getVacationsSupervised, removeVacation, requestVacations } from '../../actions/vacationActions';
import { CustomDataTable } from '../../components/datatables/CustomDataTable'
import FullCalendar from '@fullcalendar/react' 
import dayGridPlugin from '@fullcalendar/daygrid' 
import interactionPlugin from "@fullcalendar/interaction";
import Swal from 'sweetalert2';

export default function ListVacations() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {auth} = useAuth()

	const {vacations, massiveUpdated} = useSelector(state => state.vacations)

	const [vacationsPendind, setVacationsPending] = useState([])
	const [event, setEvent] = useState([])

	const modalBtn = useRef(null)

	const columns = [
		{
			name: t('Seleccionar'),
			cell: vacation =>
				<div className='ms-7'>
					<input type="checkbox" className='form-check-input' defaultChecked={vacation.checked} onClick={() => {selectVacation(vacation.id)}} />
				</div>
		},
		{
			name: t('Usuario'),
			cell: vacation =>
				<Link to={`/user/${vacation.user.id}`} className="text-gray-800 text-hover-primary mb-1">
					{vacation.user.username}
				</Link>,
			selector: (vacation) => vacation.user.username,
			sortable: true,
		},{
			name: t('Fecha'),
			cell: vacation =>
				<div>
					{vacation.date}
				</div>,
			selector: (vacation) => vacation.date,
			sortable: true
		},{
			name: t('Acciones'),
			cell: vacation =>
				<div className='row w-100'>
					<div className='col-6 d-grid gap-2'>
						<button type='button' className='btn btn-outline btn-outline-success' onClick={() => {acceptOne(vacation.id)}}>{t('Aceptar')}</button>
					</div>
					<div className='col-6 d-grid gap-2'>
						<button type='button' className='btn btn-outline btn-outline-danger' onClick={() => {denyOne(vacation.id)}}>{t('Denegar')}</button>
					</div>
				</div>
		}
	]

	const acceptOne = (id) => {
		dispatch(editVacation(id, true))
	}

	const denyOne = (id) => {
		dispatch(editVacation(id, false))
	}

	const acceptSelected = () => {
		if (vacationsPendind?.filter(v => v.checked === true).length > 0) {
			modalBtn.current.click()
			dispatch(editMultipleVacations(vacationsPendind?.filter(v => v.checked === true), true))
		}
	}

	const denySelected = () => {
		if (vacationsPendind?.filter(v => v.checked === true).length > 0) {
			modalBtn.current.click()
			dispatch(editMultipleVacations(vacationsPendind?.filter(v => v.checked === true), false))
		}
	}

	const selectVacation = (id) => {
		setVacationsPending(vacationsPendind.map(vacation => {
			if (vacation.id === id) {
				if (vacation.checked) {
					vacation.checked = false
				} else {
					vacation.checked = true
				}
				return vacation
			} else {
				return vacation
			}
		}))
	}

	const handleEventClick = (e) => {
		Swal.fire({
			title: t('¿Está seguro de que desea eliminarlo?'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: t('Aceptar'),
			cancelButtonText: t('Cancelar')
			}).then((result) => {
			if (result.isConfirmed) {
				dispatch(removeVacation(e.event.id))
			}
		})
	}

	useEffect(() => {
		if(auth){
			dispatch(getVacationsSupervised(auth?.user))
		}
	}, [auth, dispatch])

	useEffect(() => {
		setVacationsPending(vacations?.filter(v => v.accepted !== true))
		let aVacations = []
		// Recorrer todas las vacaciones
		vacations?.map((vacation)=>{
			const vDay = new Date(vacation.date)
			const vDayStamp = `${vDay.getFullYear()}-${(parseInt(vDay.getMonth())+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}-${vDay.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}`
			
			// Añadirlas al array
			let eventJSON = {
				id: vacation.id,
				title: vacation.user.username, 
				start: vDayStamp,
				end: vDayStamp
			}
			if (vacation.accepted) {
				eventJSON.color = 'green'
			}
			aVacations.push(eventJSON)
		})
		setEvent(aVacations)
	}, [vacations])

	useEffect(() => {
		if (massiveUpdated) {
			modalBtn.current.click()
			dispatch({type: 'VACATION_MASSIVE_UPDATE_RESET'})
			dispatch(getVacationsSupervised(auth?.user))
			setVacationsPending([])
		}
	}, [massiveUpdated, dispatch, auth])

	return (
		<>
			<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
				<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
					<div className="page-title d-flex flex-column me-3">
						<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Listar Vacaciones')}</h1>
						<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/admin' className="text-white text-hover-primary">{t('Admin')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white">{t('Listar Vacaciones')}</li>
						</ul>
					</div>
				</div>
			</div>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					<div className="card">
						<div className="card-header border-0 pt-6">
							<div className="card-toolbar">
								<div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
									<CreateVacation />
								</div>
								<div className="d-flex justify-content-end align-items-center d-none" data-kt-user-table-toolbar="selected">
									<div className="fw-bolder me-5">
										<span className="me-2" data-kt-user-table-select="selected_count" />Selected
									</div>
									<button type="button" className="btn btn-danger" data-kt-user-table-select="delete_selected">Delete Selected</button>
								</div>
							</div>
						</div>
						<div className="card-body py-4">
							<div className='table-responsive'>
								{vacations?.length > 0
									?
										<CustomDataTable columns={columns} data={vacationsPendind} />
									:
										<></>
								}
							</div>
							<div className='row'>
								<div className='col-6'>
									<button type='button' className='btn btn-outline btn-outline-success mx-3' onClick={()=> {acceptSelected()}}>{t('Aceptar seleccionadas')}</button>
									<button type='button' className='btn btn-outline btn-outline-danger mx-3' onClick={()=> {denySelected()}}>{t('Denegar seleccionadas')}</button>
								</div>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="card-header">
							<h2 className="card-title fw-bolder">{t('Vacaciones')}</h2>
						</div>
						<div className="card-body">
							<FullCalendar
								plugins={[ dayGridPlugin, interactionPlugin ]}
								initialView="dayGridMonth"
								eventClick={handleEventClick}
								events={event}
								displayEventTime={false}
								height="auto"
							/>
							<div className='row'>
								<div className='col-12 my-7'>
									<div className='row'>
										<div className='col-6 d-flex justify-content-center'>
											<span style={{float: 'left', width: '15px', height: '15px', border: '1px solid black', backgroundColor: 'green', marginTop: '2px', marginRight: '8px'}}/>
											<span>{t('Aceptados')}</span>
										</div>
										<div className='col-6 d-flex justify-content-center'>
											<span style={{float: 'left', width: '15px', height: '15px', border: '1px solid black', backgroundColor: '#3788d8', marginTop: '2px', marginRight: '8px'}}/>
											<span>{t('solicitados')}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#loadingModal" hidden ref={modalBtn}/>
			<div className="modal fade show" id="loadingModal" tabIndex="-1" data-bs-backdrop="static">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-body text-center">
							<span className='spinner-border align-middle ms-2' />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
