import React, { Fragment, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { SuccessPopup } from '../../components/popups/Popups'
import { useDispatch, useSelector } from 'react-redux'
import { assignUserSchedule, getSchedule } from '../../actions/scheduleActions'
import { getUsers } from '../../actions/userActions'
import { useTranslation } from 'react-i18next'

export default function ScheduleAssignUser(props) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { id } = useParams()

	const {users} = useSelector(state => state.users)
	const {schedule} = useSelector(state => state.schedules)

	const {register, handleSubmit, watch, clearErrors, formState:{errors}} = useForm()
	const [errMsg, setErrMsg] = useState('')

	const [show, setShow] = useState(false)
	const handleShow = () => setShow(true)
	const handleClose = (e) => {
		e.preventDefault()
		clearErrors()
		setErrMsg('')
		setShow(false)
	}

	useEffect(() => {
		dispatch(getSchedule(id))
		dispatch(getUsers())
	}, [id, dispatch])

	const data = {
		user: watch('user'),
		schedule: id
	}

	const AssignUser = async() => {
		dispatch(assignUserSchedule({user: data.user, schedule: data.schedule}))
		setErrMsg('')
		setShow(false)
		SuccessPopup(`Horario asignado correctamente!`)
	}

	return (
		<>
		<Button variant="primary" onClick={handleShow} className="btn btn-sm btn-light-primary ms-5">
			{t('Asignar')}
		</Button>

		<Modal show={show} size='lg' backdrop='static' >
			<Modal.Header closeButton onClick={handleClose} >
			<Modal.Title className='fw-bolder fs-2'>{t('Asignar horario al usuario')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			{/*begin::Modal body*/}
			<div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
				{/*begin::Form*/}
				<form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(AssignUser)}>
				{/*begin::Scroll*/}
				<div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
					{/*begin::Input group*/}
					<div className="fv-row mb-7">
					{/*begin::Label*/}
					<label className="required fw-bold fs-6 mb-2">{t('Usuario')}</label>
					{/*end::Label*/}
					{/*begin::Select*/}
					<select className="form-select form-select-solid" placeholder='Selecciona el Usuario' {...register("user", { required: true })} >
						{users?.length
							?
							<>
								<option></option>
								{users.map((user, i) => 
								user.isActive === true ? 
									schedule?.users?.some(usertwo => usertwo.id === user.id)
									?
										<Fragment key={i}></Fragment>
									:
										<option value={user.id} key={i}>{user.username}</option>
									:
									<Fragment key={i}></Fragment>
								)}
							</>
							:
							<option disabled>{t('No existen usuarios')}</option>
						}
					</select>
					{/*end::Select*/}
					{/* <!--begin::Input error message--> */}
					<div className="fv-plugins-message-container invalid-feedback">
						<div data-field="name" data-validator="notEmpty">{errors.name?.message}</div>
					</div>
					{/* <!--end::Input error message--> */}
					</div>
					{/*end::Input group*/}
					
					{/* <!--begin::Api error message--> */}
					<div className="fv-plugins-message-container invalid-feedback">
					<div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
					</div>
					{/* <!--end::Api error message--> */}
				</div>
				{/*end::Scroll*/}
				{/*begin::Actions*/}
				<div className="text-center pt-15">
					<button className="btn btn-light me-3" onClick={handleClose}>{t('Cancelar')}</button>
					<button className="btn btn-primary">
					<span className="indicator-label">{t('Añadir')}</span>
					<span className="indicator-progress">{t('Porfavor espere...')}
						<span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
					</button>
				</div>
				{/*end::Actions*/}
				</form>
				{/*end::Form*/}
			</div>
			{/*end::Modal body*/}
			</Modal.Body>
		</Modal>
		</>
	);
}