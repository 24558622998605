export const userReducer = (state = {users: null, user: null, supervisor: null, managed: null, activeReady: false}, action)=>{
    switch (action.type) {
        case 'USER_GET_SUCCESS':
            return {
                ...state,
                users: action.payload
            }
        case 'USER_GET_DETAIL_SUCCESS':
            return {
                ...state,
                user: action.payload
            }
        case 'USER_GET_SUPERVISOR_DETAIL_SUCCESS':
            return {
                ...state,
                supervisor: action.payload
            }
        case 'USER_SEND_SUCCESS':
            return {
                ...state,
                users: [
                    ...state.users,
                    action.payload
                ]
            }
        case 'USER_EDITED_SUCCESS':
            return {...state, user: action.payload, users: state.users.map(user => {
                    if (user.id === action.payload.id) {
                        return action.payload
                    } else {
                        return user
                    }
                })
            }
        case 'ACTIVE_USER_READY':
            return {
                ...state,
                activeReady: true
            }
        default:
            return state;
    }
}