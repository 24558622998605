import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { editUser, getUser, updateRoles } from '../../actions/userActions'
import { SuccessPopup } from '../../components/popups/Popups'
import useAuth from '../../hooks/UseAuth'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

export default function EditUserRole(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {auth} = useAuth()

  const { id } = useParams()

  const {register, handleSubmit, watch, clearErrors, formState:{errors}, setValue} = useForm()
  const [errMsg, setErrMsg] = useState('')

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = (e) => {
    e.preventDefault()
    clearErrors()
    setErrMsg('')
    setShow(false)
  }

  const {user} = useSelector(state => state.users)

  useEffect(()=>{
      if(auth.user){
        dispatch(getUser(id))
      }
  }, [auth, id, dispatch])

  const data = {
    role: watch('form.role'),
  }

  useEffect(()=>{
    if (user) {
      setValue('form.role', user?.roles)
      if (user.roles.indexOf('ROLE_ADMIN') !== -1) {
        setValue('form.role', 'Admin')
      } else {
        setValue('form.role', 'User')
      }
    }
  }, [setValue, user])

  const EditRole = async() => {
    let aRoles = []

    if(data.role === 'User'){
      aRoles.push('ROLE_USER')
    } else if(data.role === 'Admin'){
      aRoles.push('ROLE_USER', 'ROLE_ADMIN')
    }
    
    dispatch(updateRoles({roles: aRoles}, props.id))
    setErrMsg('')
    setShow(false)
    SuccessPopup(`Rol actualizado correctamente!`)
  }
    
  return (
    <>
      <button onClick={handleShow} type="button" className="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto">
        {/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
        <span className="svg-icon svg-icon-3">
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
            <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
            <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
          </svg>
        </span>
        {/*end::Svg Icon*/}
      </button>

      <Modal show={show} size='lg' backdrop='static' >
        <Modal.Header closeButton onClick={handleClose} >
          <Modal.Title className='fw-bolder fs-2'>{t('Actualizar roles del usuario')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*begin::Modal body*/}
          <div className="modal-body scroll-y mx-5 mx-xl-15">
            {/*begin::Form*/}
            <form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(EditRole)}>
              {/*begin::Notice*/}
              <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                {/*begin::Icon*/}
                {/*begin::Svg Icon | path: icons/duotune/general/gen044.svg*/}
                <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                    <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                    <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                {/*end::Icon*/}
                {/*begin::Wrapper*/}
                <div className="d-flex flex-stack flex-grow-1">
                  {/*begin::Content*/}
                  <div className="fw-bold">
                    <div className="fs-6 text-gray-700">{t('Tenga en cuenta que al reducir el rol de un usuario va a peder algunos privilegios dentro de la aplicación')}.</div>
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Wrapper*/}
              </div>
              {/*end::Notice*/}
              {/*begin::Scroll*/}
              <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="fs-6 fw-bold form-label mb-5">
                    <span className="required">{t('Selecciona el rol de usuario')}</span>
                  </label>
                  {/*end::Label*/}
                  {/*begin::Input row*/}
                  <div className="d-flex">
                    {/*begin::Radio*/}
                    <div className="form-check form-check-custom form-check-solid">
                      {/*begin::Input*/}
                      <input className="form-check-input me-3" name="user_role" type="radio" checked={data.role === 'Admin'} defaultValue={'Admin'} id='Admin' {...register("form.role", {required: 'Selecciona 1 rol'})} />
                      {/*end::Input*/}
                      {/*begin::Label*/}
                      <label className="form-check-label" htmlFor="Admin">
                        <div className="fw-bolder text-gray-800">{t('Administrador')}</div>
                        <div className="text-gray-600">{t('Acceso total a la aplicacion. Permite gestionar, editar y eliminar/desactuvar cualquier entidad')}.</div>
                      </label>
                      {/*end::Label*/}
                    </div>
                    {/*end::Radio*/}
                  </div>
                  {/*end::Input row*/}
                  <div className="separator separator-dashed my-5" />
                  {/*begin::Input row*/}
                  <div className="d-flex">
                    {/*begin::Radio*/}
                    <div className="form-check form-check-custom form-check-solid">
                      {/*begin::Input*/}
                      <input className="form-check-input me-3" name="user_role" type="radio" checked={!data.role || data.role === 'User'} defaultValue={'User'} id='User' {...register("form.role", {required: 'Selecciona 1 rol'})} />
                      {/*end::Input*/}
                      {/*begin::Label*/}
                      <label className="form-check-label" htmlFor="User">
                        <div className="fw-bolder text-gray-800">{t('Usuario')}</div>
                        <div className="text-gray-600">{t('Permite fichar, conocer sus horarios, departamentos, mensajes, gestionar sus documentos y modificar su perfil')}.</div>
                      </label>
                      {/*end::Label*/}
                    </div>
                    {/*end::Radio*/}
                  </div>
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="name" data-validator="notEmpty">{errors.form?.role?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/* <!--begin::Api error message--> */}
                <div className="fv-plugins-message-container invalid-feedback">
                  <div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
                </div>
                {/* <!--end::Api error message--> */}
              </div>
              {/*end::Scroll*/}
              {/*begin::Actions*/}
              <div className="text-center pt-10">
                <button className="btn btn-light me-3" onClick={handleClose}>{t('Cancelar')}</button>
                <button className="btn btn-primary">
                  <span className="indicator-label">{t('Subir')}</span>
                  <span className="indicator-progress">{t('Porfavor espere...')}
                    <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                </button>
              </div>
              {/*end::Actions*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Modal body*/}
        </Modal.Body>
      </Modal>
    </>
  );
}
