import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/UseAuth';
import { CustomDataTable } from '../../components/datatables/CustomDataTable';
import { getUsersPayrolls } from '../../actions/payrollActions';

export default function ListPayroll() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const {auth} = useAuth()
	const {usersPayrolls, loading, error} = useSelector(state => state.payrolls)

	const [usersPayrollsState, setUsersPayrolls] = useState([])

	const columns = [
		{
			name: t('Nombre'),
			cell: payrollUser =>
				<div className="d-flex align-items-center">
					<span className="text-gray-800 text-hover-primary">{payrollUser.lastname}, {payrollUser.firstName}</span>
				</div>,
			selector: (payrollUser) => `${payrollUser.lastname}, ${payrollUser.firstName}`,
			sortable: true,
		},{
			name: t('Subir / Modificar'),
			cell: payrollUser =>
				<div>
					<Link target='_blank' to={`/payroll/add/${payrollUser.id}`} className='btn btn-success' style={{padding: '5px', width: '100%'}}>{t('Subir / Modificar')}</Link>
				</div>,
			sortable: false,
		},{
			name: t('Descargar'),
			cell: payrollUser =>
				<div className='d-grid'>
					<select className='form-control form-select mb-3' value={payrollUser.monthSelected || ''} onChange={(e) => {setSelectedDate(payrollUser.id, e.target.value)}}>
						<option value={null}>{t('Fecha')}</option>
						{
							payrollUser.payrollMonths?.map(month => (
								<option key={month} value={`${month.split('/')[0]}-${month.split('/')[1]}`}>{month}</option>
							))
						}
					</select>
					<Link target='_blank' to={`/payroll/${payrollUser.id}/${payrollUser.monthSelected}`} className='btn btn-light-primary p-3'>
					{t('Ver')}
					</Link>
				</div>,
			sortable: false,
		}
	]

	useEffect(() => {
		if(auth){
			dispatch(getUsersPayrolls())
		}
	}, [auth, dispatch])

	useEffect(() => {
		if (usersPayrolls) {
			setUsersPayrolls(usersPayrolls)
		}
	}, [usersPayrolls])

	const setSelectedDate = (user, value) => {
		setUsersPayrolls(usersPayrollsState.map(userPayroll => {
			if (userPayroll.id === user) {
				return {...userPayroll, monthSelected: value}
			} else {
				return userPayroll
			}
		}))
	}

	return (
		<>
			<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
				<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
					<div className="page-title d-flex flex-column me-3">
						<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Listar nominas')}</h1>
						<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
							<li className="breadcrumb-item text-white opacity-75">
								<a href="/" className="text-white text-hover-primary">{t('Inicio')}</a>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white opacity-75">{t('Nominas')}</li>
						</ul>
					</div>
				</div>
			</div>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					<div className="card card-flush">
						<div className="card-body">
							{
								error &&
									<div className='text-danger'>{error}</div>
							}
							<div className="d-flex flex-stack">
								<div className="badge badge-lg badge-light-primary">
									<div className="d-flex align-items-center flex-wrap">
										<Link to={'#'}>{t('Nominas')}</Link>
										<span className="svg-icon svg-icon-2x svg-icon-primary mx-1">
											<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
												<path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="black" />
											</svg>
										</span>
									</div>
								</div>
							</div>
							<div className='table-responsive'>
								{usersPayrolls?.length > 0
									?
										<CustomDataTable columns={columns} data={usersPayrollsState} />
									:
										<></>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				loading &&
					<div className='modal' tabIndex={-1} style={{display: 'block'}}>
						<div className='modal-dialog'>
							<div className='modal-content'>
								<div className='modal-body text-center'>
									<span className='spinner-border align-middle ms-2' />
								</div>
							</div>
						</div>
					</div>
			}
		</>
	)
}