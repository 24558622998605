export const absenceTypeReducer = (state = {absenceTypes: null, absencetype: null}, action)=>{
    switch (action.type) {
        case 'ABSENCETYPE_GET_SUCCESS':
            return {
                ...state,
                absenceTypes: action.payload
            }
        case 'ABSENCETYPE_GET_DETAIL_SUCCESS':
            return {
                ...state,
                absencetype: action.payload
            }
        case 'ABSENCETYPE_SEND_SUCCESS':
            return {
                ...state,
                absenceTypes: [
                    ...state.absenceTypes,
                    action.payload
                ]
            }
        case 'ABSENCETYPE_EDITED_SUCCESS':
            return {
                ...state, absencetype: action.payload, absenceTypes: state.absenceTypes?.map(
                    absencetype => absencetype.id === action.payload.id
                    ?
                        action.payload
                    :
                        absencetype
                )
            }
        default:
            return state;
    }
}