import AxiosControl from '../hooks/AxiosControl'
import { t } from "i18next";
import Swal from "sweetalert2";
const Axios = AxiosControl()

export const getDepartments = () => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/department', {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'DEPARTMENT_GET_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const getDepartment = (department) => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/department/' + department, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'DEPARTMENT_GET_DETAIL_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const addDepartment = (data) => async (dispatch) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/department', {
            name: data.name,
            address: data.address,
            description: data.description,
            festives: data.festives,
            manager: data.manager && data.manager !== '' ? data.manager : null
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'DEPARTMENT_SEND_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const editDepartment = (data, department) => async (dispatch) => {
    try {
        const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/department/' + department, {
            name: data.name,
            address: data.address,
            description: data.description,
            festives: data.festives,
            manager: data.manager && data.manager !== '' ? data.manager : null
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'DEPARTMENT_EDITED_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const enableDepartment = (department) => async (dispatch) => {
    try {
        const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/department/' + department + '/enable', {}, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'DEPARTMENT_EDITED_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const disableDepartment = (department) => async (dispatch) => {
    try {
        const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/department/' + department + '/disable', {}, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'DEPARTMENT_EDITED_SUCCESS',
                payload: response.data
            }) 
        }
        if(response.status===203){
            Swal.fire({
                title: t(response.data.status),
                text: "",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: 'primary',
                confirmButtonText: t("De acuerdo"),
            })
        }
    } catch(error){
        console.log(error)
    }
}

export const assignUserDepartment = (data) => async (dispatch) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/department/assignUser', {
            department: data.department,
            user: data.user
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'DEPARTMENT_EDITED_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const unassignUserDepartment = (data) => async (dispatch) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/department/unassignUser', {
            department: data.department,
            user: data.user
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'DEPARTMENT_EDITED_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}