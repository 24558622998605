export const taskReducer = (state = {tasks: null, task: []}, action)=>{
    switch (action.type) {
        case 'TASK_GET_SUCCESS':
            return {
                ...state,
                tasks: action.payload
            }
        case 'TASK_GET_DETAIL_SUCCESS':
            return {
                ...state,
                task: action.payload
            }
        case 'TASK_SEND_SUCCESS':
            return {
                ...state,
                tasks: [
                    ...state.tasks,
                    action.payload
                ]
            }
        case 'TASK_EDITED_SUCCESS':
            return {
                ...state, task: action.payload, tasks: state.tasks?.map(
                    task => task.id === action.payload.id
                    ?
                        action.payload
                    :
                        task
                )
            }
        default:
            return state;
    }
}