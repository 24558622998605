import React from 'react'
import { Link } from 'react-router-dom';


export default function About() {

	return (
		<>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				{/*begin::Post*/}
				<div className="content flex-row-fluid mt-7" id="kt_content">
					<div className="row g-5 g-xl-10 mt-7">
						<div className="col-10 mx-auto">
							<div className="card card-flush h-xl-100" style={{background: '#1C325E'}}>
								{/*begin::Heading*/}
								<div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px" style={{backgroundImage: 'url("/assets/media/svg/shapes/top-green.png")'}}>
									<div className='row w-100 align-items-center'>
										<div className='col-10'>
											<h3 className="card-title align-items-start text-white pt-15">
												<span className="fw-bolder fs-2x mb-3">Acerca de la plataforma</span>
												<span className="badge badge-white ms-4">2.1</span>
											</h3>
											<div className="fs-4 text-white">
												<span className="opacity-75">Últimos cambios</span>
											</div>
										</div>
										<div className='col-2'>
											<Link className='btn btn-light' to={'/'}>
											<span className="svg-icon svg-icon-muted">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<path d="M14 18H9.60001V16H14C15.1 16 16 15.1 16 14V3C16 2.4 16.4 2 17 2C17.6 2 18 2.4 18 3V14C18 16.2 16.2 18 14 18Z" fill="black"/>
													<path opacity="0.3" d="M9.60002 12L5.3 16.3C4.9 16.7 4.9 17.3 5.3 17.7L9.60002 22V12Z" fill="black"/>
												</svg>
											</span>
												Inicio
											</Link>
										</div>
									</div>
								</div>
								<div className="card-body mt-n20">
									<div className='table-responsive'>
										<table className='bg-white table table-rounded border table-row-bordered table-striped gy-7 gs-7'>
											<thead>
												<tr className='fw-bolder fs-6 text-grey-800'>
													<th>Tipo</th>
													<th>Descripción</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Añadido</td>
													<td>Mejoras en el proceso de fichaje incluyendo geolocalización</td>
												</tr>
												<tr>
													<td>Añadido</td>
													<td>Mejoras en los informes para que aparezca la geolocalización</td>
												</tr>
												<tr>
													<td>Editado</td>
													<td>Cambio de versión de api Symfony a la versión 6</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}