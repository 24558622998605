import AxiosControl from '../hooks/AxiosControl'
import { getCheckInOutStatus } from "./checkrequestActions";
const Axios = AxiosControl()

export const getSheets = () => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/sheet', {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SHEET_GET_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const getSheet = (sheet) => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/sheet/' + sheet, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SHEET_GET_DETAIL_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const getDatesByUser = (user) => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/sheet/userSheetsDates/' + user, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SHEET_GET_DATES',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const userSheetsByDate = (data, user) => async (dispatch) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/sheet/userSheetsByDate/' + user, {
            date: data.date
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SHEET_GET_SUCCESS',
                payload: response.data.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const getSheetCorrected = (user) => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/sheet/corrected/' + user, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SHEET_GET_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const acceptSheetCorrected = (data) => async (dispatch) => {
    try {
        await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/sheets/acceptcorrection', {
            sheets: data.sheets
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
    } catch(error){
        console.log(error)
    }
}

export const denySheetCorrected = (data) => async (dispatch) => {
    try {
        await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/sheets/denycorrection', {
            sheets: data.sheets,
            comment: data.comment
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
    } catch(error){
        console.log(error)
    }
}

export const checkIn = (data, checkinoutdata) => async (dispatch) => {
    try {
        await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/sheet/checkInOut', {
            username: data.username,
            task: data.task,
            latitude: data.latitude,
            longitude: data.longitude
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        dispatch(getCheckInOutStatus(checkinoutdata))
    } catch(error){
        console.log(error)
    }
}

export const correctSheet = (data, user) => async (dispatch) => {
    try {
        await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/sheet/correct/' + user, {
            sheets: data.sheets,
            deleted: data.deleted,
            date: data.date,
            supervisor: data.supervisor
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
    } catch(error){
        console.log(error)
    }
}

export const checkUserSheetsAmongDates = (data, user) => async (dispatch) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/sheet/userSheetsAmongDates/' + user, {
            start: data.start,
            end: data.end
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SHEET_GET_SUCCESS',
                payload: response.data.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}