import React from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import DepartmentAssignUser from '../modals/DepartmentAssignUser'
import DepartmentRemoveUser from '../modals/DepartmentRemoveUser'
import EditDepartment from '../modals/EditDepartment'
import { useTranslation } from 'react-i18next'
import { getDepartment } from '../../actions/departmentActions'
import useAuth from '../../hooks/UseAuth'
import { useEffect } from 'react'
import { CustomDataTable } from '../../components/datatables/CustomDataTable'

export default function Department() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {auth} = useAuth()
	const {id} = useParams()
	const {department} = useSelector(state => state.departments)

	useEffect(() => {
		if(auth){
			dispatch(getDepartment(id))
		}
	}, [auth, dispatch, id])

	const columns = [
		{
			name: t('Usuario'),
			cell: user =>
				<div className="d-flex align-items-center">
					<div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
						<Link to={`/user/${user?.id}`}>
						<div className="symbol-label fs-3 bg-light-danger text-danger">{user.username?.charAt(0)}</div>
						</Link>
					</div>
					<div className="d-flex flex-column">
						<Link to={`/user/${user?.id}`} className="text-gray-800 text-hover-primary mb-1">
							{user.username}
						</Link>
					</div>
				</div>,
			selector: (user) => user.username,
			sortable: true,
		},{
			name: t('Nombre y apellidos'),
			cell: user =>
				<div>
					{
						user.firstName 
						? user.firstName + ' '
						: ''
					}
					{
						user.lastName 
						? user.lastName
						: ''
					}
				</div>,
			selector: (user) => user.firstName+' '+user.lastName,
			sortable: true,
		},{
			name: t('Empleo'),
			cell: user =>
				<div>
					{user.employ}
				</div>,
			selector: (user) => user.employ,
			sortable: true,
		},{
			name: t('Roles'),
			cell: user =>
				<div>
					{user?.roles
						? 
							user.roles.map((role, y) => 
								role==='ROLE_ADMIN' 
									? 
										<div className="badge badge-light-danger fw-bolder mb-1 me-1" key={y}>{role}</div>
									: 
										<div className="badge badge-light-success fw-bolder mb-1 me-1" key={y}>{role}</div>
							)
						: 
							<></>
					}
				</div>,
			selector: (user) => user.roles,
			sortable: true,
		},{
			name: '',
			cell: user =>
				<div>
					<DepartmentRemoveUser userId={user.id} />
				</div>,
			selector: (user) => user.id,
			sortable: true,
		}
	]

  return (
    <>
		<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
			<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
				<div className="page-title d-flex flex-column me-3">
					<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Departamento')}</h1>
					<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
						<li className="breadcrumb-item text-white opacity-75">
							<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
						</li>
						<li className="breadcrumb-item">
							<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
						</li>
						<li className="breadcrumb-item text-white opacity-75">
							<Link to='/department/list' className="text-white text-hover-primary">{t('Listar Departamentos')}</Link>
						</li>
						<li className="breadcrumb-item">
							<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
						</li>
						<li className="breadcrumb-item text-white">{t('Departamento')}</li>
					</ul>
				</div>
			</div>
		</div>
		<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
			<div className="content flex-row-fluid" id="kt_content">
				<form id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row" data-kt-redirect="../../demo2/dist/apps/ecommerce/catalog/categories.html">
					<div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
						<div className="card card-flush py-4">
							<div className="card-header">
								<div className="card-title">
									<h2>{department?.name}</h2>
								</div>
							</div>
							<div className="card-body pt-0">
								{department?.isActive 
									? 
										<></>
									: 
										<div className="notice d-flex bg-light-danger rounded border-danger border border-dashed mb-9 p-6">
											<span className="svg-icon svg-icon-2tx svg-icon-danger me-4">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
													<rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"/>
													<rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
												</svg>
											</span>
											<div className="d-flex flex-stack flex-grow-1">
												<div className="fw-bold">
													<div className="fs-4 text-gray-900">{t('Departamento desactivado')}.</div>
													<div className="fs-6 text-gray-700">{t('Este departamento no se mostrará a los usuarios')}.</div>
												</div>
											</div>
										</div>
								}
								<div className="d-flex flex-stack fs-4 py-3">
								<div className="fw-bolder rotate collapsible" data-bs-toggle="collapse" href="#kt_user_view_details" role="button" aria-expanded="false" aria-controls="kt_user_view_details">{t('Detalles')}
									<span className="ms-2 rotate-180">
									<span className="svg-icon svg-icon-3">
										<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
										<path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
										</svg>
									</span>
									</span></div>
								<span data-bs-toggle="tooltip" data-bs-trigger="hover" title="Edit customer details">
									<EditDepartment department={department} />
								</span>
								</div>
								<div className="separator" />
								<div id="kt_user_view_details" className="collapse show">
								<div className="pb-5 fs-6">
									
									{department?.description !== null
									? <>
										<div className="fw-bolder mt-5">{t('Descripción')}</div>
										<div className="text-gray-600">{department?.description}</div>
									</>
									: ''}

									{department?.address !== null
									? <>
										<div className="fw-bolder mt-5">{t('Dirección')}</div>
										<div className="text-gray-600">{department?.address}</div>
									</>
									: ''}
									{
										department?.manager
										?
											<>
												<div className="fw-bolder mt-5">{t('Responsable')}</div>
												<div className="text-gray-600">{department?.manager.lastName}, {department.manager.firstName}</div>
											</>
										:
											<></>
									}
								</div>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
						<div className="card card-flush py-4">
							<div className="card-header border-0 pt-6">
								<div className="card-title">
									<h2>{t('Usuarios')}</h2>
								</div>
								<div className="card-toolbar">
									<div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
										<DepartmentAssignUser department={department} />
									</div>
								</div>
							</div>
							<div className="card-body pt-0">
								<div className="mb-10 fv-row">
									<label className="form-label">{t('Usuarios que pertenecen al departamento')}<div className="separator my-5" /></label>
									<div className='table-responsive'>
										{department?.users?.length > 0
											?
												<CustomDataTable columns={columns} data={department.users} />
											:
												<></>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
    </>
  )
}
