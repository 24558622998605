import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/UseAuth';
import { CustomDataTable } from '../../components/datatables/CustomDataTable';
import { getPayrolls } from '../../actions/payrollActions';
import { getUser } from '../../actions/userActions';

export default function DownloadPayroll(props) {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const {id, month} = useParams() // Id del usuario y mes seleccionado

	const {auth} = useAuth()
	const {payrolls, loading, error} = useSelector(state => state.payrolls)
	const {user} = useSelector(state => state.users)


	const columns = [
		{
			name: t('Nombre'),
			cell: payroll =>
				<div className="d-flex align-items-center">
					<span className="text-gray-800 text-hover-primary">{payroll.title}</span>
				</div>,
			selector: (payroll) => payroll.title,
			sortable: true,
		},{
			name: t('Mes/Año'),
			cell: payroll =>
				<div>
					{payroll.monthyear}
				</div>,
			selector: (payroll) => payroll.monthyear,
			sortable: true,
		},{
			name: '',
			cell: payroll =>
				<div>
					<a target='_blank' rel='noreferrer' href={payroll.route} download className='btn btn-light-primary p-3'>
					<span className="svg-icon svg-icon-muted svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
						<rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor"/>
						<path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor"/>
						</svg>
					</span>
					{t('Descargar')}
					</a>
				</div>,
			selector: (payroll) => payroll.route,
			sortable: true,
		}
	]

	useEffect(() => {
		if(auth){
			dispatch(getPayrolls(id, `${month.split('-')[0]}/${month.split('-')[1]}`))
			dispatch(getUser(id))
		}
	}, [auth, id, month, dispatch])

	return (
		<>
			<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
				<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
					<div className="page-title d-flex flex-column me-3">
						<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Descargar nominas')}</h1>
						<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
							<li className="breadcrumb-item text-white opacity-75">
								<a href="/" className="text-white text-hover-primary">{t('Inicio')}</a>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white opacity-75">
								<Link className='text-white text-hover-primary' to="/payroll/list">{t('Nominas')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white opacity-75">
								<span className="text-white text-hover-primary">{t('Descargar nominas')}</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					<div className="card card-flush">
						<div className="card-body">
							{
								error &&
									<div className='text-danger'>{error}</div>
							}
							<div className="d-flex flex-stack">
								<h2 >{t('Nominas del usuario ')} {user?.lastName}, {user?.firstName}</h2>
							</div>
							<div className='table-responsive'>
								{payrolls?.length > 0
									?
										<CustomDataTable columns={columns} data={payrolls} />
									:
										<></>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				loading &&
					<div className='modal' tabIndex={-1} style={{display: 'block'}}>
						<div className='modal-dialog'>
							<div className='modal-content'>
								<div className='modal-body text-center'>
									<span className='spinner-border align-middle ms-2' />
								</div>
							</div>
						</div>
					</div>
			}
		</>
	)
}