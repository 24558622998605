import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getSchedulesManaged } from '../../actions/scheduleActions'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/UseAuth'
import { Accordion } from 'react-bootstrap'

export default function InfoSchedules() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {auth} = useAuth()

	const {schedules} = useSelector(state => state.schedules)

	useEffect(() => {
		if(auth){
			dispatch(getSchedulesManaged(auth?.user))
		}
	}, [auth, dispatch])

	const columns = [
		{
			name: t('Día de la semana'),
			cell: schedule =>
				<div>
					{t(schedule.day)}
				</div>,
			selector: (schedule) => schedule.day,
			sortable: true,
		},{
			name: t('Hora de entrada'),
			cell: schedule =>
				<div>
					{schedule.checkInTime}
				</div>,
			selector: (schedule) => schedule.checkInTime,
			sortable: true,
		},{
			name: t('Hora de salida'),
			cell: schedule =>
				<div>
					{schedule.checkOutTime}
				</div>,
			selector: (schedule) => schedule.checkOutTime,
			sortable: true,
		},{
			name: t('Fecha de inicio'),
			cell: schedule =>
				<div>
					{schedule.startDateFormated}
				</div>,
			selector: (schedule) => schedule.startDateFormated,
			sortable: true,
		},{
			name: t('Fecha de fin'),
			cell: schedule =>
				<div>
					{schedule.endDateFormated}
				</div>,
			selector: (schedule) => schedule.endDateFormated,
			sortable: true,
		}
	]

return (
	<>
	<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
		<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
			<div className="page-title d-flex flex-column me-3">
				<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Información de horarios')}</h1>
				<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
					<li className="breadcrumb-item text-white opacity-75">
						<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
					</li>
					<li className="breadcrumb-item">
						<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
					</li>
					<li className="breadcrumb-item text-white">{t('Información de horarios')}</li>
				</ul>
			</div>
		</div>
	</div>
	<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
		<div className="content flex-row-fluid" id="kt_content">
			<div className="card">
				<div className="card-body py-4">
					<div className='table-responsive'>
						<Accordion>
							{schedules?.map((userSchedule, index) => (
								<Accordion.Item eventKey={index} key={index}>
									<Accordion.Header>
										{userSchedule.lastName}, {userSchedule.firstName}
									</Accordion.Header>
									<Accordion.Body>
										<table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
											<thead>
												<tr>
													<th><strong>{t('Día de la semana')}</strong></th>
													<th><strong>{t('Hora de entrada')}</strong></th>
													<th><strong>{t('Hora de salida')}</strong></th>
													<th><strong>{t('Fecha de inicio')}</strong></th>
													<th><strong>{t('Fecha de fin')}</strong></th>
												</tr>
											</thead>
											<tbody>
												{
													userSchedule.schedules.map((schedule, index) => (
														<tr key={index}>
															<td>{t(schedule.day)}</td>
															<td>{schedule.checkInTime}</td>
															<td>{schedule.checkOutTime}</td>
															<td>{schedule.startDate}</td>
															<td>{schedule.endDate}</td>
														</tr>
													))
												}
											</tbody>
										</table>
									</Accordion.Body>
								</Accordion.Item>
							))}
						</Accordion>
					</div>
				</div>
			</div>
		</div>
	</div>
	</>
)
}