import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SuccessPopup } from '../../components/popups/Popups';
import { useTranslation } from 'react-i18next'
import { addVacation, getVacationsUser } from '../../actions/vacationActions';
import { getUsers, getUserSupervisor } from '../../actions/userActions';
import useAuth from '../../hooks/UseAuth';

export default function CreateVacation() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {auth} = useAuth()

  const {register, handleSubmit, watch, clearErrors, formState:{errors}} = useForm()
  const [errMsg, setErrMsg] = useState('')

  const [vacationDaysLeft, setVacationDaysLeft] = useState('')

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = (e) => {
	e.preventDefault()
	clearErrors()
	setErrMsg('')
	setShow(false)
  }

  const {users} = useSelector(state => state.users)
  const today = new Date().toISOString().split('T')[0]

  const data = {
	// description: watch('description'),
	description: '',
	date: watch('date'),
	user: watch('user'),
	accepted: true
  }

	useEffect(() => {
		if (auth?.user.roles?.indexOf('ROLE_ADMIN') !== -1) {
			dispatch(getUsers())
		} else {
			dispatch(getUserSupervisor())
		}
	}, [dispatch, auth])

  useEffect(() => {
	if (users) {
		let userSelected = users[0]
		if (data.user) {
			userSelected = users?.find(u => u.id === parseInt(data.user))
		}
		setVacationDaysLeft(parseInt(userSelected?.vacationDays) - (parseInt(userSelected?.vacationAccepted) + parseInt(userSelected?.vacationRequested)))
	}
	
  }, [data.user, users])

  const CreateAbsence = async() => {
	if (vacationDaysLeft > 0) {
		dispatch(addVacation(data))
		if (auth?.user.roles?.indexOf('ROLE_ADMIN') !== -1) {
				dispatch(getUsers())
			} else {
				dispatch(getUserSupervisor())
			}
		setErrMsg('')
		setShow(false)
		SuccessPopup(`Vacación asignada correctamente!`)
	}
  }

  return (
	<>
	  <Button variant="primary" onClick={handleShow}>
		{/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
		<span className="svg-icon svg-icon-2 me-3">
		  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
			<rect opacity="0.5" x="11.364" y="20.364" width={16} height={2} rx={1} transform="rotate(-90 11.364 20.364)" fill="black" />
			<rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
		  </svg>
		</span>
		{/*end::Svg Icon*/}
		{t('Asignar Vacaciones')}
	  </Button>

	  <Modal show={show} size='lg' backdrop='static' >
		<Modal.Header closeButton onClick={handleClose} >
		  <Modal.Title className='fw-bolder fs-2'>{t('Asignar Vacaciones')}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
		  {/*begin::Modal body*/}
		  <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
			{/*begin::Form*/}
			<form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(CreateAbsence)}>
			  {/*begin::Scroll*/}
			  <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
				{/*begin::Input group*/}
				<div className="fv-row mb-7">
				  {/*begin::Label*/}
				  <label className="required fw-bold fs-6 mb-2">{t('Usuario')}</label>
				  {/*end::Label*/}
				  {/*begin::Select*/}
				  <select className="form-select form-select-solid" placeholder='Selecciona el Usuario' {...register("user", { required: true })} >
					  {users?.length
						? (
						  users.map((user, i) => 
							user.isActive === true ? 
							  /* TODO:: Si el usuario ya pertenece al departamento no mostrarlo */
							  <option value={user.id} key={i}>{user.username}</option>
							  : ''
						  )
						) : <option disabled>{t('No existen usuarios')}</option>
					  }
				  </select>
				  {
					vacationDaysLeft && vacationDaysLeft !== ''
						?
							<span>{vacationDaysLeft} {t('días de vacaciones disponibles')}</span>
						:
							<></>
				  }
				  {/*end::Select*/}
				  {/* <!--begin::Input error message--> */}
				  <div className="fv-plugins-message-container invalid-feedback">
					<div data-field="name" data-validator="notEmpty">{errors.name?.message}</div>
				  </div>
				  {/* <!--end::Input error message--> */}
				</div>
				{/*end::Input group*/}
				{/*begin::Input group*/}
				
				{/* <div className="fv-row mb-7">
				  <label className="required fw-bold fs-6 mb-2">{t('Descripción')}</label>
				  <input type="text" name="description" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Descripcion de las vacaciones" {...register(
					'description', {
					  required: 'Este campo es obligatorio'
					}
					)} />
				</div> */}

				{/*end::Input group*/}
				{/*begin::Input group*/}
				<div className="fv-row mb-7">
				  {/*begin::Label*/}
				  <label className="required fw-bold fs-6 mb-2">{t('Fecha')}</label>
				  {/*end::Label*/}
				  {/*begin::Input*/}
				  <input type="date" name="date" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Fecha" min={today} {...register(
					'date', {
					  required: 'Este campo es obligatorio'
					  }
					)} />
				  {/*end::Input*/}
				  {/* <!--begin::Input error message--> */}
				  <div className="fv-plugins-message-container invalid-feedback">
					<div data-field="startDate" data-validator="notEmpty">{errors.startDate?.message}</div>
				  </div>
				  {/* <!--end::Input error message--> */}
				</div>
				{/*end::Input group*/}
				{/* <!--begin::Api error message--> */}
				<div className="fv-plugins-message-container invalid-feedback">
				  <div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
				</div>
				{/* <!--end::Api error message--> */}
			  </div>
			  {/*end::Scroll*/}
			  {/*begin::Actions*/}
			  <div className="text-center pt-15">
				<button className="btn btn-light me-3" onClick={handleClose}>{t('Cancelar')}</button>
				<button className="btn btn-primary">
				  <span className="indicator-label">{t('Enviar')}</span>
				  <span className="indicator-progress">{t('Porfavor espere...')}
					<span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
				</button>
			  </div>
			  {/*end::Actions*/}
			</form>
			{/*end::Form*/}
		  </div>
		  {/*end::Modal body*/}
		</Modal.Body>
	  </Modal>
	</>
  );
}
