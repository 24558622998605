import React from 'react'
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { unassignUserDepartment } from '../../actions/departmentActions';
import { SuccessPopup } from '../../components/popups/Popups';
import { useTranslation } from 'react-i18next'
import { getUser } from '../../actions/userActions';

export default function DepartmentRemoveUser(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  let { id } = useParams()

  if (props.departmentId){
	id = props.departmentId
  }
  
  const data = {
	user: props.userId,
	department: id
  }

  const Remove = async() => {
	dispatch(unassignUserDepartment(data))
	SuccessPopup(`Usuario actualizado correctamente!`)
	dispatch(getUser(props.userId))
  }

  return (
	<>
	  <div className="menu-item px-3">
		<Link to={'#'} className="menu-link px-3 btn btn-light-danger" data-kt-users-table-filter="delete_row" onClick={Remove}>
			<span className="svg-icon svg-icon-muted svg-icon-2x m-0">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
					<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"/>
					<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"/>
				</svg>
			</span>
		</Link>
	  </div>
	</>
  );
}
