export const documentReducer = (state = {documents: [], document: null, download: null}, action)=>{
	switch (action.type) {
		case 'DOCUMENT_GET_SUCCESS':
			return {
				...state,
				documents: action.payload
			}
		case 'DOCUMENT_SEND_SUCCESS':
			return {
				...state, 
				documents: [
					...state.documents,
					action.payload
				]
			}
		case 'DOCUMENT_GET_DOWNLOAD_SUCCESS':
			return {
				...state,
				download: action.payload
			}
		case 'DOCUMENT_DELETE_SUCCESS':
			return {
				...state,
				documents: state.documents?.filter(d => d.id !== action.payload)
			}
		default:
			return state;
	}
}