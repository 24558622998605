import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import useAuth from '../hooks/UseAuth'
import { useTranslation } from 'react-i18next';
import { getPayrolls } from '../actions/payrollActions';
import { CustomDataTable } from '../components/datatables/CustomDataTable';

export default function Payroll() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const {auth} = useAuth()
	const {payrolls} = useSelector(state => state.payrolls)

  useEffect(() => {
    if(auth){
      dispatch(getPayrolls(auth?.user))
    }
  }, [auth, dispatch])

	const columns = [
		{
			name: t('Nombre'),
			cell: payroll =>
				<div className="d-flex align-items-center">
					<span className="svg-icon svg-icon-2x svg-icon-primary me-4">
						<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
							<path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z" fill="black" />
							<path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
						</svg>
					</span>
					<a href={payroll.route} download className="text-gray-800 text-hover-primary">{payroll.title}</a>
				</div>,
			selector: (payroll) => payroll.title,
			sortable: true,
		},{
			name: t('Mes/Año'),
			cell: payroll =>
				<div>
					{payroll.monthyear}
				</div>,
			selector: (payroll) => payroll.monthyear,
			sortable: true,
		},{
			name: '',
			cell: payroll =>
				<div>
					<a href={payroll.route} download className='btn btn-light-primary p-3'>
					<span className="svg-icon svg-icon-muted svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
						<rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor"/>
						<path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor"/>
						</svg>
					</span>
					{t('Descargar')}
					</a>
				</div>,
			selector: (payroll) => payroll.route,
			sortable: true,
		}
	]

	return (
		<>
			<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
				<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
					<div className="page-title d-flex flex-column me-3">
						<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Listar nominas')}</h1>
						<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
							<li className="breadcrumb-item text-white opacity-75">
								<a href="/" className="text-white text-hover-primary">{t('Inicio')}</a>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white opacity-75">{t('Nominas')}</li>
						</ul>
					</div>
				</div>
			</div>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					<div className="card card-flush">
						<div className="card-body">
							<div className="d-flex flex-stack">
								<div className="badge badge-lg badge-light-primary">
									<div className="d-flex align-items-center flex-wrap">
										<Link to={'#'}>{t('Nominas')}</Link>
										<span className="svg-icon svg-icon-2x svg-icon-primary mx-1">
											<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
												<path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="black" />
											</svg>
										</span>
									</div>
								</div>
							</div>
							<div className='table-responsive'>
								{payrolls?.length > 0
									?
										<CustomDataTable columns={columns} data={payrolls} />
									:
										<></>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}