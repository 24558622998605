import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUser, updateAvatar } from '../actions/userActions';
import { SuccessPopup } from '../components/popups/Popups';
import useAuth from '../hooks/UseAuth'
import EditUser from './modals/EditUser';
import { useTranslation } from 'react-i18next';
import EditUserPassword from './modals/EditUserPassword';

export default function Profile() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const {auth} = useAuth()
	const {user} = useSelector(state => state.users)

	const {handleSubmit, register, watch, setValue, formState:{errors}} = useForm()

	const [show, setShow] = useState(false)
	const [showEdit, setShowEdit] = useState(false)

	const data = {
		file: watch('file')
	}

	const handleShow = () => setShow(true)
	const handleClose = (e) => {
		e.preventDefault()
		setShow(false)
		setValue('file', null)
	}

	const ChangeAvatar= async()=>{
		dispatch(updateAvatar(data.file[0], auth?.user))
		setShow(false)
		setValue('file', null)
		SuccessPopup(`Avatar modificado correctamente!`)
	}

	const handleShowEdit = () => setShowEdit(true)
	
	const handleCloseEdit = (e) => {
		e.preventDefault()
		setShowEdit(false)
	}

	useEffect(()=>{
		if(auth?.user){
			dispatch(getUser(auth.user, true))
		}
	}, [auth, dispatch])

	return (
		<>
		{/*begin::Toolbar*/}
		<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
			{/*begin::Container*/}
			<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
			{/*begin::Page title*/}
			<div className="page-title d-flex flex-column me-3">
				{/*begin::Title*/}
				<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Perfil')}</h1>
				{/*end::Title*/}
				{/*begin::Breadcrumb*/}
				<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
				{/*begin::Item*/}
				<li className="breadcrumb-item text-white opacity-75">
					<a href="/" className="text-white text-hover-primary">{t('Inicio')}</a>
				</li>
				{/*end::Item*/}
				{/*begin::Item*/}
				<li className="breadcrumb-item">
					<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
				</li>
				{/*end::Item*/}
				{/*begin::Item*/}
				<li className="breadcrumb-item text-white opacity-75">{t('Perfil')}</li>
				{/*end::Item*/}
				</ul>
				{/*end::Breadcrumb*/}
			</div>
			{/*end::Page title*/}
			</div>
			{/*end::Container*/}
		</div>
		{/*end::Toolbar*/}
		{/*begin::Container*/}
		<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
			{/*begin::Post*/}
			<div className="content flex-row-fluid" id="kt_content">
			{/*begin::Navbar*/}
			<div className="card mb-5 mb-xxl-8">
				<div className="card-body pt-9 pb-0">
				{/*begin::Details*/}
				<div className="d-flex flex-wrap flex-sm-nowrap">
					{/*begin::Image input*/}
					<div className="image-input image-input-outline me-7 mb-4">
					{/*begin::Preview existing avatar*/}
					{user?.imageProfile
						?
							<div className="image-input-wrapper w-175px h-175px" style={{backgroundImage: `url(${user?.imageProfile})`}} />
						:
							<></>

					}
					{/*end::Preview existing avatar*/}
					{/*begin::Label*/}
					<label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" onClick={handleShow}>
						<i className="bi bi-pencil-fill fs-7" />
					</label>
					{/*end::Label*/}
					</div>
					{/*end::Image input*/}

					{/*begin::Info*/}
					<div className="flex-grow-1">
					{/*begin::Title*/}
					<div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
						{/*begin::User*/}
						<div className="d-flex flex-column">
						{/*begin::Name*/}
						<div className="d-flex align-items-center mb-2">
							<div className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">{`${user?.firstName} ${user?.lastName}`}</div>
							{ auth.roles?.includes('ROLE_ADMIN')
							? <span className="badge badge-light-danger fw-bolder fs-8 px-2 py-1 ms-2">Admin</span> 
							: ''
							}
						</div>
						{/*end::Name*/}
						{/*begin::Info*/}
						<div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
							{/* begin::employ */}
							{ user?.employ === null || user?.employ === undefined
							? ''
							: <Link to={'#'} className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
								{/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}
								<span className="svg-icon svg-icon-4 me-1">
								<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
									<path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="black" />
									<path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="black" />
								</svg>
								</span>
								{/*end::Svg Icon*/}
							{user?.employ}
							</Link>
							}
							{/* end::employ */}
							{/* begin::address */}
							{ user?.address === null || user?.address === undefined 
							? ''
							: <Link to={'#'} className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
								{/*begin::Svg Icon | path: icons/duotune/communication/com011.svg*/}
								<span className="svg-icon svg-icon-4 me-1">
								<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
									<path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="black" />
									<path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="black" />
								</svg>
								</span>
								{/*end::Svg Icon*/}
							{user?.address}
							</Link>
							}
							{/* end::address */}
							{/* begin::email */}
							{ user?.email === null || user?.email === undefined 
							? ''
							: <a href={`mailto:${user?.email}`} className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
								{/*begin::Svg Icon | path: icons/duotune/communication/com011.svg*/}
								<span className="svg-icon svg-icon-4 me-1">
								<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
									<path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="black" />
									<path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="black" />
								</svg>
								</span>
								{/*end::Svg Icon*/}
							{user?.email}
							</a>
							}
							{/* end::email */}
						</div>
						{/*end::Info*/}
						</div>
						{/*end::User*/}
					</div>
					{/*end::Title*/}
					</div>
					{/*end::Info*/}
				</div>
				{/*end::Details*/}
				{
					user?.isActive ?
					''
					:
					<div className="notice d-flex bg-light-danger rounded border-danger border border-dashed mb-9 p-6">
						{/*begin::Icon*/}
						{/*begin::Svg Icon | path: icons/duotune/art/art006.svg*/}
						<span className="svg-icon svg-icon-2tx svg-icon-danger me-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
						<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
						<rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"/>
						<rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
						</svg></span>
						{/*end::Svg Icon*/}
						{/*end::Icon*/}
						{/*begin::Wrapper*/}
						<div className="d-flex flex-stack flex-grow-1">
						{/*begin::Content*/}
						<div className="fw-bold">
							<div className="fs-4 text-gray-900">{t('Usuario desactivado')}.</div>
							<div className="fs-6 text-gray-700">{t('Este usuario no tiene acceso a la aplicación')}.</div>
						</div>
						{/*end::Content*/}
						</div>
						{/*end::Wrapper*/}
					</div>  
				}
				{/*begin:::Tabs*/}
				<ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder mt-5">
					{/*begin:::Tab item*/}
					<li className="nav-item">
					<a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#kt_user_view_overview_tab">{t('Resumen')}</a>
					</li>
					{/*end:::Tab item*/}
					{/*begin:::Tab item*/}
					<li className="nav-item">
					<a className="nav-link text-active-primary pb-4" data-kt-countup-tabs="true" data-bs-toggle="tab" href="#kt_user_view_security">{t('Seguridad')}</a>
					</li>
					{/*end:::Tab item*/}
				</ul>
				{/*end:::Tabs*/}
				</div>
			</div>
			{/*end::Navbar*/}
			{/*begin:::Tab content*/}
			<div className="tab-content" id="myTabContent">
				{/*begin:::Tab pane*/}
				<div className="tab-pane fade show active" id="kt_user_view_overview_tab" role="tabpanel">
				{/*begin::details View*/}
				<div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
					{/*begin::Card header*/}
					<div className="card-header cursor-pointer">
					{/*begin::Card title*/}
					<div className="card-title">
						<h2>{t('Detalles del usuario')}</h2>
					</div>
					{/*end::Card title*/}
					{/*begin::Action*/}
					<Button variant="primary" onClick={handleShowEdit} className='btn btn-primary align-self-center'>
						{t('Editar')}
					</Button>
					<Modal show={showEdit} size='lg' backdrop='static' >
						<Modal.Header closeButton onClick={handleCloseEdit} >
							<Modal.Title className='fw-bolder fs-2'>{t('Editar Usuario')}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<EditUser user={user} supervisor={false} handleClose={handleCloseEdit} customClassName='btn btn-primary align-self-center' />
						</Modal.Body>
					</Modal>
					{/*end::Action*/}
					</div>
					{/*begin::Card header*/}
					{/*begin::Card body*/}
					<div className="card-body p-9">
					{/*begin::Row*/}
					<div className="row mb-7">
						{/*begin::Label*/}
						<label className="col-lg-4 fw-bold text-muted">{t('Nombre y apellidos')}</label>
						{/*end::Label*/}
						{/*begin::Col*/}
						<div className="col-lg-8">
						<span className="fw-bolder fs-6 text-gray-800">{user?.firstName} {user?.lastName}</span>
						</div>
						{/*end::Col*/}
					</div>
					{/*end::Row*/}
					{/*begin::Input group*/}
					<div className="row mb-7">
						{/*begin::Label*/}
						<label className="col-lg-4 fw-bold text-muted">{t('Empleo')}</label>
						{/*end::Label*/}
						{/*begin::Col*/}
						<div className="col-lg-8 fv-row">
						<span className="fw-bold text-gray-800 fs-6">{user?.employ}</span>
						</div>
						{/*end::Col*/}
					</div>
					{/*end::Input group*/}
					{/*begin::Input group*/}
					<div className="row mb-7">
						{/*begin::Label*/}
						<label className="col-lg-4 fw-bold text-muted">{t('Telefono')}
						<i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active" /></label>
						{/*end::Label*/}
						{/*begin::Col*/}
						<div className="col-lg-8 d-flex align-items-center">
						<span className="fw-bolder fs-6 text-gray-800 me-2">{user?.phone}</span>
						<span className="badge badge-success"></span>
						</div>
						{/*end::Col*/}
					</div>
					{/*end::Input group*/}
					{/*begin::Input group*/}
					<div className="row mb-7">
						{/*begin::Label*/}
						<label className="col-lg-4 fw-bold text-muted">{t('Email')}</label>
						{/*end::Label*/}
						{/*begin::Col*/}
						<div className="col-lg-8">
						<div className="fw-bold fs-6 text-gray-800">{user?.email}</div>
						</div>
						{/*end::Col*/}
					</div>
					{/*end::Input group*/}
					{/*begin::Input group*/}
					<div className="row mb-7">
						{/*begin::Label*/}
						<label className="col-lg-4 fw-bold text-muted">{t('Dirección')}</label>
						{/*end::Label*/}
						{/*begin::Col*/}
						<div className="col-lg-8">
						<span className="fw-bolder fs-6 text-gray-800">{user?.address}</span>
						</div>
						{/*end::Col*/}
					</div>
					{/*end::Input group*/}
					{/*begin::Input group*/}
					<div className="row mb-7">
						{/*begin::Label*/}
						<label className="col-lg-4 fw-bold text-muted">{t('Genero')}</label>
						{/*end::Label*/}
						{/*begin::Col*/}
						<div className="col-lg-8">
						<span className="fw-bolder fs-6 text-gray-800">{user?.gender}</span>
						</div>
						{/*end::Col*/}
					</div>
					{/*end::Input group*/}
					{/*begin::Input group*/}
					<div className="row mb-10">
						{/*begin::Label*/}
						<label className="col-lg-4 fw-bold text-muted">{t('Fecha nacimiento')}</label>
						{/*begin::Label*/}
						{/*begin::Label*/}
						<div className="col-lg-8">
						<span className="fw-bold fs-6 text-gray-800">{user?.birthDateFormatted}</span>
						</div>
						{/*begin::Label*/}
					</div>
					{/*end::Input group*/}
					</div>
					{/*end::Card body*/}
				</div>
				{/*end::details View*/}
				</div>
				{/*end:::Tab pane*/}
				{/*begin:::Tab pane*/}
				<div className="tab-pane fade" id="kt_user_view_security" role="tabpanel">
				{/*begin::Card*/}
				<div className="card pt-4 mb-6 mb-xl-9">
					{/*begin::Card header*/}
					<div className="card-header border-0">
					{/*begin::Card title*/}
					<div className="card-title">
						<h2>{t('Ajustes')}</h2>
					</div>
					{/*end::Card title*/} 
					</div>
					{/*end::Card header*/}
					{/*begin::Card body*/}
					<div className="card-body pt-0 pb-5">
					{/*begin::Table wrapper*/}
					<div className="table-responsive">
						{/*begin::Table*/}
						<div className='table-responsive'>
						<table className="table align-middle table-row-dashed gy-5" id="kt_table_users_login_session">
							{/*begin::Table body*/}
							<tbody className="fs-6 fw-bold text-gray-600">
							<tr>
								<td>{t('Usuario')}</td>
								<td>{user?.username}</td>
								<td className="text-end">
								{/* <button type="button" className="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" data-bs-toggle="modal" data-bs-target="#kt_modal_update_email">
									<span className="svg-icon svg-icon-3">
									<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
										<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black" />
										// <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black" />
									</svg>
									</span>
								</button> */}
								</td>
							</tr>
							<tr>
								<td>{t('Contraseña')}</td>
								<td>******</td>
								<td className="text-end">
								<EditUserPassword id={user?.id} />
								</td>
							</tr>
							</tbody>
							{/*end::Table body*/}
						</table>
						</div>
						{/*end::Table*/}
					</div>
					{/*end::Table wrapper*/}
					</div>
					{/*end::Card body*/}
				</div>
				{/*end::Card*/}
				</div>
				{/*end:::Tab pane*/}
			</div>
			{/*end:::Tab content*/}
			</div>
			{/*end::Post*/}
		</div>
		{/*end::Container*/}
		<Modal show={show} size='lg' backdrop='static' >
			<Modal.Header closeButton onClick={handleClose} >
			<Modal.Title className='fw-bolder fs-2'>{t('Cambiar avatar')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			{/*begin::Modal body*/}
			<div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
				{/*begin::Form*/}
				<form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(ChangeAvatar)}>
				{/*begin::Scroll*/}
				<div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
					{/*begin::Input group*/}
					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2">{t('Avatar')}</label>
						<input className="form-control" required={true} type="file" id="imageFile" accept="image/png, image/gif, image/jpeg" {...register(
							'file', {
							required: 'Selecciona un archivo'
							}
						)} />
						{/* <!--begin::Input error message--> */}
						<div className="fv-plugins-message-container invalid-feedback">
							<div data-field="username" data-validator="notEmpty">{errors.avatar?.message}</div>
						</div>
						{/* <!--end::Input error message--> */}
					</div>
					{/*end::Input group*/}
				</div>
				{/*end::Scroll*/}
				{/*begin::Actions*/}
				<div className="text-center pt-15">
					<button className="btn btn-light me-3" onClick={handleClose}>{t('Cancelar')}</button>
					<button className="btn btn-primary" >
					<span className="indicator-label">{t('Guardar')}</span>
					<span className="indicator-progress">{t('Porfavor espere...')}
						<span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
					</button>
				</div>
				{/*end::Actions*/}
				</form>
				{/*end::Form*/}
			</div>
			{/*end::Modal body*/}
			</Modal.Body>
		</Modal>
		</>
	)
}