export const payrollReducer = (state = {payrolls: null, payroll: null, download: null, usersPayrolls: [], loading: false, error: null}, action)=>{
	switch (action.type) {
		case 'PAYROLL_LOADING':
			return {...state, loading: true}
		case 'PAYROLL_FAIL':
			return {
				...state,
				loading: false,
				error: action.payload
			}
		case 'PAYROLL_GET_SUCCESS':
			return {
				...state,
				payrolls: action.payload,
				loading: false,
				error: null
			}
		case 'PAYROLL_SEND_SUCCESS':
			return {
				...state,
				payrolls: [
					...state.payrolls,
					action.payload
				],
				loading: false,
				error: null
			}
		case 'PAYROLL_GET_DOWNLOAD_SUCCESS':
			return {
				...state,
				download: action.payload,
				loading: false,
				error: null
			}
		case 'PAYROLL_DELETE_SUCCESS':
			return {
				...state,
				payrolls: state.payrolls?.filter(d => d.id !== action.payload),
				loading: false,
				error: null
			}
		case 'PAYROLL_USERS_GET_SUCCESS':
			return {
				...state,
				usersPayrolls: action.payload,
				loading: false,
				error: null
			}
		default:
			return state;
	}
}