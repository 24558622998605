import { createContext, useEffect, useState } from "react";
import parseJwt from "../services/parseJwt";

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(() => typeof localStorage.getItem("auth") == "string" ? JSON.parse(localStorage.getItem("auth")) : localStorage.getItem("auth"))
    useEffect(() => {
        if(auth && auth !== undefined){
            if(typeof auth == "string"){
                setAuth(JSON.parse(auth))
                localStorage.setItem("auth", auth)
            }else{
                setAuth(auth)
                localStorage.setItem("auth",JSON.stringify(auth))
            }
            if(auth?.accessToken){
                localStorage.setItem('RHjwt', auth.accessToken)
                if(!auth.user){
                    setAuth({
                        ...auth,
                        'user': parseJwt(auth.accessToken)['user']
                    })
                }
            }
        }
    }, [auth])
    
    // function getLocalStorage(key, initialValue) {
    //     try {
    //         const value = localStorage.getItem(key);
    //         return value ? JSON.parse(value) : initialValue;
    //     } catch (e) {
    //         // if error, return initial value
    //         return initialValue;
    //     }
    // }

    return(
        <AuthContext.Provider value={{ auth, setAuth }} >
            { children }
        </AuthContext.Provider>
    )
}

export default AuthContext