import AxiosControl from '../hooks/AxiosControl'
const Axios = AxiosControl()

export const getLogs = () => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/logs', {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'LOG_GET_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const addLog = (data) => async (dispatch) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/logs', {
            username: data.username,
            action: data.action
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'LOG_SEND_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}