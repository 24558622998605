import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react' 
import dayGridPlugin from '@fullcalendar/daygrid' 
import interactionPlugin from "@fullcalendar/interaction";
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../hooks/UseAuth';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getUser } from '../actions/userActions';
import { getVacationsUser } from '../actions/vacationActions';
import { getUserFestives } from '../actions/festiveActions';
import { checkUserSheetsAmongDates } from '../actions/sheetActions';

export default function UserSchedule() {
    const { t } = useTranslation()
    const {auth} = useAuth()
    const dispatch = useDispatch()
    const {user} = useSelector(state => state.users)
    const {vacations} = useSelector(state => state.vacations)
    const {festives} = useSelector(state => state.festives)
    const {sheets} = useSelector(state => state.sheets)
    const [event, setEvent] = useState([])
    useEffect(()=>{
        let aSchedules = []
        const date = new Date()

        // Comprobacion para que no se ejecute hasta que 'user' no contenga información
        if(user) {
        // loop de 30 vueltas
        for(let i=0; i<=30; i++){
            // Empieza desde la fecha actual y suma 1 dia en cada loop

            // filtra los horarios del usuario autenticado y recoge los del dia actual
            // eslint-disable-next-line no-loop-func
            user.schedules?.filter(schedule => schedule.day === DayAsString(date.getDay())).map((day)=>{
            const startDate = new Date(day.startDate)
            const endDate = new Date(day.endDate)

            // Si el horario esta activo
            if(day.isActive){
                // Si el horario esta entre la fecha de inicio y fin
                if(startDate < date && date < endDate){
                // Lo añado al array
                aSchedules.push({
                    title: day.checkInTime + ' - ' + day.checkOutTime, 
                    start: date.toISOString().split('T')[0] +' '+ day.checkInTime,
                    end: date.toISOString().split('T')[0] +' '+ day.checkOutTime,
                    id: day.id
                }) 
                }
            }
            })
            date.setDate(date.getDate() + 1)
        }

        festives?.map((festive, j)=>{
            const fStartDate = new Date(festive.date)
            const fEndDate = new Date(festive.date)
            
            //Eliminamos los horarios de ese día
            aSchedules = aSchedules.filter(s => !s.start.match(new RegExp(`${fStartDate.getFullYear()}-${(parseInt(fStartDate.getMonth())+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}-${fStartDate.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}`)))
            // Añadirlas al array
            aSchedules.push({
                title: 'Festivo', 
                start: `${fStartDate.getFullYear()}-${(parseInt(fStartDate.getMonth())+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}-${fStartDate.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}`,
                end: `${fEndDate.getFullYear()}-${(parseInt(fEndDate.getMonth())+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}-${parseInt(fEndDate.getDate()+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}`,
            })
        })
        // Recorrer todas las vacaciones
        vacations?.map((vacation, j)=>{
            const vStartDate = new Date(vacation.date)
            const vEndDate = new Date(vacation.date)
            
            //Eliminamos los horarios de ese día
            aSchedules = aSchedules.filter(s => !s.start.match(new RegExp(`${vStartDate.getFullYear()}-${(parseInt(vStartDate.getMonth())+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}-${vStartDate.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}`)))
            // Añadirlas al array
            aSchedules.push({
                title: 'Vacaciones ' + vacation.description, 
                start: `${vStartDate.getFullYear()}-${(parseInt(vStartDate.getMonth())+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}-${vStartDate.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}`,
                end: `${vEndDate.getFullYear()}-${(parseInt(vEndDate.getMonth())+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}-${parseInt(vEndDate.getDate()+1).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}`,
            })
        })

        // Recorrer todos los fichajes de ese mes
        if (sheets && sheets.length > 0) {
            for (const sheet of sheets) {
                if (!sheet.editedDeleted && !aSchedules.find(e => e.id === "sheet" + sheet.id)) {
                    aSchedules.push({
                        title: `Fichaje ${sheet.editedCheckInTime ? sheet.editedCheckInTime : sheet.checkInTime}-${sheet.editedCheckOutTime ? sheet.editedCheckOutTime : sheet.checkOutTime}`,
                        start: `${sheet.date.split('/')[2]}-${sheet.date.split('/')[1]}-${sheet.date.split('/')[0]} ${sheet.editedCheckInTime ? sheet.editedCheckInTime : sheet.checkInTime}`,
                        end: `${sheet.date.split('/')[2]}-${sheet.date.split('/')[1]}-${sheet.date.split('/')[0]}  ${sheet.editedCheckOutTime ? sheet.editedCheckOutTime : sheet.checkOutTime}`,
                        id: "sheet" + sheet.id,
                        color: "#edbb32"
                    })
                }
            }
        }
        setEvent(aSchedules)
        }
    }, [user, vacations, sheets])
    
    useEffect(()=>{
        if(auth?.user){
            dispatch(getUser(auth.user, true))
            dispatch(getVacationsUser(auth.username))
            dispatch(getUserFestives(auth.user))
        }
    }, [auth, dispatch])

    // Info
    const handleEventClick = (e) => {
        window.alert(e.event.title+' \n'+e.event.startStr.split('+')[0].replace('T', ' / ').split('/')[0])
    }

    function DayAsString(dayIndex) {
        var weekdays = new Array(7);
        weekdays[0] = "Domingo";
        weekdays[1] = "Lunes";
        weekdays[2] = "Martes";
        weekdays[3] = "Miercoles";
        weekdays[4] = "Jueves";
        weekdays[5] = "Viernes";
        weekdays[6] = "Sabado";

        return weekdays[dayIndex];
    }

    const handleMonthChange = (e) => {
        dispatch(checkUserSheetsAmongDates({start: e.start, end: e.end}, auth.user))
    }

    useEffect(() => {
        if (sheets && sheets.length > 0) {
            let eventsCopyArray = [...event]
            for (const sheet of sheets) {
                if (!sheet.editedDeleted && !eventsCopyArray.find(e => e.id === "sheet" + sheet.id)) {
                    const startDate = new Date(`${sheet.date.split('/')[2]}/${sheet.date.split('/')[1]}/${sheet.date.split('/')[0]}`)
                    eventsCopyArray.push({
                        title: `Fichaje ${sheet.editedCheckInTime ? sheet.editedCheckInTime : sheet.checkInTime}-${sheet.editedCheckOutTime ? sheet.editedCheckOutTime : sheet.checkOutTime}`,
                        start: `${startDate.toISOString().split('T')[0]} ${sheet.editedCheckInTime ? sheet.editedCheckInTime : sheet.checkInTime}`,
                        end: `${startDate.toISOString().split('T')[0]}  ${sheet.editedCheckOutTime ? sheet.editedCheckOutTime : sheet.checkOutTime}`,
                        id: "sheet" + sheet.id,
                        color: "#edbb32"
                    })
                }
            }
            setEvent(eventsCopyArray)
        }
    }, [sheets])

    return (
        <>
        {/*begin::Toolbar*/}
        <div className="toolbar py-5 py-lg-15" id="kt_toolbar">
            {/*begin::Container*/}
            <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
            {/*begin::Page title*/}
            <div className="page-title d-flex flex-column me-3">
                {/*begin::Title*/}
                <h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Horario')}</h1>
                {/*end::Title*/}
                {/*begin::Breadcrumb*/}
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                {/*begin::Item*/}
                <li className="breadcrumb-item text-white opacity-75">
                    <Link to={'/'} className="text-white text-hover-primary">{t('Inicio')}</Link>
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item">
                    <span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item text-white opacity-75">{t('Horario')}</li>
                {/*end::Item*/}
                
                </ul>
                {/*end::Breadcrumb*/}
            </div>
            {/*end::Page title*/}
            </div>
            {/*end::Container*/}
        </div>
        {/*end::Toolbar*/}
        {/*begin::Container*/}
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
            {/*begin::Post*/}
            <div className="content flex-row-fluid" id="kt_content">
            {/*begin::Card*/}
            <div className="card">
                {/*begin::Card header*/}
                <div className="card-header">
                <h2 className="card-title fw-bolder">{t('Horario')}</h2>
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body">
                {/*begin::Table*/}
                <FullCalendar
                    plugins={[ dayGridPlugin, interactionPlugin ]}
                    initialView="dayGridMonth"
                    eventClick={handleEventClick}
                    events={event}

                    displayEventTime={false}
                    height="auto"
                    datesSet={handleMonthChange}
                />
                {/*end::Table*/}
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Card*/}
            </div>
            {/*end::Post*/}
        </div>
        {/*end::Container*/}
        </>
    )
}