export const vacationReducer = (state = {vacations: null, vacation: null, massiveUpdated: null}, action)=>{
	switch (action.type) {
		case 'VACATION_GET_SUCCESS':
			return {
				...state,
				vacations: action.payload
			}
		case 'VACATION_SEND_SUCCESS':
			return {
				...state,
				vacations: [
					...state.vacations,
					action.payload
				]
			}
		case 'VACATION_UPDATE_SUCCESS':
			return {
				...state,
				vacations: state.vacations?.map(v => {
					if (v.id === action.payload.id) {
						return action.payload
					} else {
						return v
					}
				})
			}
		case 'VACATION_DELETED_SUCCESS':
			console.log(typeof action.payload)
			return {
				...state,
				vacations: state.vacations?.filter(v => v.id !== parseInt(action.payload))
			}
		case 'VACATION_MASSIVE_UPDATE_SUCCESS':
			return {
				...state,
				massiveUpdated: true
			}
		case 'VACATION_MASSIVE_UPDATE_RESET':
			return {
				...state,
				massiveUpdated: null
			}
		default:
			return state;
	}
}