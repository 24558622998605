import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { SuccessPopup } from '../../components/popups/Popups'
import useAuth from '../../hooks/UseAuth'
import { useTranslation } from 'react-i18next'
import { addDocument } from '../../actions/documentActions'

export default function UploadFile(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {auth} = useAuth()

  const {register, handleSubmit, watch, clearErrors, setValue, formState:{errors}} = useForm()
  const [errMsg, setErrMsg] = useState('')

  const [isPublic, setPublic] = useState(false)

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = (e) => {
	e.preventDefault()
	clearErrors()
	setErrMsg('')
	setShow(false)
	setValue('name', null)
	setValue('file', null)
	setPublic(false)
  }

  const data = {
	name: watch('name'),
	file: watch('file'),
  }

  const Upload = async(e)=>{
	dispatch(addDocument(data.file[0], auth?.user, data.name, isPublic))
	setErrMsg('')
	setShow(false)
	setValue('name', null)
	setValue('file', null)
	setPublic(false)
	SuccessPopup(t(`Documento subida correctamente!`))
  }

  useEffect(()=>{
	if (auth?.user) {
	  data.user = auth?.user
	}
  }, [auth])
	
  return (
	<>
	  {
		props.button ? 
		  <button onClick={handleShow} type="button" className="btn btn-primary">
			{/*begin::Svg Icon | path: icons/duotune/files/fil018.svg*/}
			<span className="svg-icon svg-icon-2">
			  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
				<path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="black" />
				<path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z" fill="black" />
				<path opacity="0.3" d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z" fill="black" />
			  </svg>
			</span>
			{/*end::Svg Icon*/}
			{t('Subir Documento')}
		  </button>
		:
		  <Link to={'#'} onClick={handleShow} className="d-flex flex-column flex-center h-100 p-6 bg-hover-secondary rounded-2 bg-gray-100 bg-opacity-70  px-6 py-5" >
			{/*begin::Svg Icon | path: icons/duotune/finance/fin009.svg*/}
			<span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
			  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
				<path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
				<path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z" fill="currentColor"/>
				<path opacity="0.3" d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z" fill="currentColor"/>
			  </svg>
			</span>
			{/*end::Svg Icon*/}
			<span className="fs-6 text-gray-400">{t('Subir')}</span>
			<span className="fs-4 fw-bold text-gray-800 mb-0">{t('Documento')}</span>
		  </Link>
	  }
	  

	  <Modal show={show} size='lg' backdrop='static' >
		<Modal.Header closeButton onClick={handleClose} >
		  <Modal.Title className='fw-bolder fs-2'>{t('Subir documento')}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
		  {/*begin::Modal body*/}
		  <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
			{/*begin::Form*/}
			<form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(Upload)}>
			  {/*begin::Scroll*/}
			  <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
				{/*begin::Input group*/}
				<div className="fv-row mb-7">
				  {/* <!--begin::Input group--> */}
				  <div className="fv-row mb-7 fv-plugins-icon-container">
					<label className="required form-label fw-bolder text-dark fs-6">{t('Nombre')}</label>
					<input className="form-control form-control-lg form-control-solid" type="text" name="name" autoComplete="off" {...register('name', {
					  required: 'Introduce un nombre para el archivo'
					  })} />
				  </div>
				  <div className="fv-row mb-7 fv-plugins-icon-container">
					<label className="required fw-bold fs-6">{t('Documento')}</label>
					<input type="file" name="name" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Documento" {...register(
						'file', {
							required: 'Selecciona un archivo'
						}
					)} />
				  </div>
				  <div className="fv-plugins-message-container invalid-feedback">
					<div data-field="name" data-validator="notEmpty">{errors.file?.message}</div>
				  </div>
				  {
					auth?.roles.find(r => r === 'ROLE_ADMIN') &&
						<div className="fv-row mb-7 fv-plugins-icon-container d-flex align-items-start">
							<label className="form-label fw-bolder text-dark fs-6 me-5">{t('Público')}</label>
							<input type="checkbox" className='form-check d-inline' name='isPublic' onChange={(e) => {setPublic(e.target.checked)}} checked={isPublic}/>
						</div>
				  }
				</div>
				{/*end::Input group*/}
				{/* <!--begin::Api error message--> */}
				<div className="fv-plugins-message-container invalid-feedback">
				  <div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
				</div>
				{/* <!--end::Api error message--> */}
			  </div>
			  {/*end::Scroll*/}
			  {/*begin::Actions*/}
			  <div className="text-center pt-15">
				<button className="btn btn-light me-3" onClick={handleClose}>{t('Cancelar')}</button>
				<button className="btn btn-primary" >
				  <span className="indicator-label">{t('Subir')}</span>
				  <span className="indicator-progress">{t('Porfavor espere...')}
					<span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
				</button>
			  </div>
			  {/*end::Actions*/}
			</form>
			{/*end::Form*/}
		  </div>
		  {/*end::Modal body*/}
		</Modal.Body>
	  </Modal>
	</>
  );
}
