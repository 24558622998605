import React, {useEffect, useState} from 'react'
import { Routes, Route, useNavigate, Link, useLocation } from "react-router-dom";
import useAuth from './hooks/UseAuth';
import Swal from 'sweetalert2';
import { Footer } from './components/Footer';
import { Navbar, Offcanvas } from 'react-bootstrap';
import OffCanvasMenu from './components/nav/OffCanvasMenu';
import Menu from './components/nav/Menu';
import Home from './views/Home';
import PrivateUserRouter from './routers/PrivateUserRouter';
import PrivateAdminRouter from './routers/PrivateAdminRouter';
import Error404 from './views/errors/Error404'
import Notifications from './views/notification/ListNotifications'
import Profile from './views/Profile'
import Documents from './views/Documents'
import Payroll from './views/Payroll'
import Absences from './views/Absences'
import UserSchedule from './views/UserSchedule'
import Logout from './views/Logout'
import ModifiedSheet from './views/sheetManagement/ModifiedSheet'
import Admin from './views/Admin'
import User from './views/user/User'
import ListUsers from './views/user/ListUsers'
import Department from './views/department/Department'
import ListDepartment from './views/department/ListDepartments'
import Schedule from './views/schedule/Schedule'
import ListSchedules from './views/schedule/ListSchedules'
import Task from './views/task/Task'
import ListTasks from './views/task/ListTasks'
import Absence from './views/absence/Absence'
import ListAbsences from './views/absence/ListAbsences'
import ListAbsenceTypes from './views/absenceType/ListAbsenceTypes'
import ListVacations from './views/vacation/ListVacations'
import ModifySheet from './views/sheetManagement/ModifySheet'
import Logs from './views/Logs'
import Login from './views/Login';
import { useDispatch, useSelector } from 'react-redux';
import { getBusiness } from './actions/businessActions';
import Vacation from './views/vacation/Vacation';
import About from './views/About';
import ListFestives from './views/festives/ListFestives';
import ListPayroll from './views/payroll/ListPayroll';
import DownloadPayroll from './views/payroll/DownloadPayroll';
import AddPayroll from './views/payroll/AddPayroll';
import CheckSheet from './views/sheetManagement/CheckSheet';
import CheckVacations from './views/vacation/CheckVacations';
import InfoVacations from './views/vacation/InfoVacations';
import InfoSchedules from './views/schedule/InfoSchedules';

export default function App() {
	const {auth} = useAuth()
	const dispatch = useDispatch()
	const location = useLocation()

	const {logo} = useSelector(state => state.business)
	const {swal} = useSelector(state => state.redirectReducer)
  
	const navigate = useNavigate()
  
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const toggleShow = () => setShow((s) => !s);

	useEffect(()=>{
		if(location.pathname !== '/about' && !auth?.username){
		  navigate('/login')
		}
	}, [navigate, auth, location.pathname])

	useEffect(() => {
		dispatch(getBusiness())
	}, [dispatch])

	useEffect(() => {
		if(swal){
			Swal.fire({
				text: 'La sesión ha finalizado',
				title: 'Por favor, inicie sesión antes de continuar',
				icon: 'warning',
				showCancelButton: false,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Recargar'
			}).then((result) => {
				if (result.isConfirmed) {
					dispatch({type: 'REDIRECT_RESET'})
					navigate('/logout')
				}
			})
		}
	}, [swal, navigate, dispatch])

	return (
		<>
		{/*begin::Page*/}
		<div className="page d-flex flex-row flex-column-fluid">
			{/*begin::Wrapper*/}
			<div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
			{/*begin::Header*/}
				<div id="kt_header" className="header align-items-stretch" data-kt-sticky="true" data-kt-sticky-name="header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
					{/*begin::Container*/}
					{/* El navbar solo se muestra si el usuario contiene el rol usuario */}
					{
					auth?.user
					? 
						<div className="container-xxl d-flex align-items-center">
							<Navbar expand={false} className="w-100 w-lg-auto">
							{/* <Container fluid> */}
							<Navbar.Toggle as="div" onClick={toggleShow} className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px d-lg-none">
								<span className="svg-icon svg-icon-1">
								<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
									<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
									<path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
								</svg>
								</span>
							</Navbar.Toggle>
							<Navbar.Offcanvas
								show={show} onHide={handleClose}
								id="offcanvasNavbar"
								aria-labelledby="offcanvasNavbarLabel"
								placement="end"
							>
								<Offcanvas.Header closeButton>
								<Offcanvas.Title className="d-lg-none px-4" id="offcanvasNavbarLabel">
									Menu
								</Offcanvas.Title>
								</Offcanvas.Header>
								<Offcanvas.Body>
									<div className="d-flex align-items-stretch" id="kt_header_nav"><OffCanvasMenu toggleShow={toggleShow} /></div>
								</Offcanvas.Body>
							</Navbar.Offcanvas>
							<Navbar.Brand as="div" className="header-logo m-5 me-md-10 flex-grow-1 flex-lg-grow-0">
								{/*begin::Header Logo*/}
								<Link to="/">
								{
									logo !== ''
									?
										logo
										?
											<>
												<img alt="Logo" src={logo} className="logo-default" height={30} />
												<img alt="Logo" src={logo} className="logo-sticky" height={30} />
											</>
											
										:
										<>
											<img alt="Logo" src={'../assets/media/logos/logo-demo2.png'} className="logo-default" height={30} />
											<img alt="Logo" src={'../assets/media/logos/logo-demo2.png'} className="logo-sticky" height={30} />
										</>
									:
										<></>
								}
								
								</Link>
								{/*end::Header Logo*/}
							</Navbar.Brand>
							</Navbar>
							{/*end::Heaeder menu toggle*/}
							
							{/*begin::Wrapper*/}
							<Menu />
							{/*end::Wrapper*/}
						</div>
					:
						<></>
					}
					{/*end::Container*/}
				</div>
				{/*end::Header*/}
				<Routes>
					{/* Rutas Usuario */}
					<Route index element={<Home />} />
					<Route path="/notifications" element={<PrivateUserRouter><Notifications/></PrivateUserRouter>}/>
					<Route path="/profile" element={<PrivateUserRouter><Profile/></PrivateUserRouter>}/>
					<Route path="/documents" element={<PrivateUserRouter><Documents/></PrivateUserRouter>}/>
					<Route path="/payrolls" element={<PrivateUserRouter><Payroll/></PrivateUserRouter>}/>
					<Route path="/absences" element={<PrivateUserRouter><Absences/></PrivateUserRouter>}/>
					<Route path="/schedule" element={<PrivateUserRouter><UserSchedule/></PrivateUserRouter>}/>
					<Route path='/correctedsheets' element={<PrivateUserRouter><ModifiedSheet/></PrivateUserRouter>}/>
					<Route path="/vacation/list" element={<PrivateUserRouter><ListVacations/></PrivateUserRouter>}/>
					<Route path="/vacation/managed" element={<PrivateUserRouter><InfoVacations/></PrivateUserRouter>}/>
					<Route path="/vacation/check" element={<PrivateUserRouter><CheckVacations/></PrivateUserRouter>}/>
					<Route path="/payroll/list" element={<PrivateUserRouter><ListPayroll/></PrivateUserRouter>}/>
					<Route path="/payroll/:id/:month" element={<PrivateUserRouter><DownloadPayroll/></PrivateUserRouter>}/>
					<Route path="/payroll/add/:id" element={<PrivateUserRouter><AddPayroll/></PrivateUserRouter>}/>
					<Route path="/vacations" element={<PrivateUserRouter><Vacation/></PrivateUserRouter>} />
					<Route path="/sheets" element={<PrivateUserRouter><ModifySheet/></PrivateUserRouter>}/>
					<Route path="/checksheet" element={<PrivateUserRouter><CheckSheet/></PrivateUserRouter>}/>
					<Route path="/logout" element={<PrivateUserRouter><Logout/></PrivateUserRouter>} />
					<Route path="/schedule/managed" element={<PrivateUserRouter><InfoSchedules/></PrivateUserRouter>}/>
					{/* Rutas Usuario */}
					
					{/* Rutas Admin */}
					<Route path="/admin" element={<PrivateAdminRouter><Admin/></PrivateAdminRouter>}/>
					<Route path="/user/:id" element={<PrivateAdminRouter><User/></PrivateAdminRouter>}/>
					<Route path="/user/list" element={<PrivateAdminRouter><ListUsers/></PrivateAdminRouter>}/>
					<Route path="/department/:id" element={<PrivateAdminRouter><Department/></PrivateAdminRouter>}/>
					<Route path="/department/list" element={<PrivateAdminRouter><ListDepartment/></PrivateAdminRouter>}/>
					<Route path="/schedule/:id" element={<PrivateAdminRouter><Schedule/></PrivateAdminRouter>}/>
					<Route path="/schedule/list" element={<PrivateAdminRouter><ListSchedules/></PrivateAdminRouter>}/>
					<Route path="/task/:id" element={<PrivateAdminRouter><Task/></PrivateAdminRouter>}/>
					<Route path="/task/list" element={<PrivateAdminRouter><ListTasks/></PrivateAdminRouter>}/>
					<Route path="/absence/:id" element={<PrivateAdminRouter><Absence/></PrivateAdminRouter>}/>
					<Route path="/absence/list" element={<PrivateAdminRouter><ListAbsences/></PrivateAdminRouter>}/>
					<Route path="/absenceType/list" element={<PrivateAdminRouter><ListAbsenceTypes/></PrivateAdminRouter>}/>
					<Route path='/festives' element={<PrivateAdminRouter><ListFestives/></PrivateAdminRouter>}/>
					<Route path="/logs" element={<PrivateAdminRouter><Logs/></PrivateAdminRouter>}/>
					{/* Rutas Admin */}

					<Route path="/login" element={<Login />}/>
					<Route path='about' element={<About />} />
					<Route path="/*" element={<Error404/>}/>
				</Routes>
				<Footer />
			</div>
		{/*end::Wrapper*/}
		</div>
		{/*end::Page*/}
		</>
	)
}