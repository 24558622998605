import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import { Link, useParams } from 'react-router-dom'
import { SuccessPopup } from '../../components/popups/Popups'
import { useDispatch } from 'react-redux'
import { editSchedule } from '../../actions/scheduleActions'
import handleApiErrors from '../errors/handleApiErrors'
import { useTranslation } from 'react-i18next'

export default function EditSchedule(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  
  // Si la ruta contiene la id la recoge de ahi, sino la recoge por parametros
  const { id = props.schedule.id } = useParams()

  const {register, handleSubmit, watch, clearErrors, setValue, formState:{errors}} = useForm()
  const [errMsg, setErrMsg] = useState('')
  
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = (e) => {
    e.preventDefault()
    clearErrors()
    setErrMsg('')
    setShow(false)
  }

  const schedule = props.schedule

  const data = {
    name: watch('form.name'),
    checkInTime: watch('form.checkInTime'),
    checkOutTime: watch('form.checkOutTime'),
    startDate: watch('form.startDate'),
    endDate: watch('form.endDate'),
    day: watch('form.day')
  }

  useEffect(()=>{
    setValue('form', schedule)
  }, [setValue, schedule])

  const UpdateSchedule = async() => {
    dispatch(editSchedule(data, id))
    setErrMsg('')
    setShow(false)
    SuccessPopup(`Horario modificado correctamente!`)
  }
  
  return (
    <>
      {
        props.lightLink 
        ? <Link to={'#'} onClick={handleShow} className="menu-link px-3">
            {t('Editar')}
          </Link>
        : <Button variant="primary" onClick={handleShow} className="btn btn-sm btn-light-primary">
            {t('Editar')}
          </Button>
      }
      
      <Modal show={show} size='lg' backdrop='static' >
        <Modal.Header closeButton onClick={handleClose} >
          <Modal.Title className='fw-bolder fs-2'>{t('Editar Horario')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*begin::Modal body*/}
          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
            {/*begin::Form*/}
            <form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(UpdateSchedule)}>
              {/*begin::Scroll*/}
              <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Nombre')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="text" name="name" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Nombre del horario" {...register(
                    'form.name', {
                      required: 'Este campo es obligatorio'
                      }
                  )}  />
                  {/*end::Input*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="name" data-validator="notEmpty">{errors.name?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Día de la semana')}</label>
                  {/*end::Label*/}
                  {/*begin::Select*/}
                  <select name="day" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Dia de la semana" {...register(
                    'form.day', {
                      required: 'Este campo es obligatorio'
                      }
                  )} >
                    <option value='Lunes'>{t('Lunes')}</option>
                    <option value='Martes'>{t('Martes')}</option>
                    <option value='Miercoles'>{t('Miercoles')}</option>
                    <option value='Jueves'>{t('Jueves')}</option>
                    <option value='Viernes'>{t('Viernes')}</option>
                    <option value='Sabado'>{t('Sabado')}</option>
                    <option value='Domingo'>{t('Domingo')}</option>
                  </select>
                  {/*end::Select*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="day" data-validator="notEmpty">{errors.day?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Hora de entrada')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="time" name="checkInTime" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Hora de entrada" {...register(
                    'form.checkInTime', {
                      required: 'Este campo es obligatorio'
                      }
                  )}  />
                  {/*end::Input*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="checkInTime" data-validator="notEmpty">{errors.checkInTime?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Hora de salida')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="time" name="checkOutTime" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Hora de salida" {...register(
                    'form.checkOutTime', {
                      required: 'Este campo es obligatorio'
                      }
                  )}  />
                  {/*end::Input*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="checkOutTime" data-validator="notEmpty">{errors.checkOutTime?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Fecha de Inicio')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="date" name="startDate" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Fecha de inicio" {...register(
                    'form.startDate', {
                      required: 'Este campo es obligatorio'
                      }
                  )}  />
                  {/*end::Input*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="startDate" data-validator="notEmpty">{errors.startDate?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="fw-bold fs-6 mb-2">{t('Fecha de Fin')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="date" name="endDate" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Fecha de fin" {...register('form.endDate')}  />
                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}
                {/* <!--begin::Api error message--> */}
                <div className="fv-plugins-message-container invalid-feedback">
                  <div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
                </div>
                {/* <!--end::Api error message--> */}
              </div>
              {/*end::Scroll*/}
              {/*begin::Actions*/}
              <div className="text-center pt-15">
                <button className="btn btn-light me-3" onClick={handleClose}>{t('Cancelar')}</button>
                <button className="btn btn-primary">
                  <span className="indicator-label">{t('Actualizar')}</span>
                  <span className="indicator-progress">{t('Please wait..')}.
                    <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                </button>
              </div>
              {/*end::Actions*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Modal body*/}
        </Modal.Body>
      </Modal>
    </>
  );
}
