import { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import useAuth from "../hooks/UseAuth"

export default function Logout() {
  const navigate = useNavigate();
  const { setAuth } = useAuth()

  useEffect(() => {
    setAuth(undefined)
    localStorage.removeItem('auth')
    localStorage.removeItem('RHjwt')
    navigate('/login');
  })
}
