export const sheetReducer = (state = {sheets: null, sheet: null, dates: null, sheetByDate: null}, action)=>{
    switch (action.type) {
        case 'SHEET_GET_SUCCESS':
            return {
                ...state,
                sheets: action.payload
            }
        case 'SHEET_GET_DETAIL_SUCCESS':
            return {
                ...state,
                sheet: action.payload
            }
        case 'SHEET_GET_DATES':
            return {
                ...state,
                dates: action.payload
            }
        case 'SHEET_SEND_SUCCESS':
            return {
                ...state,
                sheets: [
                    ...state.sheets,
                    action.payload
                ]
            }
        case 'SHEET_EDITED_SUCCESS':
            return {
                ...state.map(
                    sheet => sheet.id === action.payload.id
                    ?
                        action.payload
                    :
                        sheet
                )
            }
        default:
            return state;
    }
}