import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { editUser, getUsers } from '../../actions/userActions'
import { SuccessPopup,ConfirmPupup } from '../../components/popups/Popups'
import { useTranslation } from 'react-i18next'
import Select from "react-select";
import useAuth from '../../hooks/UseAuth'

export default function EditUser(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {auth} = useAuth()

  // Si la ruta contiene la id la recoge de ahi, sino la recoge por parametros
  const {id = props?.user?.id} = useParams()

  const {register, handleSubmit, watch, clearErrors, formState:{errors}, setValue} = useForm()
  const [errMsg, setErrMsg] = useState('')

  const [supervisors, setSupervisors] = useState({options: [], selected: null})

  const user = props.user

  const {users} = useSelector(state => state.users)

  const data = {
	firstName: watch('form.firstName'),
	lastName: watch('form.lastName'),
	dni: watch('form.dni'),
	email: watch('form.email'),
	address: watch('form.address'),
	gender: watch('form.gender'),
	phone: watch('form.phone'),
	employ: watch('form.employ'),
	birthDate: watch('form.birthDate'),
	vacationDays: watch('form.vacationDays'),
	vacationAccepted: watch('form.vacationAccepted'),
	vacationRequested: watch('form.vacationRequested')
  }
  useEffect(() => {
	if(auth){
	  dispatch(getUsers())
	}
  }, [auth, dispatch])

  useEffect(()=>{
	setValue('form', user)
	clearErrors()
	setErrMsg('')
  }, [setValue, user])

  useEffect(() => {
	let usersAvailable = []
	let supervisorSelected = null
	users?.filter(u => u.id !== user?.id).forEach(u => {
	  usersAvailable.push({value: u.id, label: u.username})
	  if (user?.supervisor === u.id) {
		supervisorSelected = {value: u.id, label: u.username}
	  }
	});
	setSupervisors({selected: supervisorSelected, options: usersAvailable})
  }, [users, user])

  const UpdateUser = async() => {
	dispatch(editUser({...data, supervisor: supervisors?.selected?.value}, id))
	setErrMsg('')
	props.handleClose()
	SuccessPopup(`Usuario modificado correctamente!`)
  }

  const resetVacationDays = () => {	
	ConfirmPupup('¿Seguro que desea resetar los días de vacaciones?')
	.then((result) => {
		if (result.isConfirmed) {
			setValue('form.vacationAccepted', 0)
			setValue('form.vacationRequested', 0)
		}
	})
 }

  return (
	<>
		{/*begin::Modal body*/}
		<div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
		{/*begin::Form*/}
		<form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(UpdateUser)}>
			{/*begin::Scroll*/}
			<div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
			{/* <!--begin::Input group--> */}
			<div className="row fv-row mb-7 fv-plugins-icon-container">
				{/* <!--begin::Col--> */}
				<div className="col-xl-6">
				<label className="form-label fw-bolder text-dark fs-6">{t('Nombre')}</label>
				<input className="form-control form-control-lg form-control-solid" type="text" autoComplete="off" {...register('form.firstName')} />
				{/* <!--begin::Input error message--> */}
				<div className="fv-plugins-message-container invalid-feedback">
					<div data-field="firstName" data-validator="notEmpty">{errors.firstName?.message}</div>
				</div>
				{/* <!--end::Input error message--> */}
				<div className="fv-plugins-message-container invalid-feedback"></div></div>
				{/* <!--end::Col--> */}
				{/* <!--begin::Col--> */}
				<div className="col-xl-6">
				<label className="form-label fw-bolder text-dark fs-6">{t('Apellidos')}</label>
				<input className="form-control form-control-lg form-control-solid" type="text" autoComplete="off" {...register('form.lastName')} />
				{/* <!--begin::Input error message--> */}
				<div className="fv-plugins-message-container invalid-feedback">
					<div data-field="lastName" data-validator="notEmpty">{errors.lastName?.message}</div>
				</div>
				{/* <!--end::Input error message--> */}
				<div className="fv-plugins-message-container invalid-feedback"></div></div>
				{/* <!--end::Col--> */}
			</div>
			{/* <!--end::Input group--> */}
			{/* <!--begin::Input group--> */}
			<div className="fv-row mb-7 fv-plugins-icon-container">
				<label className="form-label fw-bolder text-dark fs-6">{t('DNI')}</label>
				<input className="form-control form-control-lg form-control-solid" type="text" name="dni" maxLength={9} autoComplete="off" {...register('form.dni')} />
			</div>
			{/* <!--end::Input group--> */}
			{/* <!--begin::Input group--> */}
			<div className="fv-row mb-7 fv-plugins-icon-container">
				<label className="form-label fw-bolder text-dark fs-6">{t('Email')}</label>
				<input className="form-control form-control-lg form-control-solid" type="text" name="email" autoComplete="off" {...register('form.email')} />
			</div>
			{/* <!--end::Input group--> */}
			{/* <!--begin::Input group--> */}
			<div className="fv-row mb-7 fv-plugins-icon-container">
				<label className="form-label fw-bolder text-dark fs-6">{t('Teléfono')}</label>
				<input className="form-control form-control-lg form-control-solid" type="text" name="phone" maxLength={9} autoComplete="off" {...register('form.phone')} />
			</div>
			{/* <!--end::Input group--> */}
			{/* <!--begin::Input group--> */}
			<div className="fv-row mb-7 fv-plugins-icon-container">
				<label className="form-label fw-bolder text-dark fs-6">{t('Dirección')}</label>
				<input className="form-control form-control-lg form-control-solid" type="text" name="address" autoComplete="off" {...register('form.address')} />
			</div>
			{/* <!--end::Input group--> */}
				{/* <!--begin::Input group--> */}
				<div className="fv-row mb-7 fv-plugins-icon-container">
				<label className="form-label fw-bolder text-dark fs-6">{t('Empleo')}</label>
				<input className="form-control form-control-lg form-control-solid" type="text" name="employ" autoComplete="off" {...register('form.employ')} />
			</div>
			{/* <!--end::Input group--> */}
			{/* <!--begin::Input group--> */}
			<div className="fv-row mb-7 fv-plugins-icon-container">
				<label className="form-label fw-bolder text-dark fs-6">{t('Género')}</label>
				<input className="form-control form-control-lg form-control-solid" type="text" name="gender" autoComplete="off" {...register('form.gender')} />
			</div>
			{/* <!--end::Input group--> */}
			{/* <!--begin::Input group--> */}
			<div className="fv-row mb-7 fv-plugins-icon-container">
				<label className="form-label fw-bolder text-dark fs-6">{t('Fecha de nacimiento')}</label>
				<input className="form-control form-control-lg form-control-solid" type="date" name="birthDate" autoComplete="off" {...register('form.birthDate')} />
			</div>
			{
				props.supervisor !== false
				?
				<div className="fv-row mb-7 fv-plugins-icon-container">
				<label className="form-label fw-bolder text-dark fs-6">{t('Supervisor')}</label>
				<Select placeholder={t('Seleccionar Usuario')} options={supervisors.options} value={supervisors.selected} onChange={(selectedValue) => {setSupervisors({...supervisors, selected: selectedValue})}}/>
				</div>
				:
				<></>
			}
			{
				props.admin === true
				?
					<>
						<div className='fv-row mb-7 fv-plugins-icon-container'>
							<label className='form-label fw-bolder text-dark fs-6'>{t('Días de vacaciones disponibles')}</label>
							<input type="number" name="vacationDays" className="form-control form-control-solid mb-3 mb-lg-0" placeholder={t('Días de vacaciones disponibles')} {...register('form.vacationDays')}  />
						</div>
						<div className='fv-row mb-7 fv-plugins-icon-container'>
							<span className='fw-bolder me-7'>{parseInt(user?.vacationDays) - (parseInt(user?.vacationAccepted) + parseInt(user?.vacationRequested))} {t('días de vacaciones disponibles')}</span>
							<button type='button' className='btn btn-info' onClick={resetVacationDays}>{t('Resetear días de vacaciones disponibles')}</button>
						</div>
					</>
				:
					<></>
			}
			
			{/* <!--end::Input group--> */}
			{/* <!--begin::Api error message--> */}
			<div className="fv-plugins-message-container invalid-feedback">
				<div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
			</div>
			{/* <!--end::Api error message--> */}
			</div>
			{/*end::Scroll*/}
			{/*begin::Actions*/}
			<div className="text-center pt-15">
			<button className="btn btn-light me-3" onClick={props.handleClose}>{t('Cancelar')}</button>
			<button className="btn btn-primary">
				<span className="indicator-label">{t('Actualizar')}</span>
				<span className="indicator-progress">{t('Please wait...')}
				<span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
			</button>
			</div>
			{/*end::Actions*/}
		</form>
		{/*end::Form*/}
		</div>
		{/*end::Modal body*/}
		
	</>
  );
}
