import AxiosControl from '../hooks/AxiosControl'
const Axios = AxiosControl()

export const getUsers = () => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/user', {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'USER_GET_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const getUser = (user, isActiveUser = false) => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/user/' + user, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'USER_GET_DETAIL_SUCCESS',
                payload: response.data
            })
            if (isActiveUser) {
                dispatch({
                    type: 'ACTIVE_USER_READY'
                })
            }
        }
    } catch(error){
        console.log(error)
    }
}

export const getUserSupervisor = (user) => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/user/supervised/' + user, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'USER_GET_SUPERVISOR_DETAIL_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const addUser = (data) => async (dispatch) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/user', {
            roles: data.roles,
            username: data.username,
            password: data.password,
            dni: data.dni,
            firstName: data.firstName,
            lastName: data.lastName,
            gender: data.gender,
            address: data.address,
            phone: data.phone,
            email: data.email,
            birthDate: data.birthDate,
            employ: data.employ,
            imageProfile: data.imageProfile,
            vacationDays: data.vacationDays
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'USER_SEND_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const editUser = (data, user) => async (dispatch) => {
    try {
        const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/user/' + user, {
            roles: data.roles,
            username: data.username,
            dni: data.dni,
            firstName: data.firstName,
            lastName: data.lastName,
            gender: data.gender,
            address: data.address,
            phone: data.phone,
            email: data.email,
            birthDate: data.birthDate,
            employ: data.employ,
            imageProfile: data.imageProfile,
            vacationDays: data.vacationDays,
            vacationAccepted: data.vacationAccepted,
            vacationRequested: data.vacationRequested,
            supervisor: data.supervisor
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'USER_EDITED_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const enableUser = (user) => async (dispatch) => {
    try {
        const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/user/enable/' + user, {}, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'USER_EDITED_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const disableUser = (user) => async (dispatch) => {
    try {
        const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/user/disable/' + user, {}, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'USER_EDITED_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const updateRoles = (data, user) => async (dispatch) => {
    try {
        const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/user/' + user + '/roles', {
            roles: data.roles
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'USER_EDITED_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const updatePassword = (data, user) => async (dispatch) => {
    try {
        const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/user/' + user + '/password', {
            password: data.password
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'USER_EDITED_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const updateAvatar = (data, user) => async (dispatch) => {
    try {
        const fileData = new FormData()
		fileData.append('file', data)
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/user/avatar/' + user, fileData, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'USER_EDITED_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}