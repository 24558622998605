import { SuccessPopup } from '../components/popups/Popups'
import AxiosControl from '../hooks/AxiosControl'
import i18n from '../i18n'
const Axios = AxiosControl()

export const getPayrolls = (user, monthyear) => async (dispatch) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/payroll/userPayrolls', {
            user: user,
            monthyear: monthyear
        },{
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'PAYROLL_GET_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const getUsersPayrolls = () => async (dispatch) => {
    dispatch({type: 'PAYROLL_LOADING'})
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/payroll',{
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response?.status < 400){
            dispatch({
                type: 'PAYROLL_USERS_GET_SUCCESS',
                payload: response.data
            }) 
        } else {
            dispatch({type: 'PAYROLL_FAIL', payload: response.message})
        }
    } catch(error){
        dispatch({type: 'PAYROLL_FAIL', payload: error.message})
        console.log('error', error.message)
    }
}

export const addPayroll = (data, user, title, monthyear) => async (dispatch) => {
    try {
        const fileData = new FormData()
        fileData.append('file', data)
        fileData.append('monthyear', monthyear)
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/payroll/new/' + user + '/' + title, fileData, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'PAYROLL_SEND_SUCCESS',
                payload: response.data
            })
            SuccessPopup(i18n.t(`Nómina subida correctamente!`))
        }
    } catch(error){
        console.log(error)
    }
}

export const removePayroll = (payroll) => async (dispatch) => {
    try {
        const response = await Axios.delete(
            process.env.REACT_APP_DB_ADDRESS + '/api/payroll/' + payroll, {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('RHjwt')
                }
            }
        )
        if (response.status < 400) {
            dispatch({
                type: 'PAYROLL_DELETE_SUCCESS',
                payload: payroll
            })
            SuccessPopup(i18n.t(`Nómina eliminada correctamente!`))
        }
    } catch (error) {
        console.log(error)
    }
}

export const downloadPayroll = (payroll) => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/payroll/' + payroll, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'DEPARTMENT_GET_DOWNLOAD_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}