export const festiveReducer = (state = {festives: null, festive: null}, action)=>{
	switch (action.type) {
		case 'FESTIVE_GET_SUCCESS':
			return {
				...state,
				festives: action.payload
			}
		case 'FESTIVE_SEND_SUCCESS':
			return {
				...state,
				festives: [
					...state.festives,
					action.payload
				]
			}
		case 'FESTIVE_UPDATE_SUCCESS':
			return {
				...state,
				festives: state.festives?.map(f => {
					if (f.id === action.payload.id) {
						return action.payload
					} else {
						return f
					}
				})
			}
		case 'FESTIVE_DELETED_SUCCESS':
			return {
				...state,
				festives: state.festives?.filter(f => f.id !== action.payload)
			}
		default:
			return state;
	}
}