import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { getTask, updateTask } from '../../actions/taskActions'
import { getDepartments } from '../../actions/departmentActions'
import { SuccessPopup } from '../../components/popups/Popups'
import EditTask from '../modals/EditTask'
import { useTranslation } from 'react-i18next'
import { CustomDataTable } from '../../components/datatables/CustomDataTable'

export default function Task() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { id } = useParams()
	const {task} = useSelector(state => state.tasks)
	const {department} = useSelector(state => state.departments)

	const {register, handleSubmit, watch, setValue} = useForm()

	const data = {
		status: watch('taskStatus'),
	}

	useEffect(()=>{
		dispatch(getTask(id))
	}, [id, dispatch])

	useEffect(()=>{
		if(task){
			setValue('taskStatus', task.status)
		}
	}, [setValue, task])
	
	const UpdateTaskStatus = async() => {
		dispatch(updateTask(data, id))
		SuccessPopup(`Estado de la tarea modificado correctamente!`)
	}

	const columns = [
		{
			name: t('Departamento'),
			cell: department =>
				<div className="d-flex align-items-center border-0">
					<div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
						<Link to={`/department/${department?.id}`}>
							<div className="symbol-label fs-3 bg-light-danger text-danger">{department.name?.charAt(0)}</div>
						</Link>
					</div>
					<div className="d-flex flex-column">
						<Link to={`/department/${department?.id}`} className="text-gray-800 text-hover-primary mb-1">
							{department.name}
						</Link>
					</div>
				</div>,
			selector: (department) => department.name,
			sortable: true,
		}
	]

	return (
		<>
			<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
				<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
					<div className="page-title d-flex flex-column me-3">
						<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Tarea')}</h1>
						<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/task/list' className="text-white text-hover-primary">{t('Listar Tareas')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white">{t('Tarea')}</li>
						</ul>
					</div>
				</div>
			</div>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					<div className="form d-flex flex-column flex-lg-row">
						<div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
							<div className="card card-flush py-4">
								<div className="card-header">
									<div className="card-title">
										<h2>{task?.name}</h2>
									</div>
								</div>
								<div className="card-body pt-0">
									<div className="d-flex flex-stack fs-4 py-3">
										<div className="fw-bolder rotate collapsible" data-bs-toggle="collapse" href="#kt_user_view_details" role="button" aria-expanded="false" aria-controls="kt_user_view_details">{t('Detalles')}
											<span className="ms-2 rotate-180">
												<span className="svg-icon svg-icon-3">
													<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
														<path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
													</svg>
												</span>
											</span>
										</div>
										<span data-bs-toggle="tooltip" data-bs-trigger="hover" title="Edit customer details">
											<EditTask task={task} />
										</span>
									</div>
									<div className="separator" />
									<div id="kt_user_view_details" className="collapse show">
										<div className="pb-5 fs-6">
											{task?.description
												?
													<>
														<div className="fw-bolder mt-5">{t('Descripción')}</div>
														<div className="text-gray-600">{task.description}</div>
													</>
												:
													<></>
											}
											{task?.startDate
												?
													<>
														<div className="fw-bolder mt-5">{t('Fecha de inicio')}</div>
														<div className="text-gray-600">{task.startDate}</div>
													</>
												:
													<></>
											}
											{task?.endDate
												?
													<>
														<div className="fw-bolder mt-5">{t('Fecha de fin')}</div>
														<div className="text-gray-600">{task.endDate}</div>
													</>
												:
													<></>
											}
											{task?.departments
												?
													<>
														<div className="fw-bolder mt-5">{t('Departamentos')}</div>
														{task?.departments?.map((department, y) =>
															<Link key={y} to={`/department/${department?.id}`} className="text-gray-600 text-hover-primary mb-3">
																{department?.name}
															</Link>
														)}
													</>
												:
													<></>
											}
										</div>
									</div>
								</div>
							</div>
							<div className="card card-flush py-4">
								<div className="card-header">
									<div className="card-title">
										<h2>{t('Estado')}</h2>
									</div>
								</div>
								<form className="card-body pt-0" onSubmit={handleSubmit(UpdateTaskStatus)}>
									<select className="form-select mb-2" data-hide-search="true" {...register('taskStatus', { required: true })}>
										<option value={"Pendiente"}>{t('Pendiente')}</option>
										<option value={"Finalizada"}>{t('Finalizada')}</option>
									</select>
									<div className="text-muted fs-7">{t('Cambiar el estado de la tarea')}.</div>
									<button className='btn btn-primary py-2 px-3 mt-5'>{t('Guardar')}</button>
								</form>
							</div>
						</div>
						<div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
							<div className="card card-flush py-4">
								<div className="card-header border-0 pt-6">
									<div className="card-title">
										<h2>{t('Departamentos')}</h2>
									</div>
									<div className="card-toolbar">
										<div className="d-flex justify-content-end" data-kt-user-table-toolbar="base"></div>
									</div>
								</div>
								<div className="card-body pt-0">
									<div className="mb-10 fv-row">
										<label className="form-label">{t('Departamentos que tienen asignada esta tarea')}<div className="separator my-5" /></label>
										<div className='table-responsive'>
											{task?.departments?.length > 0
												?
													<CustomDataTable columns={columns} data={task?.departments} />
												:
													<></>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}