import AxiosControl from '../hooks/AxiosControl'
const Axios = AxiosControl()

export const getDocuments = (user) => async (dispatch) => {
	try {
		const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/document/userdocuments', {
			username: user,
		},{
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'DOCUMENT_GET_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}

export const addDocument = (data, user, title, isPublic) => async (dispatch) => {
	try {
		const fileData = new FormData()
		fileData.append('file', data)
		fileData.append('public', isPublic)
		const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/document/new/' + user + '/' + title, fileData, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'DOCUMENT_SEND_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}

export const removeDocument = (document) => async (dispatch) => {
	try {
		const response = await Axios.delete(
			process.env.REACT_APP_DB_ADDRESS + '/api/document/' + document, {
				headers: {
					authorization: 'Bearer ' + localStorage.getItem('RHjwt')
				}
			}
		)
		if (response.status < 400) {
			dispatch({
				type: 'DOCUMENT_DELETE_SUCCESS',
				payload: document
			}) 
		}
	} catch (error) {
		console.log(error)
	}
}

export const downloadDocument = (document) => async (dispatch) => {
	try {
		const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/document/' + document, {
			headers: {
				authorization: 'Bearer ' + localStorage.getItem('RHjwt')
			}
		})
		if(response.status < 400){
			dispatch({
				type: 'DOCUMENT_GET_DOWNLOAD_SUCCESS',
				payload: response.data
			}) 
		}
	} catch(error){
		console.log(error)
	}
}