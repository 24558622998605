import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ConfirmPupup } from '../../components/popups/Popups'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/UseAuth'
import { CustomDataTable } from '../../components/datatables/CustomDataTable'
import { deleteFestive, getFestives } from '../../actions/festiveActions'
import CreateFestive from './CreateFestive'
import { Button } from 'react-bootstrap'

export default function ListFestives() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {auth} = useAuth()

	const {festives} = useSelector(state => state.festives)

	useEffect(() => {
		if(auth){
			dispatch(getFestives())
		}
	}, [auth, dispatch])

	const columns = [
		{
			name: t('Fecha'),
			cell: festive =>
				<div className="d-flex align-items-center">
					<div className="d-flex flex-column">
						{festive.date}
					</div>
				</div>,
			selector: (festive) => festive.name,
			sortable: true,
		},{
			name: '',
			cell: festive =>
				<div>
					<Button variant='light-danger' className="btn-sm" onClick={() => confirmDeleteFestive(festive.id, festive.date)}>
					<span class="svg-icon svg-icon-muted svg-icon-2hx m-0">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<path opacity="0.3" d="M6 19.7C5.7 19.7 5.5 19.6 5.3 19.4C4.9 19 4.9 18.4 5.3 18L18 5.3C18.4 4.9 19 4.9 19.4 5.3C19.8 5.7 19.8 6.29999 19.4 6.69999L6.7 19.4C6.5 19.6 6.3 19.7 6 19.7Z" fill="black"/>
							<path d="M18.8 19.7C18.5 19.7 18.3 19.6 18.1 19.4L5.40001 6.69999C5.00001 6.29999 5.00001 5.7 5.40001 5.3C5.80001 4.9 6.40001 4.9 6.80001 5.3L19.5 18C19.9 18.4 19.9 19 19.5 19.4C19.3 19.6 19 19.7 18.8 19.7Z" fill="black"/>
						</svg>
					</span>
					</Button>
				</div>,
			sortable: false,
		}
	]

	const confirmDeleteFestive =(id, date)=>{
		ConfirmPupup(t('¿Eliminar el festivo ') + date + '?')
		.then((result) => {
			if (result.isConfirmed) {
				dispatch(deleteFestive(id))
			}
		})
	}

	return (
		<>
			<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
				<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
					<div className="page-title d-flex flex-column me-3">
						<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Listar Festivos')}</h1>
						<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
							<li className="breadcrumb-item text-white opacity-75">
								<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
							</li>
							<li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
							</li>
							<li className="breadcrumb-item text-white">{t('Listar Festivos')}</li>
						</ul>
					</div>
				</div>
			</div>
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					<div className="card">
						<div className="card-header border-0 pt-6">
							<div className="card-toolbar">
								<div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
									<CreateFestive />
								</div>
							</div>
						</div>
						<div className="card-body py-4">
							<div className='table-responsive'>
								{festives?.length > 0
									?
										<CustomDataTable columns={columns} data={festives} />
									:
										<></>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}