import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { addUser, getUsers } from '../../actions/userActions'
import { SuccessPopup } from '../../components/popups/Popups'

import handleApiErrors from '../errors/handleApiErrors'
import { useTranslation } from 'react-i18next'
import Select from "react-select";

export default function CreateUser() {
  const { t } = useTranslation()
  const dispatch = useDispatch()


  const {register, handleSubmit, watch, clearErrors, formState:{errors}} = useForm()
  const [errMsg, setErrMsg] = useState('')
  const [passwordShow, setPasswordShow] = useState(false)

  const {users} = useSelector(state => state.users)

  const [supervisors, setSupervisors] = useState({options: [], selected: null})

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = (e) => {
	e.preventDefault()
	clearErrors()
	setErrMsg('')
	setShow(false)
  }
  
  const data = {
	firstName: watch('firstName'),
	lastName: watch('lastName'),
	username: watch('username'),
	password: watch('password'),
	roles: ['ROLE_USER'],
	adminRoleCheck: watch('adminRole'),
	supervisor: supervisors.selected?.value,
	vacationDays: watch('vacationDays'),
  }

  const CreateUser = async() => {
	// Si el check del formulario esta activado se agrega el rol de admin
	if(data.adminRoleCheck){
	  data.roles.push('ROLE_ADMIN')
	}

	dispatch(addUser({
		firstName: data.firstName,
		lastName: data.lastName,
		username: data.username,
		password: data.password,
		roles: data.roles,
		supervisor: supervisors.selected?.value,
		vacationDays: data.vacationDays
	}))
	setErrMsg('')
	setShow(false)
	SuccessPopup(`Usuario '${data.username}' creado correctamente!`)
  }

  const togglePassword = () => {
	setPasswordShow(!passwordShow);
  }

  useEffect(() => {
	dispatch(getUsers())
  }, [dispatch])

  useEffect(() => {
	let usersAvailable = []
	users?.forEach(u => {
	  usersAvailable.push({value: u.id, label: u.username})
	});
	setSupervisors({selected: null, options: usersAvailable})
  }, [users])

  return (
	<>
	  <Button variant="primary" onClick={handleShow}>
		{/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
		<span className="svg-icon svg-icon-2 me-3">
		  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
			<rect opacity="0.5" x="11.364" y="20.364" width={16} height={2} rx={1} transform="rotate(-90 11.364 20.364)" fill="black" />
			<rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
		  </svg>
		</span>
		{/*end::Svg Icon*/}
		{t('Crear Usuario')}
	  </Button>

	  <Modal show={show} size='lg' backdrop='static' >
		<Modal.Header closeButton onClick={handleClose} >
		  <Modal.Title className='fw-bolder fs-2'>{t('Crear Usuario')}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
		  {/*begin::Modal body*/}
		  <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
			{/*begin::Form*/}
			<form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(CreateUser)}>
			  {/*begin::Scroll*/}
			  <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
				{/*begin::Input group*/}
				<div className="fv-row mb-7">
				  {/*begin::Label*/}
				  <label className="required fw-bold fs-6 mb-2">{t('Usuario')}</label>
				  {/*end::Label*/}
				  {/*begin::Input*/}
				  <input type="text" name="username" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Usuario" {...register(
					'username', {
					  required: 'Este campo es obligatorio'
					  }
				  )}  />
				  {/*end::Input*/}
				  {/* <!--begin::Input error message--> */}
				  <div className="fv-plugins-message-container invalid-feedback">
					<div data-field="username" data-validator="notEmpty">{errors.username?.message}</div>
				  </div>
				  {/* <!--end::Input error message--> */}
				</div>
				{/*end::Input group*/}
				{/* <!--begin::Input group--> */}
				<div className="fv-row mb-6">
				  {/* <!--begin::Wrapper--> */}
				  <div className="mb-1">
					{/* <!--begin::Label--> */}
					<label className="required fw-bold fs-6 mb-2">{t('Contraseña')}</label>
					{/* <!--end::Label--> */}
					{/* <!--begin::Input wrapper--> */}
					<div className="position-relative mb-3">
					  <input className="form-control form-control-solid mb-3 mb-lg-0" type={passwordShow ? "text" : "password"} placeholder="Contraseña" name="password" autoComplete="off" 
						{...register(
						  'password', {
							required: 'Este campo es obligatorio'
						  }
						)} 
					  />
					  <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={togglePassword}>
						{
						  passwordShow 
						  ? <i className="bi bi-eye-slash fs-2"></i>
						  : <i className="bi bi-eye fs-2"></i>
						}
					  </span>
					</div>
					{/* <!--end::Input wrapper--> */}
				  </div>
				  {/* <!--end::Wrapper--> */}
				  {/* <!--begin::Input error message--> */}
				  <div className="fv-plugins-message-container invalid-feedback">
					<div data-field="username" data-validator="notEmpty">{errors.password?.message}</div>
				  </div>
				  {/* <!--end::Input error message--> */}
				<div className="fv-plugins-message-container invalid-feedback"></div></div>
				{/* <!--end::Input group=--> */}
				{/*begin::Input group*/}
				<div className="fv-row mb-7">
				  {/*begin::Label*/}
				  <label className="fw-bold fs-6 mb-2">{t('Nombre')}</label>
				  {/*end::Label*/}
				  {/*begin::Input*/}
				  <input type="text" name="firstName" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Nombre" {...register('firstName')}  />
				  {/*end::Input*/}
				</div>
				{/*end::Input group*/}
				{/*begin::Input group*/}
				<div className="fv-row mb-7">
				  {/*begin::Label*/}
				  <label className="fw-bold fs-6 mb-2">{t('Apellidos')}</label>
				  {/*end::Label*/}
				  {/*begin::Input*/}
				  <input type="text" name="lastName" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Apellidos" {...register('lastName')}  />
				  {/*end::Input*/}
				</div>
				{/*end::Input group*/}
				{/*begin::Checkbox group*/}
				<div className="form-check form-check-custom form-check-solid mb-7">
					<input className="form-check-input" type="checkbox" {...register("adminRole", {})} />
					<label className="form-check-label">
						{t('Rol Admin')}
					</label>
				</div>
				<div className="fv-row mb-7 fv-plugins-icon-container">
				  <label className="form-label fw-bolder text-dark fs-6">{t('Supervisor')}</label>
				  <Select placeholder={t('Seleccionar Usuario')} options={supervisors.options} value={supervisors.selected} onChange={(selectedValue) => {setSupervisors({...supervisors, selected: selectedValue})}}/>
				</div>
				<div className='fv-row mb-7 fv-plugins-icon-container'>
					<label className='form-label fw-bolder text-dark fs-6'>{t('Días de vacaciones disponibles')}</label>
					<input type="number" name="vacationDays" className="form-control form-control-solid mb-3 mb-lg-0" placeholder={t('Días de vacaciones disponibles')} {...register('vacationDays')}  />
				</div>
				{/*end::Checkbox group*/}
				{/* <!--begin::Api error message--> */}
				<div className="fv-plugins-message-container invalid-feedback">
				  <div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
				</div>
				{/* <!--end::Api error message--> */}
			  </div>
			  {/*end::Scroll*/}
			  {/*begin::Actions*/}
			  <div className="text-center pt-15">
				<button className="btn btn-light me-3" onClick={handleClose}>{t('Cancelar')}</button>
				<button className="btn btn-primary">
				  <span className="indicator-label">{t('Crear')}</span>
				  <span className="indicator-progress">{t('Please wait...')}
					<span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
				</button>
			  </div>
			  {/*end::Actions*/}
			</form>
			{/*end::Form*/}
		  </div>
		  {/*end::Modal body*/}
		</Modal.Body>
	  </Modal>
	</>
  );
}
