import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import { Link, useParams } from 'react-router-dom'
import { SuccessPopup } from '../../components/popups/Popups'
import handleApiErrors from '../errors/handleApiErrors'
import { useDispatch } from 'react-redux'
import { editAbsenceType } from '../../actions/absenceTypeActions'
import { useTranslation } from 'react-i18next'

export default function EditAbsenceType(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()


  const {register, handleSubmit, watch, clearErrors, setValue, formState:{errors}} = useForm()
  const [errMsg, setErrMsg] = useState('')
  
  // Si la ruta contiene la id la recoge de ahi, sino la recoge por parametros
  const { id = props.absenceType.id } = useParams()
  
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = (e) => {
    e.preventDefault()
    clearErrors()
    setErrMsg('')
    setShow(false)
  }
  
  const absenceType = props.absenceType

  const data = {
    name: watch('form.name'),
    description: watch('form.description'),
  }

  useEffect(()=>{
    setValue('form', absenceType)
  }, [setValue, absenceType])

  const UpdateAbsenceType = async() => {
    setErrMsg('')
    setShow(false)
    dispatch(editAbsenceType(data, id))
    SuccessPopup(`Tipo de ausencia modificada correctamente!`)
  }
  
  return (
    <>
      {
        props.lightLink 
        ? <Link to={'#'} onClick={handleShow} className="menu-link px-3">
            {t('Editar')}
          </Link>
        : <Button variant="primary" onClick={handleShow} className="btn btn-sm btn-light-primary">
            {t('Editar')}
          </Button>
      }
      
      <Modal show={show} size='lg' backdrop='static' >
        <Modal.Header closeButton onClick={handleClose} >
          <Modal.Title className='fw-bolder fs-2'>{t('Editar Tipo de Ausencia')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*begin::Modal body*/}
          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
            {/*begin::Form*/}
            <form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(UpdateAbsenceType)}>
              {/*begin::Scroll*/}
              <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="required fw-bold fs-6 mb-2">{t('Nombre')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="text" name="name" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Nombre de la tarea" {...register(
                    'form.name', {
                      required: 'Este campo es obligatorio'
                      }
                  )}  />
                  {/*end::Input*/}
                  {/* <!--begin::Input error message--> */}
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="name" data-validator="notEmpty">{errors.form?.name?.message}</div>
                  </div>
                  {/* <!--end::Input error message--> */}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-7">
                  {/*begin::Label*/}
                  <label className="fw-bold fs-6 mb-2">{t('Descripción')}</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input type="text" name="description" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Descripcion de la tarea" {...register('form.description')} />
                  {/*end::Input*/}
                </div>
                {/*end::Input group*/}
                {/* <!--begin::Api error message--> */}
                <div className="fv-plugins-message-container invalid-feedback">
                  <div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
                </div>
                {/* <!--end::Api error message--> */}
              </div>
              {/*end::Scroll*/}
              {/*begin::Actions*/}
              <div className="text-center pt-15">
                <button className="btn btn-light me-3" onClick={handleClose}>{t('Cancelar')}</button>
                <button className="btn btn-primary">
                  <span className="indicator-label">{t('Actualizar')}</span>
                  <span className="indicator-progress">{t('Porfavor espere...')}
                    <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                </button>
              </div>
              {/*end::Actions*/}
            </form>
            {/*end::Form*/}
          </div>
          {/*end::Modal body*/}
        </Modal.Body>
      </Modal>
    </>
  );
}
