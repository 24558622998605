export const checkRequestReducer = (state = {checkrequests: [], checkrequest: null, status: 'waiting', buttonState: 'waiting'}, action)=>{
  switch (action.type) {
      case 'CHECKREQUEST_GET_SUCCESS':
          return {
              ...state,
              checkrequests: action.payload
          }
      case 'CHECKREQUEST_GET_DETAIL_SUCCESS':
          return {
              ...state,
              checkrequest: action.payload
          }
      case 'CHECKREQUEST_SEND_SUCCESS':
          return {
              ...state,
              checkrequests: [
                  ...state.checkrequests,
                  action.payload
              ]
          }
      case 'CHECKREQUEST_EDITED_SUCCESS':
          return {
            ...state, checkrequest: state.checkrequests.map(
                  checkrequest => checkrequest.id === action.payload.id
                  ?
                      action.payload
                  :
                      checkrequest
              )
          }
      case 'CHECKREQUEST_DELETE_SUCCESS':
          return {
            ...state,
            checkrequests: state.checkrequests.filter(checkrequest => checkrequest.id !== action.payload)
          }
      case 'CHECKREQUEST_GET_BYDEVICEANDUSER_SUCCESS':
        return {
            ...state,
            buttonState: action.payload.buttonState
        }
      case 'CHECKREQUEST_GET_INOUT_SUCCESS':
        return {
                ...state,
                status: action.payload.status
            }
      default:
          return state;
  }
}