import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/UseAuth';
import AxiosControl from '../hooks/AxiosControl'
import parseJwt from '../services/parseJwt';
import handleApiErrors from './errors/handleApiErrors';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getBusiness } from '../actions/businessActions';
const Axios = AxiosControl()

export default function Login() {
	const { t } = useTranslation()
	const { auth, setAuth } = useAuth()
	const dispatch = useDispatch()

	const {logo, name} = useSelector(state => state.business)

	const navigate = useNavigate()
	const location = useLocation()
	const from = location.state?.from?.pathname || "/"

	const {register, handleSubmit, watch, clearErrors, formState:{errors}} = useForm()
	const [errMsg, setErrMsg] = useState('');
	const [checked, setChecked] = useState(false)
	const [islogging, setIslogging] = useState(false)

	const data = {
		username: watch('username'),
		password: watch('password')
	}

	const CheckLogin = async() => {
		setIslogging(true)
		try {
			const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/login_check',{
				username: data.username,
				password: data.password,
			})
			if (response.status && response.status < 400) {
				CheckIsActive(response)
			} else {
				setErrMsg(t('Credenciales inválidas'))
				setIslogging(false)
			}
		} catch(err){
			console.log('err',err)
			setErrMsg(handleApiErrors(err))
			setIslogging(false)
		}
	}

	const CheckIsActive = async(response) => {
		try {
			const res = await Axios.post(process.env.REACT_APP_DB_ADDRESS + `/api/user/isActive`,{
				username: data.username,
			})
			if(res.data === true){
				// Recojo los tokens de la respuesta del servidor
				const accessToken = response.data?.token
				const refreshToken = response.data?.refresh_token

				// Desencripto el token
				const parsedToken = parseJwt(accessToken)

				// Del token recojo los roles del usuario
				const roles = parsedToken.roles
				const username = data.username
				
				// le asigno al context los datos del usuario
				setAuth({ username, roles, accessToken, refreshToken })

				setChecked(true)
			} else{
				setErrMsg('Error. Usuario desactivado. Contacte con el administrador')
			}
		} catch(err){
			console.log(err)
			setErrMsg(handleApiErrors(err))
		}
		setIslogging(false)
	}

	function handleErrors() {
		clearErrors()
	}

	useEffect(() => {
		// const addLog = async () => {
		//   // Agrego entrada al log de inicio de sesión
		//   await axiosPrivate.post(`api/logs`,{
		//     username: auth.username,
		//     action: 'El usuario ' + auth.username + ' ha iniciado sesión'
		//   })
		// }
		if (auth?.username && checked) {
			//addLog()
			navigate(from, {replace: true})
		}
	}, [auth, navigate, from, checked])

	useEffect(() => {
		dispatch(getBusiness())
	}, [dispatch])

	return (
		auth?.username && !checked
		? <Navigate to="/" state={{ from: location }} replace />
		:
		<>
		{/*begin::Authentication - Sign-in */}
		<div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style={{backgroundImage: 'url(assets/media/illustrations/sigma-1/14.png'}}>
			{/*begin::Content*/}
			<div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
			{/*begin::Logo*/}
			<Link to={'#'} className="mb-12 d-flex align-items-center">
				{
					logo !== ''
					?
						logo
						?
							<img alt="Logo" src={logo} className="h-60px" />
						:
							<img alt="Logo" src={'./assets/media/logos/logo-demo2.png'} className="h-60px" />
					:
						<></>
				}
				
				<h1 className='text-white ms-5 fs-2x'>{name !== null ? name : 'Smalldev'}</h1>
			</Link>
			{/*end::Logo*/}
			{/*begin::Wrapper*/}
			<div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
				{/*begin::Form*/}
				<form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate" id="kt_sign_in_form" onSubmit={handleSubmit(CheckLogin)}>
				{/*begin::Heading*/}
				<div className="text-center mb-10">
					{/*begin::Title*/}
					<h1 className="text-dark mb-3">{t('Iniciar sesión')}</h1>
					{/*end::Title*/}
				</div>
				{/*begin::Heading*/}
				{/*begin::Username group*/}
				<div className="fv-row mb-10 fv-plugins-icon-container" onChange={handleErrors}>
					{/*begin::Label*/}
					<label className="form-label fs-6 fw-bolder text-dark">{t('Usuario')}</label>
					{/*end::Label*/}
					{/*begin::Input*/}
					<input className="form-control form-control-lg form-control-solid" type="text" autoComplete="off" 
					{...register(
						'username', {
						required: 'Este campo es obligatorio'
						}
					)} 
					/>
					{/*end::Input*/}
					{/* <!--begin::Input error message--> */}
					<div className="fv-plugins-message-container invalid-feedback">
					<div data-field="username" data-validator="notEmpty">{errors.username?.message}</div>
					</div>
					{/* <!--end::Input error message--> */}
					<div className="fv-plugins-message-container invalid-feedback" /></div>
				{/*end::Username group*/}
				{/*begin::Password group*/}
				<div className="fv-row mb-10 fv-plugins-icon-container" onChange={handleErrors}>
					{/*begin::Wrapper*/}
					<div className="d-flex flex-stack mb-2">
					{/*begin::Label*/}
					<label className="form-label fw-bolder text-dark fs-6 mb-0">{t('Contraseña')}</label>
					{/*end::Label*/}
					</div>
					{/*end::Wrapper*/}
					{/*begin::Input*/}
					<input className="form-control form-control-lg form-control-solid" type="password" autoComplete="off"
					{...register(
						'password', {
						required: 'Este campo es obligatorio'
						}
					)} 
					/>
					{/*end::Input*/}
					{/* <!--begin::Input error message--> */}
					<div className="fv-plugins-message-container invalid-feedback">
					<div data-field="password" data-validator="notEmpty">{errors.password?.message}</div>
					</div>
					{/* <!--end::Input error message--> */}
					<div className="fv-plugins-message-container invalid-feedback" /></div>
					{/* <!--begin::Api error message--> */}
					<div className="fv-plugins-message-container invalid-feedback">
					<div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
					</div>
					{/* <!--end::Api error message--> */}
				{/*end::Password group*/}
				{/*begin::Actions*/}
				<div className="text-center">
					{/*begin::Submit button*/}
					<button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5" disabled={islogging}>
						{islogging
						?
						<span className="indicator-label">
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
						</span>
						:
						<span className="indicator-label">{t('Iniciar sesión')}</span>
						}
					</button>
					{/*end::Submit button*/}
				</div>
				{/*end::Actions*/}
				<div /></form>
				{/*end::Form*/}
			</div>
			{/*end::Wrapper*/}
			</div>
			{/*end::Content*/}
		</div>
		{/* <!--end::Authentication - Sign-in--> */}
		</>
	)
}
