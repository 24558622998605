import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { addFestive } from '../../actions/festiveActions'
import { SuccessPopup } from '../../components/popups/Popups'

export default function CreateFestive() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const {register, handleSubmit, watch, setValue, formState:{errors}} = useForm()

	const [showModal, setShowModal] = useState(false)

	const date = watch('date')

	const CreateTask = async() => {
		dispatch(addFestive(date))
		setShowModal(false)
		SuccessPopup(`¡Festivo creado correctamente!`)
		setValue('date', null)
	}

	return (
		<>
			<Button variant="primary" onClick={()=> {setShowModal(true)}}>
				<span className="svg-icon svg-icon-2 me-3">
					<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
						<rect opacity="0.5" x="11.364" y="20.364" width={16} height={2} rx={1} transform="rotate(-90 11.364 20.364)" fill="black" />
						<rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="black" />
					</svg>
				</span>
				{t('Crear Festivo')}
			</Button>

			<Modal show={showModal} size='lg' backdrop='static' >
				<Modal.Header closeButton onClick={()=> {setShowModal(false)}} >
					<Modal.Title className='fw-bolder fs-2'>{t('Crear festivo')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				<div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
					<form id="kt_modal_add_user_form" className="form" action="#" onSubmit={handleSubmit(CreateTask)}>
						<div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
							<div className="fv-row mb-7">
								<label className="required fw-bold fs-6 mb-2">{t('Fecha')}</label>
								<input type="date" name="name" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Nombre de la tarea" {...register(
									'date', {
									required: t('Este campo es obligatorio')
									}
								)}  />
								<div className="fv-plugins-message-container invalid-feedback">
									<div data-field="name" data-validator="notEmpty">{errors.name?.message}</div>
								</div>
							</div>
						</div>
						<div className="text-center pt-15">
							<button className="btn btn-light me-3" onClick={()=>{setShowModal(false)}}>{t('Cancelar')}</button>
							<button className="btn btn-primary">
							<span className="indicator-label">{t('Crear')}</span>
							<span className="indicator-progress">{t('Cargando...')}
								<span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
							</button>
						</div>
					</form>
				</div>
				</Modal.Body>
			</Modal>
		</>
	)
}