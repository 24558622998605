import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import Swal from 'sweetalert2';
import { getUsers } from '../actions/userActions';
import { getDepartments } from '../actions/departmentActions';
import { getTasks } from '../actions/taskActions';
import { getAbsences } from '../actions/absenceActions';
import { getMonths, getMonthsDepartment, getYears, getYearsDepartment } from '../actions/reportActions';
import { disableCheckRequest, enableAllCheckRequest, enableCheckRequest, getCheckRequests } from '../actions/checkrequestActions';
import useAuth from '../hooks/UseAuth';
import { CustomDataTable } from '../components/datatables/CustomDataTable';
import { useForm } from 'react-hook-form';
import { SuccessPopup } from '../components/popups/Popups';
import { addOrEditBusiness } from '../actions/businessActions';

export default function Admin() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {auth} = useAuth()

	const {register, watch, clearErrors, setValue} = useForm()

	const {users} = useSelector(state => state.users)
	const {departments} = useSelector(state => state.departments)
	const {name} = useSelector(state => state.business)

	const [errMsg, setErrMsg] = useState('')

	const {checkrequests} = useSelector(state => state.checkRequests)
	const [checkrequestsActive, setCheckrequestsActive] = useState([])
	const [checkrequestsNoActive, setCheckrequestsNoActive] = useState([])

	const {tasks} = useSelector(state => state.tasks)
	const [pendingTasks, setPendingTasks] = useState(null)
	const [finishedTasks, setFinishedTasks] = useState(null)

	const {absences} = useSelector(state => state.absences)
	const {months, years, monthsDepartment, yearsDepartment} = useSelector(state => state.reports)

	const [monthlyUser, setMonthlyUser] = useState({options: [], value: null})
	const [monthUser, setMonthUser] = useState({options: [], selected: null})

	const [yearlyUser, setYearlyUser] = useState({options: [], value: null})
	const [yearUser, setYearUser] = useState(null)

	const [monthlyDepartment, setMonthlyDepartment] = useState({options: [], value: null})
	const [monthDepartment, setMonthDepartment] = useState(null)

	const [yearlyDepartment, setYearlyDepartment] = useState({options: [], value: null})
	const [yearDepartment, setYearDepartment] = useState(null)

	const data = {
		newName: watch('name'),
		file: watch('file')
	}

	useEffect(() => {
		if(auth){
			dispatch(getUsers())
			dispatch(getDepartments())
			dispatch(getTasks())
			dispatch(getAbsences())
			dispatch(getCheckRequests())
		}
	}, [auth, dispatch])

	useEffect(() => {
		if (tasks) {
			setPendingTasks(tasks.filter(task => task.status === 'Pendiente'))
			setFinishedTasks(tasks.filter(task => task.status === 'Finalizada'))
		}
	}, [tasks])

	useEffect(() => {
		let usersOptions = []
		if (users) {
			for (const user of users) {
				usersOptions.push({label: `${user.lastName}, ${user.firstName}`, value: user.id})
			}
		}
		setMonthlyUser({options: usersOptions, value: null})
		setYearlyUser({options: usersOptions, value: null})
	}, [users])

	useEffect(() => {
		if (checkrequests) {
		setCheckrequestsActive(checkrequests.filter(checkrequest => checkrequest.active === true))
		setCheckrequestsNoActive(checkrequests.filter(checkrequest => checkrequest.active === false))
		}
	}, [checkrequests])

	const searchMonths = async (selectedValue) => {
		setMonthlyUser({...monthlyUser, value: selectedValue})
		dispatch(getMonths(selectedValue.value))
	}

	useEffect(() => {
		setMonthUser({...monthUser, options: months})
	}, [months])

	const searchReport = async (selectedValue) => {
		setMonthUser({...monthUser, selected: selectedValue.target.value})
	}

	const searchYears = async (selectedValue) => {
		setYearlyUser({...yearlyUser, value: selectedValue})
		dispatch(getYears(selectedValue.value))
	}

	useEffect(() => {
		setYearUser({...yearUser, options: years})
	}, [years])

	const searchReportYearly = async (selectedValue) => {
		setYearUser({...yearUser, selected: selectedValue.target.value})
	}

	const searchMonthsDepartment = async (selectedValue) => {
		setMonthlyDepartment({...monthlyDepartment, value: selectedValue})
		dispatch(getMonthsDepartment(selectedValue.value))
	}

	useEffect(() => {
		setMonthDepartment({...monthDepartment, options: monthsDepartment})
	}, [monthsDepartment])

	const searchReportDepartment = async (selectedValue) => {
		setMonthDepartment({...monthDepartment, selected: selectedValue.target.value})
	}

	const searchYearsDepartment = async (selectedValue) => {
		setYearlyDepartment({...yearlyDepartment, value: selectedValue})
		dispatch(getYearsDepartment(selectedValue.value))
	}

	useEffect(() => {
		setYearDepartment({...yearDepartment, options: yearsDepartment})
	}, [yearsDepartment])

	const searchReportYearlyDepartment = async (selectedValue) => {
		setYearDepartment({...yearDepartment, selected: selectedValue.target.value})
	}

	useEffect(() => {
		let departmentOptions = []
		if (departments) {
			for (const department of departments) {
				departmentOptions.push({label: department.name, value: department.id})
			}
		}
		setMonthlyDepartment({options: departmentOptions, value: null})
		setYearlyDepartment({options: departmentOptions, value: null})
	}, [departments])

	const acceptCheckRequest = async (checkrequest) => {
		try {
		dispatch(enableCheckRequest(checkrequest))

		setCheckrequestsActive(checkrequests.filter(check => check.active === true || check.id === checkrequest))
		setCheckrequestsNoActive(checkrequests.filter(check => check.active === false && check.id !== checkrequest))

		Swal.fire({
			title: t("Acceso a fichaje aceptado"),
			text: "",
			icon: 'success',
			showCancelButton: false,
			confirmButtonColor: '#5cb85c',
			confirmButtonText: t("¡Genial!"),
		})
		} catch(error){
		Swal.fire({
			title: t("Hubo un error"),
			text: "",
			icon: 'error',
			showCancelButton: false,
			confirmButtonColor: 'red',
			confirmButtonText: t("Vale"),
		})
		}
	}

	const deniedCheckRequest = async (checkrequest) => {
		try {
			dispatch(disableCheckRequest(checkrequest))

			setCheckrequestsNoActive(checkrequests.filter(check => check.active === false && check.id !== checkrequest))

			Swal.fire({
				title: t("Acceso a fichaje denegado"),
				text: "",
				icon: 'success',
				showCancelButton: false,
				confirmButtonColor: '#5cb85c',
				confirmButtonText: t("¡Genial!"),
			})
		} catch(error){
			Swal.fire({
				title: t("Hubo un error"),
				text: "",
				icon: 'error',
				showCancelButton: false,
				confirmButtonColor: 'red',
				confirmButtonText: t("Vale"),
			})
		}
	}

	const deniedCheckRequestActive = async (checkrequest) => {
		setCheckrequestsActive(checkrequests.filter(check => check.active === false && check.id !== checkrequest))
		deniedCheckRequest(checkrequest)
	}

	const acceptAllCheckRequest = async (checkrequest) => {
		try {
			dispatch(enableAllCheckRequest(checkrequest))

			setCheckrequestsActive(checkrequests.filter(check => check.active === true || check.id === checkrequest))
			setCheckrequestsNoActive(checkrequests.filter(check => check.active === false && check.id !== checkrequest))

			Swal.fire({
				title: t("Acceso a fichaje general aceptado"),
				text: "",
				icon: 'success',
				showCancelButton: false,
				confirmButtonColor: '#5cb85c',
				confirmButtonText: t("¡Genial!"),
			})
		} catch(error){
			Swal.fire({
				title: t("Hubo un error"),
				text: "",
				icon: 'error',
				showCancelButton: false,
				confirmButtonColor: 'red',
				confirmButtonText: t("Vale"),
			})
		}
	}

	const columnsOne = [
		{
			name: t('Usuario'),
			cell: checkrequest =>
				<div>
					{checkrequest.username}
				</div>,
			selector: (checkrequest) => checkrequest.username,
			sortable: true,
		},{
			name: t('Dispositivo'),
			cell: checkrequest =>
				<div>
					{checkrequest.name}
				</div>,
			selector: (checkrequest) => checkrequest.name,
			sortable: true,
		},{
			name: t('Tipo de dispositivo'),
			cell: checkrequest =>
				<div>
					{checkrequest.description}
				</div>,
			selector: (checkrequest) => checkrequest.description,
			sortable: true,
		},{
			name: '',
			cell: checkrequest =>
				<div>
					<button className="btn btn-primary" onClick={() => acceptCheckRequest(checkrequest.id)}>{t('Aceptar')}</button>
					<button className="btn btn-danger mx-5" onClick={() => deniedCheckRequest(checkrequest.id)}>{t('Denegar')}</button>
					<button className="btn btn-warning my-5" onClick={() => acceptAllCheckRequest(checkrequest.id)}>{t('Aceptar a todos en este dispositivo')}</button>
				</div>
		}
	]

	const columnsTwo = [
		{
			name: t('Usuario'),
			cell: checkrequest =>
				<div>
					{checkrequest.user
						?
							checkrequest.username
						:
							<>{t('Todos los usuarios')}</>
					}
				</div>,
			selector: (checkrequest) => checkrequest.username,
			sortable: true,
		},{
			name: t('Dispositivo'),
			cell: checkrequest =>
				<div>
					{checkrequest.name}
				</div>,
			selector: (checkrequest) => checkrequest.name,
			sortable: true,
		},{
			name: t('Tipo de dispositivo'),
			cell: checkrequest =>
				<div>
					{checkrequest.description}
				</div>,
			selector: (checkrequest) => checkrequest.description,
			sortable: true,
		},{
			name: '',
			cell: checkrequest =>
				<div>
					<button className="btn btn-danger mx-5" onClick={() => deniedCheckRequestActive(checkrequest.id)}>{t('Denegar')}</button>
					{checkrequest.user
						?
						<button className="btn btn-warning my-5" onClick={() => acceptAllCheckRequest(checkrequest.id)}>{t('Aceptar a todos en este dispositivo')}</button>
						:
						<></>
					}
				</div>
		}
	]

	// Update Business if data is correct
	const updateBusiness = () => {
		if (data.newName?.trim() !== '') {
			dispatch(addOrEditBusiness(data.file[0], data.newName))
			clearErrors()
			setErrMsg('')
			setValue('name', null)
			setValue('file', null)
			SuccessPopup(t(`Documento subida correctamente!`))
		} else {
			setErrMsg(t('La empresa debe tener un nombre'))
		}
	}

	// Load the current business name in the form
	useEffect(() => {
		if (name) {
			setValue('name', name)
		}
	}, [name, setValue])

	return (
		<div>
		{/*begin::Toolbar*/}
		<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
		{/*begin::Container*/}
		<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
			{/*begin::Page title*/}
			<div className="page-title d-flex flex-column me-3">
			{/*begin::Title*/}
			<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Panel de administración')}</h1>
			{/*end::Title*/}
			{/*begin::Breadcrumb*/}
			<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
				{/*begin::Item*/}
				<li className="breadcrumb-item text-white opacity-75">
				<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
				</li>
				{/*end::Item*/}
				{/*begin::Item*/}
				<li className="breadcrumb-item">
				<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
				</li>
				{/*end::Item*/}
				{/*begin::Item*/}
				<li className="breadcrumb-item text-white opacity-75">
				<Link to='/admin' className="text-white text-hover-primary">{t('Admin')}</Link>
				</li>
				{/*end::Item*/}
			</ul>
			{/*end::Breadcrumb*/}
			</div>
			{/*end::Page title*/}
		</div>
		{/*end::Container*/}
		</div>
		{/*end::Toolbar*/}
		{/*begin::Container*/}
		<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
		{/*begin::Post*/}
		<div className="content flex-row-fluid" id="kt_content">
			{/*begin::Stats*/}
			<div className="row g-6 g-xl-9">
				<div className='col-12'>
					<div className='card h-100'>
						<div className='card-body p-9'>
							<div className='fs-2x fw-bolder'>{t('Ajustes de la empresa')}</div>
							<div className='row my-6'>
								<div className='col-lg-6 col-md-12'>
									<label className='required fw-bold fs-6'>{t('Nombre de la empresa')}</label>
									<input className="form-control form-control-lg form-control-solid" type="text" name="name" autoComplete="off" {...register('name', {
										required: 'Introduce un nombre para el archivo'}
									)} />
								</div>
								<div className='col-lg-6 col-md-12'>
									<label className="fw-bold fs-6">{t('Logo')}</label>
									<input type="file" name="name" accept="image/png, image/gif, image/jpeg" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Documento" {...register(
										'file', {
										required: 'Selecciona un archivo'
										}
									)} />
								</div>
								<div className='col-12'>
									<div className="fv-plugins-message-container invalid-feedback">
										<div data-field="errMsg" data-validator="notEmpty">{errMsg}</div>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-12 d-grid gap-2'>
									<button type='button' className='btn btn-primary' onClick={updateBusiness}>{t('Actualizar')}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/*begin::Pending Tasks*/}
				{/* <div className="col-lg-6 col-xxl-4">
					<div className="card card-flush h-xl-100">
					<div className="card-header pt-5">
						<div className="card-title d-flex align-items-start flex-column">
						<h3 className="card-title fw-bolder text-dark">{t('Tareas pendientes')}</h3>
						</div>
					</div>
					<div className="card-body pt-0 scroll-y mh-425px">
						{
						pendingTasks?.map((task, i) =>
						<Link to={`/task/${task.id}`} key={i} className="d-flex align-items-center bg-hover-light rounded-2 p-2">
							<div className="symbol symbol-50px me-5">
							<span className="symbol-label bg-light-success">
								<span className="svg-icon svg-icon-2x svg-icon-success">
								<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
									<path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="black" />
									<path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="black" />
								</svg>
								</span>
							</span>
							</div>
							<div className="flex-grow-1">
							<div className="text-gray-800 text-hover-primary fw-bolder fs-6">{task.name}</div>
							
							{task?.departments
								?
									<>
										<div className="fw-bolder mt-5">{t('Departamentos')}</div>
										{task?.departments?.map((department, y) =>
											<span key={y} className="text-muted fw-bold d-block">{department?.name}</span>
										)}
									</>
								:
									<></>
							}
							</div>
							{
							task.status === 'Pendiente' 
								? <span className="badge badge-light-info fs-8 fw-bolder">{task.status}</span>
								: <span className="badge badge-light-success fs-8 fw-bolder">{task.status}</span>
							}
						</Link>
						)
						}
					</div>
					</div>
				</div> */}
				{/*end::Pending tasks*/}
				{/*begin::Completed Tasks*/}
				{/* <div className="col-lg-6 col-xxl-4">
					<div className="card card-flush h-xl-100">
					<div className="card-header pt-5">
						<div className="card-title d-flex align-items-start flex-column">
						<h3 className="card-title fw-bolder text-dark">{t('Tareas finalizadas')}</h3>
						</div>
						<div className="card-toolbar">
						<ol className="p-0 m-0 carousel-indicators carousel-indicators-bullet carousel-indicators-active-primary">
							<li data-bs-target="#kt_sliders_widget_1_slider" data-bs-slide-to={0} className={'active ms-1'} />
							<li data-bs-target="#kt_sliders_widget_1_slider" data-bs-slide-to={1} className={'ms-1'} />
						</ol>
						</div>
					</div>
					<div className="card-body pt-0 scroll-y mh-425px">
						{
						finishedTasks?.map((task, i) =>
						<Link to={`/task/${task.id}`} key={i} className="d-flex align-items-center bg-hover-light rounded-2 p-2">
							<div className="symbol symbol-50px me-5">
							<span className="symbol-label bg-light-success">
								<span className="svg-icon svg-icon-2x svg-icon-success">
								<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
									<path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="black" />
									<path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="black" />
								</svg>
								</span>
							</span>
							</div>
							<div className="flex-grow-1">
							<div className="text-gray-800 text-hover-primary fw-bolder fs-6">{task.name}</div>
							{task?.departments
								?
									<>
										<div className="fw-bolder mt-5">{t('Departamentos')}</div>
										{task?.departments?.map((department, y) =>
											<span key={y} className="text-muted fw-bold d-block">{department?.name}</span>
										)}
									</>
								:
									<></>
							}
							</div>
							{
							task.status === 'Pendiente' 
								? <span className="badge badge-light-info fs-8 fw-bolder">{task.status}</span>
								: <span className="badge badge-light-success fs-8 fw-bolder">{task.status}</span>
							}
						</Link>
						)
						}
					</div>
					</div>
				</div> */}
				{/*end::Completed tasks*/}
				{/*begin::Absences*/}
				<div className='col-lg-6 col-xxl-4'>
					<div className="card card-flush h-xl-100">
					{/*begin::Header*/}
					<div className="card-header border-0">
						<h3 className="card-title fw-bolder text-dark mt-5">{t('Ausencias')}</h3>
					</div>
					{/*end::Header*/}
					{/*begin::Body*/}
					<div className="card-body pt-0 scroll-y mh-425px">
						{/*begin::Item*/}
						{
						absences?.filter(a => a.status === "Pendiente de revisión")?.map((absence, i)=>
						<Link to={`/absence/${absence.id}`} key={i} className="d-flex align-items-center bg-hover-light rounded-2 p-2">
							{/*begin::Symbol*/}
							<div className="symbol symbol-50px me-5">
							<span className="symbol-label bg-light-info">
								{/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
								<span className="svg-icon svg-icon-danger svg-icon-2qx">
								<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
									<path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black" />
									<path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black" />
								</svg>
								</span>
								{/*end::Svg Icon*/}
							</span>
							</div>
							{/*end::Symbol*/}
							{/*begin::Text*/}
							<div className="flex-grow-1">
							<div className="text-gray-800 text-hover-primary fw-bolder fs-6">{absence.reason}</div>
							<div className="text-muted fw-bold d-block text-hover-primary">{absence.user.username}</div>
							</div>
							{/*end::Text*/}
							<div className='d-flex flex-column'>
							<span className="badge badge-light fs-8 fw-bolder mb-1">{absence.startDate}</span>
							<span className="badge badge-light fs-8 fw-bolder">{absence.endDate}</span>
							</div>
							
						</Link>
						)
						}
						{/*end::Item*/}
					</div>
					{/*end::Body*/}
					</div>
				</div>
				{/*end::Absences*/}
				{/*begin::Reports*/}
				<div className="col-12">
					{/*begin::Budget*/}
					<div className="card h-100">
					<div className="card-body p-9">
						<div className="fs-2x fw-bolder">{t('Informes')}</div>
						<div className="row">
						<div className='col-12 my-6'>
							<h3>{t('Informes mensuales por empleados')}</h3>
							<div className='row align-items-end'>
							<div className='col-5'>
								<label className='form-label'>{t('Seleccione empleado')}</label>
								<Select name="user" className="mb-1" onChange={searchMonths} options={monthlyUser.options} value={monthlyUser.value} ></Select>
							</div>
							<div className='col-5'>
								<label className='form-label'>{t('Seleccione mes')}</label>
								<select name="month" className="form-control form-control-solid mb-3 mb-lg-0" onChange={searchReport} value={monthUser?.selected || ''} >
								<option value={0}>{t('Seleccione mes')}</option>
								{monthUser?.options?.map((month, i) =>
										<option key={i} value={month}>{month}</option>
									)
								}
								</select>
							</div>
							<div className='col-2'>
								<a disabled href={`${process.env.REACT_APP_DB_ADDRESS}/api/sheet/user/generateusermonthreport/${monthlyUser?.value?.value}/${monthUser?.selected}`} className="btn btn-primary">{t('Generar')}</a>
							</div>
							</div>
						</div>
						<div className='col-12 my-6'>
							<h3>{t('Informes anuales por empleados')}</h3>
							<div className='row align-items-end'>
							<div className='col-5'>
								<label className='form-label'>{t('Seleccione empleado')}</label>
								<Select name="user" className="mb-1" onChange={searchYears} options={yearlyUser.options} value={yearlyUser.value} ></Select>
							</div>
							<div className='col-5'>
								<label className='form-label'>{t('Seleccione año')}</label>
								<select name="year" className="form-control form-control-solid mb-3 mb-lg-0" onChange={searchReportYearly} value={yearUser?.selected || ''} >
								<option value={0}>{t('Seleccione año')}</option>
								{yearUser?.options?.map((year, i) =>
									<option key={i} value={year}>{year}</option>
									)
								}
								</select>
							</div>
							<div className='col-2'>
								<a disabled href={`${process.env.REACT_APP_DB_ADDRESS}/api/sheet/user/generateuseryearreport/${yearlyUser?.value?.value}/${yearUser?.selected}`} className="btn btn-primary">{t('Generar')}</a>
							</div>
							</div>
						</div>
						<div className='col-12 my-6'>
							<h3>{t('Informes mensuales por departamento')}</h3>
							<div className='row align-items-end'>
							<div className='col-5'>
								<label className='form-label'>{t('Seleccione departamento')}</label>
								<Select name="user" className="mb-1" onChange={searchMonthsDepartment} options={monthlyDepartment.options} value={monthlyDepartment.value} ></Select>
							</div>
							<div className='col-5'>
								<label className='form-label'>{t('Seleccione mes')}</label>
								<select name="month" className="form-control form-control-solid mb-3 mb-lg-0" onChange={searchReportDepartment} value={monthDepartment?.selected || ''} >
								<option value={0}>{t('Seleccione mes')}</option>
								{monthDepartment?.options?.map((month, i) =>
									<option key={i} value={month}>{month}</option>
									)
								}
								</select>
							</div>
							<div className='col-2'>
								<a disabled href={`${process.env.REACT_APP_DB_ADDRESS}/api/sheet/user/generatedepartmentmonthreport/${monthlyDepartment?.value?.value}/${monthDepartment?.selected}`} className="btn btn-primary">{t('Generar')}</a>
							</div>
							</div>
						</div>
						<div className='col-12 my-6'>
							<h3>{t('Informes anuales por departamentos')}</h3>
							<div className='row align-items-end'>
							<div className='col-5'>
								<label className='form-label'>{t('Seleccione departamento')}</label>
								<Select name="department" className="mb-1" onChange={searchYearsDepartment} options={yearlyDepartment.options} value={yearlyDepartment.value} ></Select>
							</div>
							<div className='col-5'>
								<label className='form-label'>{t('Seleccione año')}</label>
								<select name="year" className="form-control form-control-solid mb-3 mb-lg-0" onChange={searchReportYearlyDepartment} value={yearDepartment?.selected || ''} >
								<option value={0}>{t('Seleccione año')}</option>
								{yearDepartment?.options?.map((year, i) =>
									<option key={i} value={year}>{year}</option>
									)
								}
								</select>
							</div>
							<div className='col-2'>
								<a disabled href={`${process.env.REACT_APP_DB_ADDRESS}/api/sheet/user/generatedepartmentyearreport/${yearlyDepartment?.value?.value}/${yearDepartment?.selected}`} className="btn btn-primary">{t('Generar')}</a>
							</div>
							</div>
						</div>
						</div>
					</div>
					</div>
					{/*end::Budget*/}
				</div>
				{/*end::Report*/}
				{/*begin::Access to check*/}
				<div className="col-12">
					<div className="card h-100">
						<div className="card-body p-9">
							<div className="fs-2x fw-bolder">{t('Acceso a fichaje')}</div>
							<div className="row">
								<div className='col-12 my-6'>
									<h3>{t('Peticiones de fichajes en dispositivos')}</h3>
									<div className='table-responsive'>
										<CustomDataTable columns={columnsOne} data={checkrequestsNoActive} />
									</div>
									<h3>{t('Dispositivos con el acceso a fichaje permitido')}</h3>
									<div className='table-responsive'>
										<CustomDataTable columns={columnsTwo} data={checkrequestsActive} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/*end::Access to check*/}
			</div>
			{/*end::Stats*/}
		</div>
		{/*end::Post*/}
		</div>
		{/*end::Container*/}
	</div>
	)
}