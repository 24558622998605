import React from 'react'
import { format, parseISO } from 'date-fns'


export default function VacationDays({ events }){
  // Mapa de nombres de meses en inglés a español
  const monthsInSpanish = {
    January: "Enero",
    February: "Febrero",
    March: "Marzo",
    April: "Abril",
    May: "Mayo",
    June: "Junio",
    July: "Julio",
    August: "Agosto",
    September: "Septiembre",
    October: "Octubre",
    November: "Noviembre",
    December: "Diciembre",
  }

  // Función para traducir el nombre del mes
  const translateMonth = (dateString) => {
    const monthEnglish = format(parseISO(dateString), 'MMMM yyyy') // Obtiene el mes y año en inglés
    const [month, year] = monthEnglish.split(' ')
    const monthSpanish = monthsInSpanish[month] // Traduce el mes al español
    return `${monthSpanish} ${year}`
  }

  // Función para formatear el día específico en formato dd/mm/yyyy
  const formatDay = (dateString) => {
    return format(parseISO(dateString), 'dd/MM/yyyy'); // Formato dd/mm/yyyy
  };

  // Agrupar eventos por mes
  const eventsByMonth = events.reduce((acc, event) => {
    const month = translateMonth(event.start);
    if (!acc[month]) {
      acc[month] = []
    }
    acc[month].push(event)
    return acc
  }, {})

  return (
    <div>
      {Object.entries(eventsByMonth).map(([month, events]) => (
        <div key={month}>
          <h3>{month}: {events.length} día(s) en total</h3>
          <table className='mx-auto table w-50'>
          <thead>
              <tr>
                <th>Fecha</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {events.map(event => (
                <tr key={event.id} style={{ backgroundColor: event.color ? 'lightgreen' : '#3788d833' }}>
                  <td>{formatDay(event.start)}</td>
                  <td>{event.color ? 'Aceptado' : 'Pendiente'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  )
}