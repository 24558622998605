export const departmentReducer = (state = {departments: [], department: null}, action)=>{
    switch (action.type) {
        case 'DEPARTMENT_GET_SUCCESS':
            return {
                ...state,
                departments: action.payload
            }
        case 'DEPARTMENT_GET_DETAIL_SUCCESS':
            return {
                ...state,
                department: action.payload
            }
        case 'DEPARTMENT_SEND_SUCCESS':
            return {
                ...state,
                departments: [
                    ...state.departments,
                    action.payload
                ]
            }
        case 'DEPARTMENT_EDITED_SUCCESS':
            return {
                ...state, department: action.payload, departments: state.departments.map(
                    department => department.id === action.payload.id
                    ?
                        action.payload
                    :
                        department
                )
            }
        default:
            return state;
    }
}