import AxiosControl from '../hooks/AxiosControl'
const Axios = AxiosControl()

export const getSchedules = () => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/schedule', {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SCHEDULE_GET_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const getSchedulesManaged = (user) => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/schedule/managedSchedules/' + user, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SCHEDULE_GET_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const getSchedule = (schedule) => async (dispatch) => {
    try {
        const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/schedule/' + schedule, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SCHEDULE_GET_DETAIL_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const addSchedule = (data) => async (dispatch) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/schedule', {
            name: data.name,
            checkInTime: data.checkInTime,
            checkOutTime: data.checkOutTime,
            startDate: data.startDate,
            endDate: data.endDate,
            day: data.day,
            description: data.description
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SCHEDULE_SEND_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const editSchedule = (data, schedule) => async (dispatch) => {
    try {
        const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/schedule/' + schedule, {
            name: data.name,
            checkInTime: data.checkInTime,
            checkOutTime: data.checkOutTime,
            startDate: data.startDate,
            endDate: data.endDate,
            day: data.day,
            description: data.description
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SCHEDULE_EDITED_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const enableSchedule = (schedule) => async (dispatch) => {
    try {
        const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/schedule/enable/' + schedule, {}, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SCHEDULE_EDITED_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const disableSchedule = (schedule) => async (dispatch) => {
    try {
        const response = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/schedule/disable/' + schedule, {}, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SCHEDULE_EDITED_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const assignUserSchedule = (data) => async (dispatch) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/schedule/assignUser', {
            schedule: data.schedule,
            user: data.user
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SCHEDULE_EDITED_SUCCESS',
                payload: response.data.schedule
            }) 
            dispatch({
                type: 'USER_EDITED_SUCCESS',
                payload: response.data.user
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const unassignUserSchedule = (data) => async (dispatch) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/schedule/unassignUser', {
            schedule: data.schedule,
            user: data.user
        }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SCHEDULE_EDITED_SUCCESS',
                payload: response.data.schedule
            }) 
            dispatch({
                type: 'USER_EDITED_SUCCESS',
                payload: response.data.user
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const duplicateSchedule = (schedule) => async (dispatch) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/schedule/duplicate/' + schedule, {}, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SCHEDULE_SEND_SUCCESS',
                payload: response.data
            }) 
        }
    } catch(error){
        console.log(error)
    }
}

export const getAvailableUserSchedules = (userId) => async (dispatch) => {
	try {
		const response = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/schedules/getAvailable/' + userId, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('RHjwt')
            }
        })
        if(response.status < 400){
            dispatch({
                type: 'SCHEDULE_GET_SUCCESS',
                payload: response.data
            }) 
        }
	} catch (error) {
		console.log(error);
	}
}