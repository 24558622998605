import { Navigate } from "react-router-dom";
import parseJwt from "../services/parseJwt";

/**
 * Comprueba si el usuario está logeado
 * @returns Redirecciona al login si no está logeado y si lo está renderiza la vista pedida
 */
const PrivateAdminRouter = ({children}) => {
    const token = localStorage.getItem('RHjwt')
    let token_decode = null
    if (token!=='undefined'){
        token_decode = parseJwt(token)
        if(token_decode.roles.includes('ROLE_ADMIN')){
            return children
        }else{
            return <Navigate to="/"/>
        }
    }
}

export default PrivateAdminRouter;