import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { disableSchedule, duplicateSchedule, enableSchedule, getSchedules } from '../../actions/scheduleActions'
import { ConfirmPupup, SuccessPopup } from '../../components/popups/Popups'
import CreateSchedule from '../modals/CreateSchedule'
import EditSchedule from '../modals/EditSchedule'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/UseAuth'
import { CustomDataTable } from '../../components/datatables/CustomDataTable'

export default function ListSchedules() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {auth} = useAuth()

	const {schedules} = useSelector(state => state.schedules)

	useEffect(() => {
		if(auth){
			dispatch(getSchedules())
		}
	}, [auth, dispatch])

	const confirmDeactivateSchedule=(scheduleId, scheduleName)=>{
		ConfirmPupup(`Desactivar calendario ${scheduleName}?`)
		.then((result) => {
			if (result.isConfirmed) {
				dispatch(disableSchedule(scheduleId))
			}
		})
	}

	const confirmEnableSchedule=(scheduleId, scheduleName)=>{
		ConfirmPupup(`Activar calendario ${scheduleName}?`)
		.then((result) => {
			if (result.isConfirmed) {
				dispatch(enableSchedule(scheduleId))
			}
		})
	}

	const confirmDuplicateSchedule=(schedule, scheduleName)=>{
		ConfirmPupup(`Duplicar calendario ${scheduleName}?`)
		.then((result) => {
			if (result.isConfirmed) {
				dispatch(duplicateSchedule(schedule.id))
				SuccessPopup(`Horario '${schedule.name}' creado correctamente!`)
			}
		})
	}

	const columns = [
		{
			name: t('Horario'),
			cell: schedule =>
				<div className="d-flex align-items-center">
					<div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
						<Link to={`/schedule/${schedule.id}`}>
						<div className="symbol-label fs-3 bg-light-danger text-danger">{schedule.name?.charAt(0)}</div>
						</Link>
					</div>
					<div className="d-flex flex-column">
						<Link to={`/schedule/${schedule.id}`} className="text-gray-800 text-hover-primary mb-1">
							{schedule.name}
						</Link>
					</div>
				</div>,
			selector: (schedule) => schedule.name,
			sortable: true,
		},{
			name: t('Día de la semana'),
			cell: schedule =>
				<div>
					{t(schedule.day)}
				</div>,
			selector: (schedule) => schedule.day,
			sortable: true,
		},{
			name: t('Hora de entrada'),
			cell: schedule =>
				<div>
					{schedule.checkInTime}
				</div>,
			selector: (schedule) => schedule.checkInTime,
			sortable: true,
		},{
			name: t('Hora de salida'),
			cell: schedule =>
				<div>
					{schedule.checkOutTime}
				</div>,
			selector: (schedule) => schedule.checkOutTime,
			sortable: true,
		},{
			name: t('Fecha de inicio'),
			cell: schedule =>
				<div>
					{schedule.startDateFormated}
				</div>,
			selector: (schedule) => schedule.startDateFormated,
			sortable: true,
		},{
			name: t('Fecha de fin'),
			cell: schedule =>
				<div>
					{schedule.endDateFormated}
				</div>,
			selector: (schedule) => schedule.endDateFormated,
			sortable: true,
		},{
			name: t('Estado'),
			cell: schedule =>
				<div className={schedule.isActive ? '' : 'bg-light'}>
					{schedule.isActive ? t('Activo') : t('Desactivado')}
				</div>,
			selector: (schedule) => schedule.isActive,
			sortable: true,
		},{
			name: '',
			cell: schedule =>
				<div>
					<Link to={'#'} className="btn btn-light btn-active-light-primary btn-sm" data-bs-toggle="dropdown" data-kt-menu-placement="bottom-end">{t('Acciones')}
						<span className="svg-icon svg-icon-5 m-0">
							<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
								<path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
							</svg>
						</span>
					</Link>
					<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4 dropdown-menu">
						<div className="menu-item px-3">
						<EditSchedule schedule={schedule} lightLink />
						</div>
						<div className="menu-item px-3">
						<Link to={'#'} className="menu-link px-3" data-kt-users-table-filter="delete_row"
							onClick={() => {
							confirmDuplicateSchedule(schedule, schedule?.name)
							}}
						>
							{t('Duplicar')}
						</Link>
						</div>
						{
						schedule.isActive ?
						<div className="menu-item px-3">
							<Link to={'#'} className="menu-link px-3 text-danger" data-kt-users-table-filter="delete_row"
								onClick={() => {
								confirmDeactivateSchedule(schedule.id, schedule?.name)
								}}
							>
								{t('Desactivar')}
							</Link>
							</div>
						:
						<div className="menu-item px-3">
							<Link to={'#'} className="menu-link px-3 text-success" data-kt-users-table-filter="delete_row"
							onClick={() => {
								confirmEnableSchedule(schedule.id, schedule?.name)
							}}
							>
							{t('Activar')}
							</Link>
						</div>
						}
					</div>
				</div>,
			selector: (schedule) => schedule.name,
			sortable: true,
		},
	]

return (
	<>
	<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
		<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
			<div className="page-title d-flex flex-column me-3">
				<h1 className="d-flex text-white fw-bolder my-1 fs-3">{t('Listar Horarios')}</h1>
				<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
					<li className="breadcrumb-item text-white opacity-75">
						<Link to='/' className="text-white text-hover-primary">{t('Inicio')}</Link>
					</li>
					<li className="breadcrumb-item">
						<span className="bullet bg-white opacity-75 w-5px h-2px m-1" />
					</li>
					<li className="breadcrumb-item text-white">{t('Listar Horarios')}</li>
				</ul>
			</div>
		</div>
	</div>
	<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
		<div className="content flex-row-fluid" id="kt_content">
			<div className="card">
				<div className="card-header border-0 pt-6">
					<div className="card-toolbar">
						<div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
							<CreateSchedule />
						</div>
						<div className="d-flex justify-content-end align-items-center d-none" data-kt-user-table-toolbar="selected">
							<div className="fw-bolder me-5">
								<span className="me-2" data-kt-user-table-select="selected_count" />Selected
							</div>
							<button type="button" className="btn btn-danger" data-kt-user-table-select="delete_selected">Delete Selected</button>
						</div>
					</div>
				</div>
				<div className="card-body py-4">
					<div className='table-responsive'>
						{schedules?.length > 0
							?
								<CustomDataTable columns={columns} data={schedules} />
							:
								<></>
						}
					</div>
				</div>
			</div>
		</div>
	</div>
	</>
)
}