import DataTable from "react-data-table-component"
import DataTableExtensions from 'react-data-table-component-extensions'
import "react-data-table-component-extensions/dist/index.css"
import { useTranslation } from "react-i18next"

export const CustomDataTable = (props) => {
    const { t } = useTranslation()
    const customStyles = {
        headCells: { style: { paddingLeft: '20px', paddingRight: '5px' } },
        cells: { style: { padding: '5px' } },
    }

    const {columns, data} = props
  
    const tableData = {
        columns: columns,
        data: data,
        exportHeaders: true,
        print: false,
        export: false,
        filterPlaceholder: t('Buscar') 
    }

    return (
        <>
            <DataTableExtensions {...tableData}>
                <DataTable
                    className="responsive"
                    noDataComponent={t('Sin resultados')}
                    labelRowsPerPage={t('Elementos por página')}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    customStyles={customStyles}
                    paginationRowsPerPageOptions={[10, 20, 50, 100, 500]}
                    paginationComponentOptions={{ 
                        rowsPerPageText: t('Elementos por página'),
                        rangeSeparatorText: t('de'),
                        noRowsPerPage: false,
                        selectAllRowsItem: true,
                        selectAllRowsItemText: t('todos')
                    }}
                />
            </DataTableExtensions>
        </>
  )
}