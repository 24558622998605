export const logReducer = (state = {logs: null, log: null}, action)=>{
    switch (action.type) {
        case 'LOG_GET_SUCCESS':
            return {
                ...state,
                logs: action.payload
            }
        case 'LOG_SEND_SUCCESS':
            return {
                ...state,
                logs: [
                    ...state.logs,
                    action.payload
                ]
            }
        default:
            return state;
    }
}